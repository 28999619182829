.medecinPage{
  .medecin-fiche-wrapper{
    &__header{
      text-align: left;
      padding-bottom: 30px;

      &--btn{
        display: inline-block;
        vertical-align: middle;
        font-weight: 500;
        font-size: 14px;
        margin: 5px 10px;
        padding: 5px 10px;
        color: @blue;
        border: 1px solid @blue;
        background: @white;
        border-radius: 4px;

        &.selected{
          background: @blue;
          color: @white;
        }

        &.green{
          background: @green;
          color: @white;
          border-color: @green;
        }
      }
    }

    &__fiche{
      display: none;
      &.selected{
        display: block;
      }
    }

  }
}