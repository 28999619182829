.telecons-iframe {

  height: calc(~"100vh - 200px");
  margin: 0 -40px -20px -40px;
  text-align: center;
  background: @black;

  .dmp-visible & {
    text-align: right;
  }

  @media @tablet {
    margin: 0 -20px -20px -20px;
  }

  @media @mobile {
    height: calc(~"100vh - 280px");
  }

  iframe {
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: none;
    overflow: hidden;
    border: 0;
  }
}

.telecons-infos {
  background: @blue;
  color: @white;
  padding: 10px 20px;
  border-radius: 2px;
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 16px;
  margin: 0 -20px;

  @media @tablet {
    margin: 0 -20px;
  }
}

.rdv-wrapper__cta-wrapper {
  .icon-video-camera {
    margin-right: 5px;
  }
}

.dmp-wrapper {
  position: fixed;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  top: 0;
  left: 0;
  height: 100%;
  max-width: calc(~"100% - 40px");
  width: 400px;
  background: @white;
  z-index: 3000;
  transition: all 150ms linear;
  transform: translate(-100%, 0);

  .inner {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .dmp-visible & {
    transform: translate(0, 0);
  }
}

.show-dmp-btn {
  transition: all 150ms linear;
  background: @green;
  color: @white;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  padding: 0 30px 0 10px;
  height: 36px;
  line-height: 36px;
  position: absolute;
  right: 0;
  bottom: 45px;
  z-index: 2500;
  border-radius: 0 100px 100px 0;
  transform: translate(100%, 0);

  .icon {
    display: none;
    font-size: 22px;
  }

  > span {
    vertical-align: middle;
  }

  @media @mobile {
    bottom: 85px;
    .icon {
      display: inline-block;
      vertical-align: middle;
    }

    > span {
      display: none;
    }
  }

  .dmp-visible & {
    font-size: 0 !important;

    .icon {
      display: none !important;
    }

    &:after {
      transform: rotate(-135deg);
    }
  }

  &:after {
    content: '';
    position: absolute;
    right: 15px;
    height: 10px;
    width: 10px;
    border-top: 2px solid @white;
    border-right: 2px solid @white;
    transform: rotate(45deg);
    transition: all 150ms linear;
    margin-top: -5px;
    top: 50%;
  }
}

.telecons-check {

  &__loader {
    position: relative;
    top: 0;
    height: 3px;
    left: 0;
    width: 100%;
    margin: -25px 0 20px;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      content: '';
      background: @green;
    }

    &.started {

      &:after {
        width: 100%;
        transition: width 25.5s linear;
      }
    }
  }

  &__errors {
    line-height: 1.3;
    font-size: 14px;
    padding: 20px;
    border-radius: 10px;
    border: 2px solid @red;
    margin: 40px 0 0 0;
    font-weight: 500;
    display: none;

    > span {
      display: none;

      &.active {
        display: inline;
      }
    }

    &.active {
      display: block;
    }
  }

  &__confirm {
    font-size: 16px;
    text-align: center;
    border: 2px solid @green;
    border-radius: 4px;
    padding: 10px 0;
    margin-top: 30px;
    display: none;
  }

  &__infos {
    background: rgba(0, 0, 0, 0.05);
    line-height: 1.3;
    font-size: 14px;
    padding: 20px;
    border-radius: 10px;
    font-weight: 400;
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin: 10px 0 40px;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 16px;
    color: @black;
    width: 400px;
    max-width: 90%;
    margin: 0 auto;
    padding-bottom: 20px;

    .icon {
      font-size: 30px;
      margin-right: 5px;
    }

    &--text {
      font-size: 16px;
      padding: 10px;
    }

    &--checked {
      padding: 10px;
    }

    &--status {
      position: relative;

      &.passed {
        &:after {
          content: '';
          display: block;
          width: 30px;
          transform: rotate(-45deg);
          height: 20px;
          border-left: 5px solid @green;
          border-bottom: 5px solid @green;
        }
      }

      &.loader {
        margin-left: 15px;
      }

      &.error {
        &:after, &:before {
          content: '';
          display: block;
          background: @red;
          position: absolute;
          width: 30px;
          transform: rotate(-45deg);
          height: 5px;

        }

        &:before {
          transform: rotate(45deg);

        }
      }
    }
  }
}

.telecons-praticien-group {
  opacity: 0;
  transform: translate(100%, 0);
  visibility: hidden;
  pointer-events: none;
  transition: all 300ms @easeOut;
  height: 0;
  overflow: hidden;

  &--infos {
    padding: 40px 0 20px;
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    font-size: 16px;
  }

  &--cta {
    text-align: center;

    .blue-cta {
      @media @mobile {
        min-width: 280px;
      }
    }
  }

  &--or {
    text-align: center;
    overflow: hidden;
    font-size: 0;
    padding: 10px 0;
    width: 298px;
    margin: 0 auto;

    @media @mobile {
      width: 242px;
    }
  }

  &--or-text {
    font-size: 16px;
    display: inline-block;
    position: relative;
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;

    &:before, &:after {
      content: '';
      width: 1000px;
      height: 1px;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
    }

    &:before {
      left: 40px;
    }

    &:after {
      right: 40px;
    }
  }

  &--pict {
    position: relative;
    height: 120px;
    overflow: hidden;
    margin-bottom: 50px;

    @media @mobile {
      margin: 20px 0 40px;
    }
  }

  @easeOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);

  &--pict-wrapper {
    width: 120px;
    height: 120px;
    border-radius: 200px;
    overflow: hidden;
    border: 5px solid @white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 800ms @easeOut;
    margin: 0;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
      border-radius: 200px;
    }

    &.left-left {
      z-index: 3;
      width: 100px;
      height: 100px;

      @media @mobile {
        display: none;
      }
    }

    &.left {
      z-index: 4;
      width: 110px;
      height: 110px;

    }

    &.center {
      z-index: 5;
    }

    &.right {
      z-index: 4;
      width: 110px;
      height: 110px;
    }

    &.right-right {
      z-index: 3;
      width: 100px;
      height: 100px;

      @media @mobile {
        display: none;
      }
    }
  }

  &.active {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
    pointer-events: all;
    height: auto;
    overflow: visible;

    &.show-praticien {
      .telecons-praticien-group {
        &--pict-wrapper {

          &.left-left {
            margin-left: -170px;
          }

          &.left {
            margin-left: -90px;

          }

          &.right {
            margin-left: 90px;
          }

          &.right-right {
            margin-left: 170px;
          }
        }
      }
    }
  }
}

.medecin-details {
  display: flex;
  align-items: flex-start;
  padding-bottom: 50px;

  @media @mobile {
    flex-wrap: wrap;
    padding-bottom: 100px;
  }

  &__pict {
    width: 120px;
    height: 120px;
    position: relative;
    border-radius: 200px;
    overflow: hidden;
    margin: 0 auto;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__content {
    padding-left: 30px;
    width: calc(~"100% - 120px");

    @media @mobile {
      width: 100%;
      padding: 20px 0 0 0;
    }

    &--address {
      font-size: 12px;
      line-height: 1.5;
      font-weight: 400;
    }

    &--label {
      font-weight: 600;
      font-size: 16px;
      padding-bottom: 10px;
    }

    &--desc {
      font-size: 14px;
      font-weight: 500;
      line-height: 1.5;

      .medecin-name {
        text-transform: capitalize;
      }

      .medecin-name {
        text-transform: capitalize;
      }

      .formation-wrapper {
        display: flex;
        align-items: flex-start;
        padding-bottom: 10px;

        &__year {
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }
}

.telecons-tabs {
  &__nav {
    white-space: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    text-align: left;
    padding-bottom: 30px;
  }

  &__step {
    padding: 20px 0;
    display: flex;
    align-items: center;

    @media @mobile {
      flex-wrap: wrap;
      padding: 0 0 20px;
    }

    .header-right__content-wrapper & {
      flex-wrap: wrap;
      padding: 0 0 20px;
    }

    &.reverse {
      flex-direction: row-reverse;

      @media @mobile {
        flex-direction: unset;
      }

      .header-right__content-wrapper & {
        flex-direction: unset;
      }

      .telecons-tabs__step--text {
        padding: 0 0 0 40px;

        @media @mobile {
          padding: 0;
        }

        .header-right__content-wrapper & {
          padding: 0;
        }
      }
    }

    &--smaller {
      font-size: 14px;
      font-weight: 400;
    }

    &--text {
      padding-right: 20px;

      @media @mobile {
        padding: 0;
      }

      .header-right__content-wrapper & {
        padding: 0;
      }
    }

    &--number {
      font-size: 50px;
      font-weight: 900;
      opacity: 0.2;
      color: @blue;
      line-height: 1.2;
    }

    &--desc {
      font-weight: 500;
      line-height: 1.3;
      font-size: 16px;
    }

    &--visual {
      width: 50%;
      overflow: hidden;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      @media @mobile {
        width: 100%;
        margin-top: 20px;
      }

      .header-right__content-wrapper & {
        width: 100%;
        margin-top: 20px;
      }

      img {
        display: block;
        max-width: 100%;
      }
    }
  }

  &__btn {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    margin: 0 20px 0 0;
    background: none;
    color: @dark;
    font-weight: 400;
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -3px;
      height: 3px;
      left: 0;
      width: 0;
      transition: all 300ms linear;
      background: @green;
    }

    &.active {
      font-weight: 700;

      &:after {
        width: 100%;
      }
    }
  }

  &__title {
    font-weight: 600;
    font-size: 22px;
    padding-bottom: 10px;
    color: @dark;

    @media @mobile {
      font-size: 20px;
    }
  }

  &__text {
    line-height: 1.5;
    font-size: 16px;
    color: @dark;
    padding-bottom: 20px;
    font-weight: 400;
  }

  &__tarif-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding-bottom: 20px;
  }

  &__tarif {
    text-align: center;
    padding: 10px;
    width: 50%;

    @media @mobile {
      width: 100%;
    }

    &--value {
      font-weight: 900;
      color: @blue;
      font-size: 40px;
    }

    &--label {
      font-size: 16px;
      font-weight: 400;
    }
  }

  &__tab {
    display: none;

    &.active {
      display: block;
    }
  }
}

.test-connexion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
  margin: 20px 0;
  background: @white;

  &.contact {
    margin-bottom: 30px;
    padding: 20px;

    .test-connexion__label {
      text-align: left;
      padding: 0;
    }
    .section__smallform {
      margin: 30px -10px;

      @media @mobile {
        margin: 20px 0;
      }
    }
  }

  &.alert {
    border: 2px solid @red;
  }

  &.update {
    border: 1px solid @green;
    border-radius: 4px;

    &.mauve {
      border: 2px solid @purple;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media @mobile {
    flex-wrap: wrap;
  }

  &__label {
    font-size: 14px;
    line-height: 1.5;
    padding-right: 10px;
    font-weight: 400;
    width: 100%;

    strong {
      font-weight: 500;
    }

    @media @mobile {
      font-size: 12px;
      width: 100%;
      /*padding: 0 0 10px 0;*/
    }
  }

  &__cta {

    @media @mobile {
      width: 100%;
    }

    .blue-cta {
      white-space: nowrap;
    }
  }
}

.telecons__profil3-cta {

  min-width: 50px;

  .icon {

    font-size: 16px;

    @media @mobile {
      font-size: 20px;
      margin: 0;
    }
  }

  &--text {
    @media @mobile {
      display: none;
    }
  }
}

.tarif-select {
  position: relative;
  max-width: 50%;

  &__devise {
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;
  }

  .input-wrapper {
    padding-bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 10px;

    label {
      position: absolute;
      top: -20px;
      right: 43px;
      text-transform: none;
      text-align: right;

      @media @mobile {
        right: 34px;
      }
    }

    input {
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      font-size: 18px;
      font-weight: 500;
      text-align: right;
      width: 180px;

      @media @mobile {
        right: 34px;
      }
    }
  }
}

.select-tarif {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.02);
  padding: 10px 0;
  width: 300px;
  max-width: 100%;
  border-radius: 4px;
  margin: 30px auto;

  &__label {
    font-size: 16px;
    font-weight: 500;
    margin: 0;
  }

  &__value {
    font-size: 16px;
    font-weight: 500;
    width: 100px;
    padding: 5px;
    margin-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-align: right;
  }

  &__devise {
    width: 20px;
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
  }
}

.range-tarif {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px auto;
  width: 300px;

  input[type=range] {
    -webkit-appearance: none;
    background: transparent;
    cursor: ew-resize;
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    margin-top: -8px;
    background: @green;
    border-radius: 99px;
  }

  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 99px;
  }

  &__select {
    width: 190px;
  }

  &__value {
    font-size: 16px;
    font-weight: 500;
    width: 70px;
    padding: 5px;
    margin-left: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    text-align: center;
  }

  &__devise {
    width: 20px;
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
  }
}

.dmp-visible {
  #popin-rdv-comment, #popin-rdv-ordo, #popin-edit-ordo, #popin-rdv-cerfa, #popin-rdv-cerfa-work, #popin-sephira, #popin-upload-doc {
    background: rgba(0, 0, 0, 0.2);

    @media @tablet {
      background: rgba(0, 0, 0, 0.2);
    }

    .popin-med__wrapper {
      top: 0;
      left: 0;
      max-width: calc(~"100% - 40px");
      width: 400px;
      height: 100%;
      max-height: 100%;
      border-radius: 0;
      transform: translate(0, 0);
      position: fixed;
    }

    .popin-med__close {
      position: absolute;
      top: 5px;
      right: 4px;
      transform: none;
      margin: 0;
    }
  }

  #popin-rdv-comment {
    .toggle-wrapper {
      margin: 0 -20px;
    }

    .toggle-wrapper__inner {
      font-size: 12px;
    }
  }
}

#popin-rdv-comment {
  .popin-med__subtitle {
    padding-bottom: 20px;
  }
  .toggle-wrapper {
    margin: 0 -40px;

    @media @tablet {
      margin: 0 -20px;
    }
  }
  .toggle-wrapper__title {
    height: 40px;
    line-height: 40px;
    padding: 0 10px 0 50px;

    &:before {
      left: 26px;
    }

    &:after {
      left: 20px;
    }
  }
}

.cerfa-alert {
  font-weight: 400;
  border: 1px solid @red;
  padding: 5px 10px;
  font-size: 14px;
  margin-bottom: 20px;
  border-radius: 4px;

  @media @mobile {
    font-size: 12px;
  }

  &__checkbox {
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
}