.section {
  &__rdv {
    position: relative;
    padding-top: 20px;
    padding-bottom: 40px;
    margin: 0 -40px;

    &--none {
      opacity: 0.5;
      font-size: 16px;
      padding: 20px;
      text-align: center;
    }

    &.smaller {
      margin: 0;
      padding: 0;
    }

    @media @tablet {
      margin: 0 -20px;
    }

    &.cerfa-element {
      padding: 0;
    }

    .pagination__paginate {
      padding-left: 10px;
      padding-right: 10px;
    }

    &--subtitle {
      padding: 14px 40px 0;
      font-size: 16px;

      @media @mobile {
        padding: 14px 20px 0;
      }

      & + hr {
        margin-bottom: 0;
      }
    }

    .no-result {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      padding: 20px;
    }

    .rdv-wrapper {
      font-size: 0;
      padding: 10px 50px 10px 40px;
      background: none;
      width: 100%;
      position: relative;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &:nth-child(odd) {
        background: @lightgrey5;
      }

      &.rdv-comment {
        width: 100%;
        padding: 20px;
        display: block;

        .rdv-wrapper__date {
          font-size: 12px;
          padding-top: 0;
          font-weight: 500;
        }

        .rdv-wrapper__content {
          text-align: left;
          padding: 10px 0 0 0;
          margin: 0;
          border: 0;
          background: none;
          white-space: pre-wrap;
        }
      }

      &.tlc {
        padding: 10px 20px;

        &.past, &.next {
          opacity: 0.7;
        }

        &.tlc_standby {
          background: @yellowlt2 !important;
        }

        .date_past, .date_next, .date_today, .date_standby {
          padding: 3px 6px;
          color: @white;
          font-weight: 600;
          border-radius: 100px;
          font-size: 11px;
          margin-left: 10px;
        }

        .date_past {
          background: @grey;
        }

        .date_today {
          background: @green;
        }

        .date_next {
          background: @blue;
        }

        .date_standby {
          background: @red;
        }

        @media @mobile {
          flex-wrap: wrap;

          .rdv-wrapper__name {
            padding-top: 10px;
          }

          .rdv-wrapper__address {
            padding-bottom: 15px;
          }
        }

        .rdv-wrapper__name {
          width: 100%;
        }

        .rdv-wrapper__address {
          padding-top: 5px;
          font-size: 12px;

          .icon {
            color: @blue;
            margin-left: 10px;
            font-size: 12px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }

      &.rdv-cerfa {

        padding: 10px 20px;

        .blue-cta {
          color: @white;
          margin: 5px;
          padding: 0 10px;

          .icon {
            margin: 0;
          }
        }
      }

      &.rdv-patient {
        .rdv-wrapper__name {
          width: 100%;

          .icon {
            font-size: 10px;
            cursor: pointer;
          }

          &--details {
            font-size: 12px;
          }
        }

        .rdv-wrapper__type {
          margin-right: 10%;
        }

        @media @mobile {
          padding: 15px 20px;
          flex-wrap: wrap;

          .rdv-wrapper__type {
            width: 100%;
            min-width: 100%;
            margin-right: 0;
            padding-bottom: 10px;
          }

          .rdv-wrapper__date {
            padding-bottom: 15px;
          }

          .rdv-wrapper__address {
            padding-bottom: 20px;
          }

          .blue-cta {
            width: 100%;
          }
        }
      }

      &.rdv-list {
        width: 200px;
        padding: 0 10px;
        margin: 0 10px 20px 10px;
        background: @white;
        border: 1px solid @lightgrey5;
        border-radius: 4px;
        display: inline-block;
        vertical-align: top;

        .smaller& {
          width: 150px;
          margin: 5px;

          @media @mobile {
            width: 100%;
          }

          .icon, .rdv-wrapper__date--date, .rdv-wrapper__date--time {
            font-size: 10px;
          }
        }

        &:nth-child(odd) {
          background: @white;
        }
      }

      &.rdv-ordo {
        width: 200px;
        margin: 0 10px 20px 10px;
        background: @blue;
        color: @white;
        cursor: pointer;
        border-radius: 4px;
        padding: 0 20px;
        display: inline-block;
        vertical-align: middle;

        .rdv-wrapper__date {
          font-size: 14px;

          .icon {
            color: @white;
            font-size: 12px;
          }
        }
      }

      &.rdv-ordo-doc {
        width: 250px;
        margin: 0 10px 20px 10px;
        background: @antracite;
        color: @white;
        cursor: pointer;
        border-radius: 4px;
        padding: 0 4px 0 10px;
        text-align: right;
        display: inline-block;
        font-size: 0;
        vertical-align: middle;

        .rdv-wrapper__date {
          font-size: 14px;
          display: inline-block;
          vertical-align: middle;

          .icon {
            color: @white;
          }
        }

        .rdv-ordo-doc {
          &__cta {
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
          }

          &__link, &__delete {
            margin: 0 0 0 10px;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            border-radius: 4px;
            width: 28px;
            height: 28px;
            line-height: 28px;
            padding: 0;
            
            .icon {
              margin: 0;
              vertical-align: middle;
            }
          }
        }
      }

      &.patient {
        padding: 10px 70px 10px 40px;
        position: relative;
        cursor: pointer;
        text-align: left;

        @media @mobile {
          padding: 10px 20px;
        }

        &.address-book {
          cursor: default !important;

          .rdv-wrapper__name {
            width: 20%;
            min-width: 20%;
          }

          .rdv-wrapper__birthday {
            width: 20%;
            min-width: 20%;
          }

          .rdv-wrapper__details {
            width: 60%;
            min-width: 60%;

            .icon {
              color: @blue;
              margin-left: 10px;
            }
          }
        }

        .round-cta {
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translate(0, -50%);

          .icon {
            font-size: 26px;
            color: @black;
            opacity: 0.3;
          }
        }
      }

      a {
        color: @black;

        &.blue-cta {
          color: @white;
        }
      }

      * {
        font-size: 14px;
        font-weight: 400;
      }

      strong {
        font-weight: 500;
      }

      &.cancelled {
        pointer-events: none;
        cursor: not-allowed;
        opacity: 0.5;

        .medecinPage & {
          pointer-events: all;
        }
      }

      &__half {
        width: 50%;
        display: inline-block;
        vertical-align: middle;
      }

      &__alert-mask {
        background: rgba(0, 0, 0, 0.6);
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        color: @white;
        font-weight: 500;
        font-size: 14px;
        line-height: 1.5;
        padding: 10px 20px;
      }

      &__byprat {
        border-bottom: 1px solid @lightgrey4;
        padding: 20px 0;
        margin-bottom: 5px;

        &.selected {

          .rdv-wrapper__byprat--name {
            &:after {
              transform: rotate(135deg);
            }
          }

          .rdv-wrapper__cabine {
            display: block;
          }
        }

        &--name {
          font-size: 16px;
          cursor: pointer;
          padding: 10px 10px 10px 30px;
          position: relative;
          margin-left: 40px;

          &:after {
            content: '';
            position: absolute;
            top: 12px;
            left: 0;
            width: 10px;
            height: 10px;
            border-top: 3px solid @green;
            border-right: 3px solid @green;
            transform: rotate(45deg);
          }
        }
      }

      &__cabine {
        display: none;
        padding: 20px 0;
        margin: 0 40px;
      }

      &__tiers {
        width: 100%;
        display: inline-block;
        vertical-align: middle;

        @media @mobile {
          /*width: 50%;*/
          &.mobile-full {
            width: 100%;
          }
        }
      }

      &__cta-wrapper {
        text-align: right;
        display: flex;
        justify-content: center;

        i {
          margin-right: 5px;
          vertical-align: middle;
        }

        .no-ordonnance, .no-comment {
          opacity: 0.6;
        }

        .blue-cta {
          text-transform: none;
          padding: 0 10px;
          font-size: 12px;
          font-weight: 500;
          width: auto;

          span {
            vertical-align: unset;
            font-size: 12px;
            font-weight: 500;
            line-height: 1;
          }
        }

        button {
          margin: 5px;
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          span {
            display: inline;
          }
        }

        @media @mobile {
          text-align: center;
          margin-top: 20px;
        }
      }

      &__type {
        margin-right: 20px;
        min-width: 18%;
        width: 18%;

        span {
          background: @blue;
          border-radius: 10px;
          display: inline-block;
          color: @white;
          padding: 5px 15px;
          font-size: 10px;
          font-weight: 500;
        }

        &.passed {
          span {
            background: @grey;
          }
        }
      }

      &__name {
        font-weight: 500;
        min-width: 33%;
        width: 33%;
        line-height: 1.3;
        overflow: hidden;
        text-overflow: ellipsis;

        a {
          font-weight: 500;
        }

        @media @mobile {
          width: 40%;
        }
      }

      &__birthday {
        padding-left: 10px;
        font-size: 12px;
        min-width: 33%;
        width: 33%;
      }

      &__address {
        font-size: 12px;
      }

      &__details {
        display: block;
        padding: 10px 0;
        line-height: 1.5;
      }

      &__date {
        text-align: center;
        font-size: 20px;
        display: flex;
        padding: 10px 0;
        line-height: 1;

        .cancel-text {
          padding-left: 20px;
        }

        .icon {
          vertical-align: middle;
          padding-right: 10px;
          color: @blue;
        }

        &--date {
          font-weight: 500;
          font-size: 14px;
          text-align: left;
          justify-content: left;

          .to-pay {
            font-size: 10px;
            font-weight: 500;
            color: @red;
          }
        }

        &--prat {
          padding-right: 20px;
          font-weight: 500;
          font-size: 12px;
        }

        &--time {
          font-size: 14px;
          padding-left: 10px;
        }
      }
    }
  }
}