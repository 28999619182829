.homepage {
  #main {
    display: block;
    overflow: hidden;
  }

  .footer__sitemap, .footer__contact-us {
    display: block;
  }

  .header {
    position: fixed !important;
  }
}

.blue-text {
  color: @blue;
}

.section {
  &__cta {
    &.center-cta {
      padding-bottom: 40px;
      text-align: center;
    }
  }

  &.home-faq {

    .section__cta {
      margin-top: 30px;
    }

    .faq-list__item {
      border: 0;

      &.active {
        .faq-list__title {
          &:after {
            transform: translate(0, -65%) rotate(130deg);
          }
        }
      }
    }

    .faq-list__title {
      padding: 10px 30px 10px 50px;

      &:after {
        right: auto;
        left: 20px;
        width: 8px;
        height: 8px;
      }
    }

    &.center-content {
      background: none;
      margin-bottom: 0;
      box-shadow: none;
      width: 100%;
      max-width: 100%;
      border-radius: 0;
      padding: 40px 0;

      &.white-bg {
        background: @white;
      }

      @media @tablet {
        width: calc(~"100% - 20px");
      }

      @media @mobile {
        width: 100%;
        margin-left: 0;
        border-radius: 0;
      }
    }

    .home-faq {

      &__spacer {
        width: 100%;
        display: block;
      }
      &__wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 1150px;
        width: 100%;
        margin: 0 auto;
        padding: 0 20px;

        &.reverse {
          flex-direction: row-reverse;

          @media @mobile {
            flex-direction: initial;
          }
        }

        @media @mobile {
          padding: 0;
        }
      }

      &__visual {
        width: 40%;
        padding: 20px 60px;

        &.ans {
          @media @mobile {
            display: flex;
          }
          .minis-logo {
            margin-bottom: 40px;

            @media @mobile {
              margin-bottom: 0;
              max-width: 40%;
              height: auto;
            }
          }

          .ans-logo {
            margin-bottom: 40px;

            @media @mobile {
              margin-bottom: 0;
              max-width: 40%;
              height: auto;
            }
          }
        }

        @media @mobile {
          width: 100%;
          padding: 0 0 0 0;
          border: 0;
        }

        img {
          width: 100%;
          height: auto;
          display: block;

          @media @mobile {
            width: auto;
            height: 200px;
            margin: 0 auto;
            max-width: 80%;

            &.logo {
              max-height: 40px;
              margin-top: 50px;
            }
          }
        }
      }

      &__content {
        width: 60%;
        padding: 20px 20px 40px;
        border-radius: 10px;

        &.no-padding {
          padding: 0;

          .hp-services__section-desc {
            max-width: 100%;
            padding: 0;

            @media @mobile {
              padding: 10px 20px 0;
            }
          }
        }

        &.white-bg {
          background: @white;
          margin-bottom: 30px;
        }

        @media @mobile {
          border-radius: 0;
          width: 100%;
          padding: 10px 0 30px;
        }

        .section__title--number {
          text-align: left;
          padding: 30px 20px 0;

          @media @mobile {
            padding-top: 20px;
          }
        }

        .section__title--title {
          text-align: left;
          padding: 30px 20px 10px;
          color: @blue;
          font-weight: 700;
          font-size: 25px;

          @media @mobile {
            padding-top: 20px;
          }
        }

        .section__subtitle {
          padding: 10px 20px 20px;
          text-align: left;
          font-size: 20px;
          font-weight: 700;

          .small {
            font-weight: 400;
            font-size: 18px;
          }

          &.smaller {
            font-size: 18px;

            @media @mobile {
              font-size: 16px;
            }
          }

          @media @mobile {
            font-size: 18px;
          }

          @media @verysmallmobile {
            font-size: 17px;
          }
        }

        .faq-list {
          margin: 0;

          &__title {
            font-weight: 400;
          }

          &__desc {
            font-weight: 500;
          }

          @media @tablet {
            margin: 0;
          }

          @media @mobile {
            margin: 0;
          }
        }
      }
    }

    &.reverse {
      .home-faq {
        &__wrapper {
          flex-direction: row-reverse;
        }

        &__visual {
          border-right: 0;
        }
      }
    }
  }

  &.certif {
    display: flex;
    align-items: center;
    margin: 30px 0;
    flex-wrap: wrap;
    justify-content: center;

    .text {
      color: @black;
      font-size: 14px;
      padding: 10px 10px 10px;
      width: 100%;
      text-align: center;
    }

    .picto-certif {
      width: 220px;
      height: 96px;
      padding: 10px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  &__intertitle {
    color: @black;
    font-size: 25px;
    padding: 20px;
    text-align: center;
    font-weight: 500;
    letter-spacing: normal;

    @media @mobile {
      font-size: 16px;
      line-height: 1.5;
      margin-bottom: 0;
      padding-top: 35px;
    }
  }

  &__slider {
    padding: 0 50px 20px;
    margin-bottom: 30px;

    .slide {
      width: 230px;

      opacity: 0.4;
      transition: all 150ms linear;

      &.slick-current {
        opacity: 1;
      }

      &__img {
        text-align: center;

        img {
          border-radius: 999px;
          display: inline-block;
          width: 70px;
        }
      }

      &__infos {
        font-size: 13px;
        color: @black;
        padding: 20px;
        font-weight: 300;
      }

      &__title {
        font-weight: 500;
        line-height: 1.3;
        padding-bottom: 10px;
        font-size: 15px;
      }
    }
  }

  &.home-header {
    background: url("@{img-path}home/header-bg.jpg") 50% 50% no-repeat;
    background-size: cover;
    height: 410px;
    position: relative;
    margin-bottom: 50px;

    @media @mobile {
      max-height: 290px;
      margin-bottom: 30px;
      min-height: calc(~"100vh - 274px");
    }

    .left, .right {
      position: absolute;
      z-index: 1;

      @media @mobile {
        opacity: 0.5;
      }
    }

    .left {
      top: 65%;
      left: 20%;
    }

    .right {
      top: 49%;
      right: 15%;
    }

    .section {
      &__content {
        position: absolute;
        left: 0;
        width: 100%;
        top: calc(~"50% - 30px");
        transform: translate(0, -50%);
        text-align: center;
        z-index: 10;

        @media @mobile {
          top: calc(~"50% - 40px");
        }

        .bullet-picture {
          margin: 50px auto 0;

          @media @mobile {
            margin-top: 15px;
            top: calc(~"50% - 40px");
          }
        }

        .blue-cta {
          margin-top: 24px;

          @media @mobile {
            display: none;
          }
        }
      }

      &__baseline {
        font-weight: 700;
        color: @black;
        padding: 0 10px;

        @media @mobile {
          font-size: 20px;
        }

        strong {
          font-weight: 700;
          color: @blue;
        }
      }

      &__search-wrapper {
        position: absolute;
        transform: translate(-50%, 30px);
        max-width: 1150px;

        @media @mobile {
          transform: translate(-50%, -10px);
          z-index: 2;
        }

        &.fixed {
          position: fixed;
          width: 100%;
          z-index: 200;
          bottom: auto;

          @media @mobile {
            position: absolute;
            z-index: 2;
            top: auto !important;
            bottom: 0 !important;

          }
        }
      }
    }
  }

  &.homepage-header {
    background: @white;
    background-size: cover;
    position: relative;
    margin-bottom: 70px;

    &.praticien-header {
      .section__content {
        max-width: 960px;
        margin: 0 auto;
        padding-bottom: 50px;

        .section__baseline {
          padding-bottom: 0;
        }

        .section__cta {
          padding-top: 30px;
          text-align: center;

          .blue-cta {
            margin: 5px;
          }
        }
      }
    }

    &.demo-header {
      margin-bottom: 0;

      .section__content {
        max-width: 960px;
        margin: 0 auto;
        padding-bottom: 50px;
        flex-direction: unset;

        .section__baseline {
          padding-bottom: 0;
          width: 100%;
          margin-top: 40px;
        }

        .section__card {
          width: 100%;

          img {
            width: 400px;
            max-width: 70%;

            @media @mobile {
              margin: 0 auto;
              float: none;
            }
          }
        }
      }
    }

    @media @mobile {
      margin-bottom: 30px;
    }

    .search-nav {
      max-width: 1150px;
      margin: -50px auto 0;
      padding: 20px 0 34px;
      font-size: 14px;
      display: flex;
      align-items: center;

      &__link {
        color: @black;
        position: relative;
        padding: 5px 15px;
        border-radius: 4px 4px 0 0;
        background: none;
        font-weight: 400;

        &.active {
          color: @black;
          font-weight: 400;
          pointer-events: none;
          background: rgba(0, 0, 0, 0.05);
        }
      }

      @media @mobile {
        white-space: nowrap;
        padding: 20px 10px 74px;
        margin: -60px auto 0;
        font-size: 14px;
      }
    }

    .section {

      &__content {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        @media @mobile {
          flex-direction: column-reverse;
        }
      }

      &__pict {
        text-align: center;
        padding: 40px 0 10px;

        @media @mobile {
          padding-top: 30px;
        }

        img {
          display: block;
          margin: 0 auto;
          max-width: 500px;

          @media @mobile {
            max-width: 70%;
          }
        }
      }

      &__card {
        padding: 40px 0 0;
        width: 50%;

        @media @mobile {
          padding: 10px 0 0;
          width: 100%;
          text-align: right;
        }

        img {
          display: block;
          margin: 0 auto;
          width: 100%;
          height: auto;

          @media @mobile {
            width: 130%;
            margin-right: -44.5%;
            margin-bottom: -10%;
            float: right;
          }
        }
      }

      &__praticien {
        padding: 40px 0 0;
        width: 50%;

        @media @mobile {
          padding: 10px 0 20px;
          width: 100%;
        }

        img {
          display: block;
          margin: 0 auto;
          width: 400px;
          max-width: 90%;
          height: auto;

          @media @mobile {
            width: 60%;
          }
        }
      }

      &__baseline {
        font-size: 25px;
        font-weight: 700;
        line-height: 1.3;
        color: @black;
        padding: 0 10px 60px 10px;
        text-align: center;

        h1 {
          font-weight: 700;

          @media @mobile {
            font-size: 20px;
          }
        }

        @media @mobile {
          font-size: 16px;
        }

        &.half {
          width: 50%;

          @media @mobile {
            width: 100%;
          }
        }

        &--second {
          color: @blue;
          font-size: 30px;
          display: block;
          line-height: 1.3;
          padding-top: 10px;

          @media @tablet {
            font-size: 25px;
          }

          @media @mobile {
            font-size: 20px;
          }

        }
      }

      &__search-wrapper {
        position: absolute;
        transform: translate(-50%, 30px);
        max-width: 1150px;
        overflow: hidden;

        &.loader {
          &:after {
            left: auto;
            right: 16px;
          }

          .icon-icon-poi {
            opacity: 0;
          }
        }

        @media @mobile {
          transform: translate(-50%, -14px);
          z-index: 2;
          width: calc(~"100% - 20px");
        }

        &.fixed {
          position: fixed;
          width: 100%;
          z-index: 200;
          bottom: auto;

          @media @mobile {
            position: absolute;
            z-index: 2;
            top: auto !important;
            bottom: 0 !important;

          }
        }
      }
    }
  }

  .services {

    &__list {
      list-style: none;
      margin: 0;
      padding: 0 0 50px 0;
      text-align: center;
      font-size: 0;

      @media @mobile {
        display: none;
      }

      &--sep {
        display: inline-block;
        vertical-align: middle;
        width: 1px;
        height: 30px;
        background: @black;
        opacity: 0.2;
        margin: 0 20px;
      }

      &--item {
        display: inline-block;
        vertical-align: middle;
        font-size: 10px;
        color: @dark;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
      }

      &--text {
        display: block;
        text-align: center;
        padding: 10px;
      }

      &--picto {
        font-size: 26px;
        color: @blue;
      }
    }
  }

  .services-praticien {

    &__list {
      list-style: none;
      margin: 0;
      padding: 0 0 50px 0;
      text-align: center;
      font-size: 0;

      @media @mobile {
        display: none;
      }

      &--sep {
        display: inline-block;
        vertical-align: middle;
        width: 1px;
        height: 30px;
        background: @black;
        opacity: 0.2;
        margin: 0 20px;
      }

      &--item {
        display: inline-block;
        vertical-align: middle;
        font-size: 10px;
        color: @dark;
        text-align: center;
        font-weight: 500;
      }

      &--text {
        display: block;
        text-align: center;
        padding: 10px;
        font-size: 16px;
        font-weight: 500;
      }

      &--picto {
        font-size: 46px;
        color: @blue;
      }
    }
  }

  &.center-content {
    text-align: center;
    background: @white;
    border-radius: 10px;
    max-width: 1150px;
    margin: 0 auto 40px;
    position: relative;
    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.10);
    font-size: 0;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    padding: 30px 20px 60px;

    @media @tablet {
      width: calc(~"100% - 20px");
    }

    &.vaccin {
      margin-top: 60px;
      font-size: 14px;
      text-align: left;

      @media @tablet {
        margin: 0;
        border-radius: 0;
      }

      @media @mobile {
        margin: 0;
        border-radius: 0;
        width: 100%;
      }
    }

    &.smaller {
      padding-top: 10px;
      padding-bottom: 10px;

      .section__cta {
        padding-top: 10px;
      }
    }

    @media @mobile {
      width: calc(~"100% - 20px");
      margin-left: 10px;
      border-radius: 10px;
      overflow: hidden;
    }

    .section {
      &__title {
        text-align: center;
        width: 100%;
        margin: 0 0 30px;

        @media @mobile {
          font-size: 20px;
          line-height: 1.3;
        }

        &--medecin {
          color: @black;
          font-weight: 400;
          font-size: 18px;
          line-height: 1.5;

          @media @mobile {
            font-size: 14px;
          }

          strong {
            color: @blue;
            font-weight: 500;
            font-size: 24px;
            display: inline-block;
            margin-bottom: 5px;

            @media @mobile {
              font-size: 18px;
            }
          }
        }

        &--big {
          font-size: 40px;
          text-align: center;
          display: block;
          padding-bottom: 20px;
          font-weight: 700;
          color: @blue;
          text-transform: uppercase;

          &.smaller {
            @media @mobile {
              font-size: 24px;
            }
          }
        }
      }

      &__baseline {
        text-align: center;
        width: 100%;
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 25px;
        position: relative;
        color: @blue;
      }

      &__subtitle {
        text-align: center;
        width: 100%;
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 10px;
        position: relative;
        line-height: 1.5;
        margin: 0;
      }

      &__cta {
        text-align: center;
        width: 100%;
      }
    }
  }

  &.blue-bg {
    background: @blue;

    .community {
      margin: 0;
      color: @white;
      width: 100%;
    }
  }

  &.get-dmp {

    .get-dmp {
      flex-direction: unset;
    }

    .get-dmp__text .section__title {
      max-width: 100%;
      line-height: 1.3;
    }

    .get-dmp__visual {
      margin-bottom: -60px;
    }
  }

  &.offers {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 50px;

    @media @mobile {
      padding-bottom: 30px;
    }

    .offers {

      &__price {
        position: relative;
        background: @green;
        border: 10px solid @white;
        color: @white;
        font-size: 28px;
        box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 20px;
        width: 450px;
        height: 450px;

        @media @mobile {
          width: calc(~"100% - 20px");
          height: auto;
          margin: 0 auto;
          font-size: 22px;
        }

        &--inner {
          position: absolute;
          top: 50%;
          left: 0;
          width: 100%;
          text-align: center;
          padding: 40px;
          transform: translate(0, -50%);

          @media @mobile {
            position: relative;
            padding: 0;
            top: 0;
            transform: translate(0, 0);
          }
        }

        &--value {
          font-size: 50px;
          padding-top: 30px;

          @media @mobile {
            padding-top: 20px;
            font-size: 38px;
          }
        }
      }

      &__certif {
        display: flex;
        padding: 30px 10px 0 30px;
        align-items: center;
        justify-content: center;

        @media @mobile {
          padding: 20px 0 0;
          flex-wrap: wrap;
        }

        .text {
          font-size: 14px;
          text-align: center;
          padding: 10px;

          @media @mobile {
            font-size: 14px;
            padding: 10px 0;
            width: 100%;
          }
        }

        .picto-certif {
          width: 150px;
          padding: 10px;

          @media @tablet {
            width: 120px;
          }

          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }
      }

      &__desc {
        width: calc(~"100% - 430px");
        margin-left: -20px;

        @media @mobile {
          width: calc(~"100% - 60px");
          margin: -20px auto 0;
        }

        &--text {
          background: @white;
          box-shadow: 2px 6px 10px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          padding: 60px 30px 60px 50px;
          font-size: 20px;

          @media @mobile {
            padding: 40px 20px 20px;
            font-size: 16px;
            text-align: center;
          }
        }
      }
    }
  }

  &.step1 {
    background: @blue;
    border-radius: 20px;
    max-width: 1150px;
    margin: 0 auto;
    padding: 30px 30px 130px;
    position: relative;
    margin-bottom: 130px;
    z-index: 20;

    &.bis {
      padding-bottom: 50px;
      margin-bottom: 40px;

      .section__title {
        @media @mobile {
          font-size: 18px;

          .section__title--number {
            font-size: 20px;
          }
        }
      }

      @media @mobile {
        padding-bottom: 80px;
      }
    }

    @media @mobile {
      border-radius: 10px;
      width: calc(~"100% - 20px");
      margin-left: 10px;
      padding: 30px 10px 0;
    }

    .section {
      &__step-desc {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        color: @white;
        padding-bottom: 50px;

        @media @mobile {
          padding-bottom: 10px;
        }

        &--item {
          background: rgba(255, 255, 255, 0.1);
          border-radius: 10px;
          width: calc(~"25% - 28px");
          position: relative;
          margin: 0 14px;

          @media @tablet {
            margin: 20px;
            width: calc(~"50% - 40px");
          }

          @media @mobile {
            margin: 20px;
            width: calc(~"100% - 40px");
          }
        }

        &--number {
          position: absolute;
          top: -23px;
          line-height: 1;
          color: @green;
          font-size: 45px;
          font-weight: 700;
          left: 0;
        }

        &--text {
          text-align: center;
          display: block;
          font-size: 20px;
          font-weight: 700;
          line-height: 1;
          margin-top: -20px;
          margin-bottom: 30px;
        }

        &--desc {
          font-size: 16px;
          line-height: 2;
          padding: 0 20px;
          text-align: left;
          font-weight: 400;
          min-height: 100px;
          border-left: 1px solid @white;

          @media @mobile {
            min-height: auto;
          }
        }

        &--picto {
          padding-bottom: 10px;

          img {
            display: block;
            width: 100px;
            height: auto;
            margin: 0 auto;
          }
        }
      }

      &__title {
        line-height: 1.3;
        text-align: center;
        color: @white;
        font-weight: 400;
      }

      &__subtitle {
        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: @white;
      }

      &__push-wrapper {
        padding: 63px 0 40px;
        position: relative;

        @media @mobile {
          text-align: right;
          padding: 25px 0 40px;
          margin-bottom: 60px;
        }
      }

      &__push {
        width: 270px;
        height: 60px;
        position: relative;
        background: @white;
        border-radius: 3px;
        font-size: 12px;
        color: @dark;
        display: inline-block;
        padding: 12px;
        z-index: 2;
        margin-left: 40px;

        @media @mobile {
          width: 250px;
          margin: 0;
        }

        &.back {
          opacity: 0.2;
          position: absolute;
          z-index: 1;
          left: 10%;
          bottom: 0;

          @media @mobile {
            left: 0;
          }

        }

        &--text {
          font-weight: 500;
          font-size: 12px;
          position: absolute;
          left: 20px;
          width: calc(~"100% - 105px");
          top: 50%;
          transform: translate(0, -50%);
          text-align: left;
        }

        &--btn {
          background: @green;
          font-size: 16px;
          position: absolute;
          right: 12px;
          top: 50%;
          transform: translate(0, -50%);
          border-radius: 3px;
          width: 63px;
          height: 36px;
          line-height: 36px;
          text-align: center;
          color: @white;

          i {
            vertical-align: middle;
          }
        }
      }

      &__avis-wrapper {
        padding: 56px 0 40px;
        position: relative;

        @media @mobile {
          margin-bottom: 0;
          padding-bottom: 0;
          text-align: left;
        }
      }

      &__avis {
        width: 300px;
        padding: 20px;
        position: relative;
        background: @white;
        border-radius: 3px 3px 3px 0;
        font-size: 12px;
        color: @dark;
        display: inline-block;
        z-index: 2;
        margin-left: -40px;

        @media @mobile {
          width: 260px;
          margin: 0;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: -6px;
          left: 0;
          border-left: 11px solid @white;
          border-bottom: 6px solid transparent;
        }

        &.back {
          opacity: 0.2;
          position: absolute;
          z-index: 1;
          right: 10%;
          top: 26px;

          @media @mobile {
            right: 0;
          }

        }

        &--text {
          font-weight: 400;
          font-size: 12px;
          text-align: left;
          line-height: 1.3;
          padding-bottom: 40px;

          @media @mobile {
            padding-bottom: 20px;
          }
        }

        &--user-wrapper {
          text-align: left;
          font-size: 0;
        }

        &--pict {
          width: 40px;
          height: 40px;
          border-radius: 99px;
          overflow: hidden;
          display: inline-block;
          vertical-align: middle;

          img {
            width: 100%;
            height: auto;
          }
        }

        &--name-wrapper {
          display: inline-block;
          vertical-align: middle;
          padding: 0 16px 0 10px;
          width: 133px;
          position: relative;

          @media @mobile {
            width: 100px;
          }

          &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);
            width: 1px;
            height: 20px;
            background: @black;
            opacity: 0.2;
          }
        }

        &--stars-wrapper {
          display: inline-block;
          vertical-align: middle;
          padding: 0 0 0 20px;
          white-space: nowrap;

          .icon-icon-star {
            font-size: 8px;
            display: inline-block;
            color: rgba(0, 0, 0, 0.2);
            margin: 0 2px;

            &.active {
              color: @blue;
            }
          }
        }

        &--name {
          font-size: 12px;
          font-weight: 500;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &--date {
          font-size: 10px;
          font-weight: 500;
          text-transform: uppercase;
          opacity: 0.2;
        }

      }

      &__content {
        font-size: 0;
        text-align: center;
        padding-top: 46px;

        @media @mobile {
          padding-top: 26px;
          margin-bottom: -50px;
        }
      }

      &__half-col {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        position: relative;
        min-height: 140px;

        @media @mobile {
          width: 100%;
        }

        &:first-child {
          &:after {
            content: '';
            position: absolute;
            width: 1px;
            background: @white;
            height: 140px;
            right: 0;
            top: 50%;
            transform: translate(0, -50%);

            @media @mobile {
              width: 140px;
              height: 1px;
              top: auto;
              bottom: 24px;
              left: 50%;
              transform: translate(-50%, 0);
            }
          }
        }
      }
    }
  }

  &.step2, &.step3, &.faq-list-wrapper {
    background: @white;
    border-radius: 20px;
    max-width: 1150px;
    margin: 0 auto 40px;
    position: relative;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
    font-size: 0;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;

    @media @mobile {
      width: calc(~"100% - 20px");
      margin-left: 10px;
      border-radius: 10px;
      overflow: hidden;
    }

    &.reverse {
      flex-direction: row-reverse;
    }

    .section {
      &__title {
        line-height: 1.3;
      }

      &__infos-wrapper {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        padding: 30px 30px 50px;

        @media @mobile {
          width: 100%;
          padding: 30px 30px 30px;
        }
      }

      &__pict-wrapper {
        position: relative;
        width: 50%;

        @media @mobile {
          width: 100%;
        }

        img {
          display: block;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          left: 0;
          object-fit: cover;

          @media @mobile {
            height: auto;
            position: relative;
          }

          &.contain {
            object-fit: contain;
          }
        }
      }

      &__baseline {
        font-size: 16px;
        font-weight: 500;
        margin: 70px 0 20px;
        padding-bottom: 25px;
        position: relative;
        max-width: 400px;
        color: @blue;

        @media @mobile {
          margin-top: 50px;
        }
      }

      &__logos {
        text-align: left;
        font-size: 0;
        padding-top: 80px;

        @media @mobile {
          padding-top: 40px;
        }

        &--agree, &--httpcs {
          display: inline-block;
          width: 180px;
          height: auto;
          vertical-align: middle;

          @media @mobile {
            max-width: calc(~"50% - 15px");
          }
        }

        &--httpcs {
          width: 200px;
          margin-left: 70px;

          @media @mobile {
            margin-left: 30px;
          }
        }

      }

      &__map-wrapper {
        display: inline-block;
        width: 50%;
        vertical-align: top;
        background: url("@{img-path}home/header-bg.jpg") 50% 50% no-repeat;
        background-size: cover;
        min-height: 500px;
        overflow: hidden;
        border-radius: 0 20px 20px 0;
        position: relative;

        &.no-map {
          background: url("@{img-path}homepage/visuel_home_3.png") 50% 50% no-repeat;
          background-size: contain;
          overflow: visible;
        }

        @media @mobile {
          width: 100%;
          border-radius: 0;
        }

        .dossier-wrapper {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          margin-top: 17px;

          @media @mobile {
            width: calc(~"100% - 20px");
            border-radius: 10px;
            margin: 77px 10px 40px;
            position: relative;
            top: 0;
            left: 0;
            transform: translate(0, 0);

          }
        }
      }
    }
  }

  &.faq-list-wrapper {
    padding: 40px;
    font-size: 16px;

    @media @mobile {
      padding: 20px;
    }

    .faq-list {
      margin-top: -30px;
      margin-bottom: -30px;

      @media @mobile {
        margin-top: -15px;
        margin-bottom: -15px;

        .faq-list__title, .faq-list__desc {
          font-size: 13px;
        }
      }

      .faq-list__item {
        &:first-child {
          border-top: 0;
        }
      }
    }
  }

  &.step3 {
    z-index: 9;

    .section {

      &__ipad {
        padding-top: 60px;
        text-align: center;
        height: 500px;
        overflow: hidden;
        position: relative;
        min-width: 480px;

        &--bg {
          width: 480px;
          height: auto;
          position: relative;
          z-index: 10;
        }
      }
    }
  }

  &.testimony {
    max-width: 1150px;
    margin: 0 auto 40px;
    position: relative;
    font-size: 0;
    z-index: 10;

    @media @mobile {
      width: calc(~"100% - 20px");
      margin-left: 10px;
      margin-bottom: 20px;
    }

    .section {
      &__col-wrapper {
        background: @white;
        border-radius: 20px;
        width: calc(~"50% - 15px");
        display: inline-block;
        vertical-align: top;
        position: relative;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
        padding: 30px 0;
        margin-left: 15px;
        text-align: center;

        @media @mobile {
          width: 100%;
          border-radius: 10px;
          margin: 0 0 20px 0;
        }

        &:first-child {
          margin-right: 15px;
          margin-left: 0;
        }
      }

      &__title {
        padding-bottom: 50px;
        padding-left: 10px;
        padding-right: 10px;
      }

      &__label {
        font-size: 14px;
        font-weight: 400;
        padding: 20px 0;
      }
    }
  }

  &.stats {
    background: @blue;
    border-radius: 20px;
    max-width: 1150px;
    margin: 0 auto;
    padding: 60px 0;
    position: relative;
    z-index: 20;
    color: @white;
    font-size: 0;
    text-align: center;

    @media @mobile {
      width: calc(~"100% - 20px");
      margin-left: 10px;
      border-radius: 10px;
      padding: 30px 0;
    }

    .section {
      &__details {
        width: 33%;
        display: inline-block;
        vertical-align: middle;
        position: relative;

        @media @mobile {
          width: 100%;
          padding: 30px 0;
        }

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
          height: 30px;
          width: 1px;
          background: @white;

          @media @mobile {
            width: 30px;
            height: 1px;
            transform: translate(-50%, 0);
            top: 0;
            left: 50%;

          }
        }

        &:first-child {
          &:after {
            display: none;
          }
        }

        &--title {
          font-size: 20px;
          font-weight: 500;
        }

        &--infos {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  &.about-us {
    max-width: 1150px;
    margin: 0 auto;
    padding: 60px 0;
    position: relative;
    z-index: 20;
    font-size: 0;
    text-align: center;

    @media @mobile {
      &:after {
        content: '';
        position: absolute;
        width: 80%;
        left: 10%;
        bottom: 0;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .section {
      &__title {
        font-size: 24px;
      }

      &__img-list {
        text-align: center;
        opacity: 0.3;
        padding-top: 40px;

        img {
          display: inline-block;
          max-width: 90%;
          height: auto;

        }
      }
    }
  }

  &.emergency {
    max-width: 1150px;
    margin: 0 auto;
    padding: 60px 0;
    position: relative;
    z-index: 20;
    font-size: 0;
    text-align: center;

    &__cta {
      text-align: center;
    }

    .section {
      &__title {
        font-size: 24px;
        padding: 0 10px;
        line-height: 1.3;
      }

      &__search-code {
        padding: 30px 0 40px;

        .input-wrapper {
          width: 400px;
          max-width: 90%;
          margin: 0 auto;
          padding: 20px 10px 40px;
        }

        .blue-cta {
          min-width: 250px;
        }
      }
    }
  }
}

.faq-content {
  &.community {
    background: @blue;
    padding: 20px;
    margin: 0 -20px -20px;
    color: white;

    .section__title {
      text-align: center;

      @media @mobile {
        font-size: 16px;

        .title {
          font-size: 20px;
          padding-bottom: 10px;
        }
      }
    }
  }
}

.community {
  &__wrapper {
    display: flex;
    align-items: center;
    padding: 30px 0 20px;
    flex-wrap: wrap;
    justify-content: center;

    @media @mobile {
      padding-top: 0;
    }
  }

  &__list {
    width: 66%;
    padding: 0 20px 0 40px;

    @media @mobile {
      width: 100%;
      padding: 0 10px 0 30px;
    }

    li {
      font-size: 18px;
      line-height: 1.3;
      padding: 10px 0;
      font-weight: 500;
      text-align: left;

      @media @mobile {
        font-size: 16px;
      }
    }
  }

  &__video {
    width: 34%;
    padding-bottom: 19.125%;
    height: 0;
    position: relative;

    @media @mobile {
      width: 100%;
      padding-bottom: 56.25%;
      margin-top: 20px;
    }

    iframe {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }
}

.get-dmp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media @mobile {
    flex-direction: column-reverse;
  }

  &__text {
    text-align: center;
    padding: 20px;
    width: 55%;

    @media @mobile {
      width: 100%;
    }

    .section__title {
      max-width: 460px;
      margin: 0 auto;
      padding-bottom: 30px;

      @media @mobile {
        font-size: 20px;
      }
    }
  }

  &__visual {
    width: 45%;
    margin-bottom: -20px;

    @media @mobile {
      width: 100%;
    }

    img {
      width: 100%;
      height: auto;
      display: block;

      @media @mobile {
        max-width: 250px;
        margin: 0 auto;
      }
    }
  }
}

.access-dmp {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &__text {
    text-align: center;
    padding: 20px;
    width: 60%;

    @media @mobile {
      width: 100%;
    }

    .section__title {
      max-width: 460px;
      margin: 0 auto;
      padding-bottom: 30px;

      @media @mobile {
        font-size: 20px;
      }
    }
  }

  &__visual {
    width: 40%;

    @media @mobile {
      width: 100%;
      padding: 30px 0 0;
    }

    img {
      width: 200px;
      height: auto;
      display: block;
      margin: 0 auto;
    }
  }
}

.partners {
  text-align: center;
  padding: 30px 0;
  font-weight: 500;

  &__title {
    margin: 0;
    font-size: 40px;
    color: @green;
    font-weight: 700;
    padding-bottom: 20px;
  }

  &__desc {
    font-size: 25px;
    line-height: 1.3;
    margin: 0;
    padding-bottom: 20px;
  }

  &__question {
    font-size: 16px;
    line-height: 1.3;
    margin: 0;
    padding-bottom: 20px;
    color: @blue;
  }

  &__cta {
    text-align: center;
    padding-top: 30px;
  }
}

.head-infos {
  text-align: center;
  font-weight: 500;
  color: @blue;
  line-height: 1.3;
  text-transform: uppercase;
  font-size: 16px;
  padding: 30px 20px 0;
  width: 100%;

  @media @mobile {
    padding-bottom: 10px;
    font-size: 14px;
  }
}

.video-header {
  position: relative;
  padding: 40px 20px;
  background: @white;

  @media @mobile {
    padding: 40px 0;
  }

  &__sub {
    max-width: 1150px;
    margin: 0 auto;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 40px -10px 0;

    @media @mobile {
      margin: 30px 10px 0;
    }
  }

  &__inner {
    position: relative;
    width: calc(~"50% - 20px");
    padding-bottom: 27.4%;
    margin: 10px;

    @media @mobile {
      margin: 0 10px 20px;
      padding-bottom: 55%;
      width: 100%;
    }
  }

  &__title {
    font-size: 24px;
    line-height: 1.3;
    margin: 30px 0 20px 0;

    @media @mobile {
      font-size: 18px;
    }
  }

  .center-cta {

    @media @mobile {
      padding-bottom: 60px;
    }
  }

  &__link {
    font-size: 16px;
    text-decoration: underline;
  }

  &__bottom {
    margin-bottom: 80px;
    text-align: center;
    padding: 0 20px;

    @media @mobile {
      margin-bottom: 40px;
    }
  }

  iframe {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.no-contact-footer {
  .footer__contact-us {
    display: none;
  }
}

.vaccin {

  &__details-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
  }

  &__details {
    width: 50%;
    padding: 20px 40px 20px 30px;

    @media @mobile {
      padding: 0;
      width: 100%;
    }

    &--name {
      font-size: 20px;
      font-weight: 700;
      margin: 0 0 20px 0;

      @media @mobile {
        font-size: 18px;
      }
    }

    &--address {
      font-size: 16px;
      margin-bottom: 30px;
    }

    &--iti {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      .icon {
        margin-right: 10px;
      }
    }

    &--phone {
      font-size: 30px;
      font-weight: 700;
      display: flex;
      align-items: center;

      @media @mobile {
        font-size: 22px;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }

  &__map {
    position: relative;
    width: 50%;
    padding-bottom: 50%;
    height: 0;

    @media @mobile {
      width: 100%;
      padding-bottom: 100%;
      margin-top: 40px;
    }

    #mapid {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__tlc {
    width: 100%;
    margin-bottom: 40px;

    &--title {
      line-height: 1.5;
      font-size: 14px;
      font-weight: 700;
      text-align: left;
      color: @blue;
      letter-spacing: normal;
      margin-top: 0;
      padding-bottom: 20px;
    }

    &--cta {
      display: flex;
      justify-content: left;

      @media @mobile {
        .blue-cta {
          width: 100%;
        }
      }
    }
  }

  &__more {
    width: 100%;
    margin-bottom: 10px;

    .simple_link {
      text-decoration: underline;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__list {
    width: 100%;
    min-height: 30px;
    position: relative;
  }

  &__top {
    position: fixed;
    z-index: 100;
    bottom: 50px;
    right: 20px;
    width: 50px;
    height: 50px;
    background: @blue;
    border-radius: 100px;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: all 100ms linear;

    &.visible {
      visibility: visible;
      opacity: 1;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -32%) rotate(45deg);
      width: 15px;
      height: 15px;
      border-left: 4px solid @white;
      border-top: 4px solid @white;
    }
  }

  .section__title {
    margin-bottom: 50px !important;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: @yellow;
      width: 40px;
      height: 40px;
      border-radius: 100px;
    }

    @media @mobile {
      font-size: 18px;
      margin-bottom: 40px !important;
    }
  }

  .input-wrapper {
    max-width: 400px;
    text-align: left;
    padding-left: 0;
    padding-right: 0;

    label {
      font-size: 16px;
      font-weight: 700;
      text-transform: none;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      white-space: normal;
      line-height: 1.3;

      .icon {
        background: @antracite;
        color: @white;
        width: 30px;
        min-width: 30px;
        height: 30px;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
      }
    }

    select {
      border: 2px solid @blue;
    }
  }

  &__dept {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
  }

  &__dept-name {
    text-transform: uppercase;
    font-size: 20px;
    color: @antracite;
    font-weight: 700;
    padding: 5px 0;
    border-bottom: 1px solid @mediumgrey2;
    width: 100%;
    margin: 20px 0 30px;

    @media @tablet {
      font-size: 18px;
    }
  }

  &__centre {
    margin-bottom: 30px;
    width: 50%;
    padding-right: 20px;
    position: relative;

    &--nextdispo {
      strong {
        display: block;
        margin-top: 10px;
        font-size: 18px;
        color: @antracite;

        @media @verysmallmobile {
          font-size: 16px;
        }
      }
    }

    &--no-result {
      padding: 30px 0;
      font-size: 16px;
    }

    &.has_dispo {
      padding-left: 20px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background: @green;
      }
    }

    &.no_dispo {
      padding-left: 20px;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 4px;
        height: 100%;
        background: @red;
      }
    }

    @media @tablet {
      width: 100%;
      padding-right: 0;
    }

    &--nodispo {
      font-weight: 700;
      color: @red;
      margin-bottom: 10px;
      font-size: 12px;
    }

    &--title {
      font-size: 14px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    &--addr {
      font-weight: 400;
      margin: 0 0 10px 0;
      font-size: 14px;
    }

    &--link-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: 20px;
    }

    &--link {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      margin-right: 30px;
      width: 100%;

      .icon {
        font-size: 16px;
        margin-right: 10px;
        color: @antracite;
      }
    }
  }
}

.hp-services {
  background: @white;
  padding-bottom: 50px;

  @media @tablet {
    padding-bottom: 0;
  }

  &__inter {
    padding: 30px 10px 0;

    &--inner {
      max-width: 1280px;
      margin: 0 auto;
      text-align: center;
    }

    &--title {
      font-weight: 400;
      font-size: 20px;
      line-height: 1.5;

      @media @mobile {
        font-size: 16px;
      }
    }
  }

  &__logos {
    max-width: 960px;
    padding: 10px 20px 60px;
    margin: 0 auto;

    @media @mobile {
      padding: 0 20px 10px;
    }

    &--title {
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 1.5;

      @media @mobile {
        font-size: 16px;
      }
    }

    &--list {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 40px 0 0;

      @media @mobile {
        flex-wrap: wrap;
      }

      img {
        margin: 0 10px 40px;
        width: 200px;
      }
    }
  }

  &__present, &__partners {
    display: flex;
    align-items: center;
    font-weight: 500;
  }

  &__present {

    margin-bottom: 20px;
    font-size: 16px;
    color: #919191;

    &--img {
      width: 90px;
      height: auto;
      margin-right: 8px;
      filter: grayscale(1);
    }
  }

  &__partners {
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    color: @antracite;

    &--img {
      width: 110px;
      height: auto;
      margin-left: 8px;
    }
  }

  &__section-title {
    font-size: 24px;
    text-align: left;
    line-height: 1.3;
    font-weight: 700;
    padding: 0 0 20px;
    width: 100%;
    position: relative;
    margin-bottom: 20px;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 40px;
      height: 4px;
      background: @green;

      @media @mobile {
        left: 20px;
      }
    }

    @media @mobile {
      font-size: 20px;
      padding: 0 20px 20px;
    }
  }

  &__section-desc {
    font-size: 16px;
    text-align: left;
    line-height: 1.6;
    padding: 0 0 40px;
    max-width: 560px;
    width: 100%;

    &.with-padding {
      padding: 0 20px 0;
    }

    @media @mobile {
      font-size: 14px;
      padding: 0 20px 20px;
    }
  }

  &__inner {
    max-width: 1150px;
    margin: 0 auto 40px;
    padding: 50px 20px 60px;

    @media @mobile {
      padding: 30px 0 0;
    }
  }

  &__all-services {

    margin: 0 auto 40px;
    position: relative;

    &--list {
      list-style: none;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;

      @media @mobile {
        margin: 0 10px;
      }
    }

    &--item {
      width: 50%;

      @media @tablet {
        width: 100%;
      }

      .services-lm {
        &__pushes {
          &--title {
            font-size: 16px;
            margin-bottom: 5px;

            @media @mobile {
              font-size: 14px;
              margin-bottom: 5px;
            }
          }

          &--content {
            margin-bottom: 10px;
            opacity: 0.7;
          }

          &--cta {
            .blue-cta {
              font-weight: 700;
              text-transform: none;
              padding: 5px 12px;
              color: @blue;
              background: @white;
              border-radius: 100px;
              height: auto;
              line-height: 1;
            }
          }

          &--desc {
            max-width: calc(~"100% - 80px");
          }

          &--img {
            min-width: 76px;
            width: 76px;

            &.orange {
              background: @s_orange;
            }

            &.blue {
              background: @s_blue;
            }

            &.pink {
              background: @s_pink;
            }

            &.purple {
              background: @s_purple;

              .hp-services__main--cta-btn {
                background: @antracite;
                color: @white;
              }
            }

            &.green {
              background: @s_green;

              .hp-services__main--cta-btn {
                background: @blue;
                color: @white;
              }
            }

            @media @mobile {
              min-width: 80px;
              width: 80px;
            }
          }

          &--push {
            margin: 15px;
            border-radius: 10px;
            align-items: center;

            @media @mobile {
              margin: 0;
              padding: 14px 10px;
            }
          }
        }
      }
    }
  }

  &__header {
    background: @white;
    min-height: 446px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

    @media @tablet {
      height: calc(~"100vh - 60px");
      height: calc(~"var(--vh, 1vh) * 100 - 60px");
      min-height: 590px;
      position: relative;
      transition: height 300ms linear;

      &.with-top-banner {
        height: calc(~"100vh - 130px");
        height: calc(~"var(--vh, 1vh) * 100 - 130px");
      }
    }

    @media @verysmallmobile {
      min-height: 480px;
    }

    &.sephira {

      @media @tablet {
        min-height: auto;
        height: auto;
        padding-bottom: 20px;
      }

      @media @verysmallmobile {
        min-height: auto;
        height: auto;
      }
    }
  }

  &__others {

    padding: 10px 20px 40px;
    margin: 10px 0 50px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    align-items: center;
    border-radius: 10px;
    font-family: Verdana, Sans-Serif;
    display: none;

    @media @tablet {
      display: flex;
      left: 50%;
      transform: translate(-50%, 0);
      position: absolute;
      bottom: 30px;
      margin: 0;
    }

    &:after, &:before {
      content: '';
      position: absolute;
      bottom: 13px;
      left: 50%;
      display: block;
      width: 10px;
      height: 10px;
      border-right: 2px solid @white;
      border-bottom: 2px solid @white;
      transform: translate(-50%, 0) rotate(45deg);
    }

    &:before {
      bottom: 2px;
      width: 30px;
      height: 30px;
      background: @green;
      border: 0;
      border-radius: 100px;
      transform: translate(-50%, 0);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    max-width: 1150px;
    margin: 0 auto;
    padding: 70px 20px 40px;
    position: relative;

    @media @tablet {
      flex-wrap: wrap;
      padding: 30px 20px 0;
    }

    @media @verysmallmobile {
      padding-top: 20px;
    }
  }

  &__title {
    font-size: 39px;
    font-weight: 700;
    line-height: 1.3;
    font-family: Verdana, Sans-Serif;

    .blue-text {
      font-family: Verdana, Sans-Serif;
    }

    @media @tablet {
      font-size: 23px;
    }

    @media @verysmallmobile {
      font-size: 21px;
    }
  }

  &__subtitle {
    font-size: 16px;
    line-height: 1.5;
    font-family: Verdana, Sans-Serif;
    padding: 20px 0 70px;
    opacity: 0.7;

    img {
      vertical-align: middle;
      margin-left: 0.2em;
      margin-bottom: 6px;
    }

    &.bigger {
      opacity: 1;
      font-size: 20px;
    }

    @media @mobile {
      padding-bottom: 30px;
    }

    @media @verysmallmobile {
      font-size: 14px;
      padding-top: 15px;
      padding-bottom: 20px;
    }
  }

  &__sephira {
    padding: 60px 20px;
    width: 100%;
    max-width: 960px;
    margin: 0 auto;

    &--logo {
      img {
        display: block;
        width: 200px;
        height: auto;

        @media @mobile {
          width: 180px;
        }
      }
    }

    &--desc {
      padding: 40px 0 20px;
      line-height: 1.8;
      font-weight: 500;
      font-size: 20px;
      display: flex;
      justify-content: center;

      p {
        width: 100%;
      }

      @media @mobile {
        font-size: 16px;
        padding-top: 20px;
      }
    }
  }

  &__teleconsultation-desc {
    padding: 40px 0;

    &--title {
      padding: 0 20px 40px;
      max-width: 1150px;
      margin: 0 auto;
      font-size: 20px;
      text-align: center;
      line-height: 1.5;

      @media @mobile {
        font-size: 18px;
        padding-bottom: 20px;
      }

      @media @verysmallmobile {
        font-size: 17px;
      }
    }

    @media @mobile {
      padding: 20px 0 40px;
    }
  }

  &__cta-center {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 40px 0;
  }

  &__cta {
    display: flex;
    align-items: center;

    .blue-cta {
      margin-right: 30px;

      span {
        font-family: Verdana, Sans-Serif;
        font-size: 16px;
      }
    }

    @media @tablet {
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 10px;

      .blue-cta {
        margin: 0 5px 10px;
      }
    }

    @media @verysmallmobile {
      margin-top: 5px;
    }
    @media @desktoponly {
      &.fixed {
        position: fixed;
        top: 60px;
        left: 0;
        width: 100%;
        background: @white;
        z-index: 100;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        justify-content: center;

        .blue-cta {
          margin: 0 15px;
        }
      }
    }
  }

  &__desc {
    width: 55%;

    @media @tablet {
      width: 100%;
    }
  }

  &__visual {
    width: 45%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0;

    &.sephira {
      margin-top: 30px;

      @media @mobile {
        margin-top: 10px;
      }

      img {
        width: 400px;
        height: auto;

        &.logo-sephira {
          position: absolute;
          z-index: 10;
          width: 24%;
          left: 45%;
          top: 16%;

          @media @tablet {
            width: 50px;
            left: 48%;
          }
          @media @mobile {
            width: 14%;
            left: 46%;
          }
        }
      }
    }

    @media @tablet {
      width: 100%;
      margin-top: -10px;
      height: 166px;
      overflow: hidden;
      position: relative;
      transform: none;
      top: auto;
      right: auto;
    }

    @media @verysmallmobile {
      height: 114px;
    }

    img {
      display: block;
      width: 320px;
      height: 320px;

      @media @tablet {
        width: auto;
        height: 200px;
      }

      @media @verysmallmobile {
        height: 140px;
      }
    }
  }
}