.clear() {
  clear: both;
  content: '';
  display: block;
}

.transition {
  transition: all 150ms linear;
}

.font-face(@font-name, @fonts-path, @font-weight, @font-style) {
  @font-face {
    font-family: @font-name;
    font-style: @font-style;
    font-weight: @font-weight;
    src: url('@{fonts-path}.woff2') format('woff2'),
    url('@{fonts-path}.woff') format('woff');
    font-display: swap;
  }
}

.setMultiCol2(@col,@state) {
  &[data-nb-rdv="@{state}@{col}"] {
    max-width: calc(~"(100% - 35px) / @{col}") !important;
    @media @desktoponly {
      max-width: calc(~"(100% - 45px) / @{col}") !important;
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"(100% - 35px)/@{col} + 25px");
    @media @desktoponly {
      left: calc(~"(100% - 45px)/@{col} + 25px");
    }
  }
}


.setMultiCol3(@col,@state) {
  &[data-nb-rdv="@{state}@{col}"] {
    max-width: calc(~"(100% - 35px) / @{col}") !important;
    @media @desktoponly {
      max-width: calc(~"(100% - 45px) / @{col}") !important;
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"(100% - 35px)/@{col} + 25px");
    @media @desktoponly {
      left: calc(~"(100% - 45px)/@{col} + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*2 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*2 + 25px");
    }
  }
}

.setMultiCol4(@col,@state) {
  &[data-nb-rdv="@{state}@{col}"] {
    max-width: calc(~"(100% - 35px) / @{col}") !important;
    @media @desktoponly {
      max-width: calc(~"(100% - 45px) / @{col}") !important;
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"(100% - 35px)/@{col} + 25px");
    @media @desktoponly {
      left: calc(~"(100% - 45px)/@{col} + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*2 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*2 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*3 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*3 + 25px");
    }
  }
}


.setMultiCol5(@col,@state) {
  &[data-nb-rdv="@{state}@{col}"] {
    max-width: calc(~"(100% - 35px) / @{col}") !important;
    @media @desktoponly {
      max-width: calc(~"(100% - 45px) / @{col}") !important;
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"(100% - 35px)/@{col} + 25px");
    @media @desktoponly {
      left: calc(~"(100% - 45px)/@{col} + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*2 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*2 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*3 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*3 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*4 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*4 + 25px");
    }
  }
}

.setMultiCol6(@col,@state) {
  &[data-nb-rdv="@{state}@{col}"] {
    max-width: calc(~"(100% - 35px) / @{col}") !important;
    @media @desktoponly {
      max-width: calc(~"(100% - 45px) / @{col}") !important;
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"(100% - 35px)/@{col} + 25px");
    @media @desktoponly {
      left: calc(~"(100% - 45px)/@{col} + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*2 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*2 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*3 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*3 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*4 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*4 + 25px");
    }
  }

  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*5 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*5 + 25px");
    }
  }
}

.setMultiCol7(@col,@state) {
  &[data-nb-rdv="@{state}@{col}"] {
    max-width: calc(~"(100% - 35px) / @{col}") !important;
    @media @desktoponly {
      max-width: calc(~"(100% - 45px) / @{col}") !important;
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"(100% - 35px)/@{col} + 25px");
    @media @desktoponly {
      left: calc(~"(100% - 45px)/@{col} + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*2 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*2 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*3 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*3 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*4 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*4 + 25px");
    }
  }

  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*5 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*5 + 25px");
    }
  }

  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*6 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*6 + 25px");
    }
  }
}

.setMultiCol8(@col,@state) {
  &[data-nb-rdv="@{state}@{col}"] {
    max-width: calc(~"(100% - 35px) / @{col}") !important;
    @media @desktoponly {
      max-width: calc(~"(100% - 45px) / @{col}") !important;
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"(100% - 35px)/@{col} + 25px");
    @media @desktoponly {
      left: calc(~"(100% - 45px)/@{col} + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*2 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*2 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*3 + 25px");
    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*3 + 25px");
    }
  }
  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*4 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*4 + 25px");
    }
  }

  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*5 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*5 + 25px");
    }
  }

  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*6 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*6 + 25px");
    }
  }

  &[data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] + [data-nb-rdv="@{state}@{col}"] {
    left: calc(~"((100% - 35px)/@{col})*7 + 25px");

    @media @desktoponly {
      left: calc(~"((100% - 45px)/@{col})*7 + 25px");
    }
  }
}