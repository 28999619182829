@headerHeight: 60px;
@headerHeightMobile: 60px;

.top-header {
  transition: all 300ms linear;
  overflow: hidden;
  position: relative;
  height: 0;

  &__inner-row {
    width: 100%;

    .blue-cta {
      margin: 5px 10px;

      .icon {
        font-size: 15px;
        margin-right: 5px;
      }

      @media @mobile {
        margin: 5px 10px 0;
        height: 25px;
        line-height: 25px;
      }
    }
  }

  .telecons-praticien-group.active {
    padding: 0;

    @media @mobile {
      display: none !important;
    }

    .telecons-praticien-group--pict {
      transform: scale(0.8);
      margin: -10px 0 -30px 0;
    }
  }


  &__text {
    font-size: 18px;
    font-weight: 500;
    margin-right: 10px;
    color: @black;

    @media @mobile {
      font-size: 14px;
      line-height: 1;
      width: 100%;
      align-self: flex-end;
      margin-bottom: 5px;
    }

    .inline-green-line {
      font-size: 18px;
      display: inline-block;
      color: @black;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: -20px;
        top: 50%;
        transition: all 150ms linear;
        height: 10px;
        width: 10px;
        border-top: 2px solid @black;
        border-right: 2px solid @black;
        transform: translate(0, -50%) rotate(45deg);

        @media @verysmallmobile {
          right: -14px;
        }
      }

      @media @mobile {
        font-size: 14px;
        line-height: 1.3;
      }
    }
  }

  &__cta-wrapper {
    @media @mobile {
      width: 100%;
      align-self: flex-start;
    }
  }

  &__show-more {
    margin-left: 20px;

    @media @mobile {
      display: none;
    }
  }

  &__quote {
    width: 25px;
    margin-right: -15px;
    position: relative;
    top: -40px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__cookie {
    padding: 20px;
  }

  &__wrapper {
    padding: 20px;
    background: #fff;
    height: @headerHeight;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    @media @mobile {
      height: @headerHeightMobile;
      padding: 20px 10px;
    }
  }

  &__close {
    position: absolute;
    top: 5px;
    right: 5px;
    height: 20px;
    width: 20px;
    background: @black;
    border: 2px solid @white;
    border-radius: 100px;
    color: transparent;
    opacity: 0;
    visibility: hidden;
    transition: all 100ms linear;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    &:after, &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 70%;
      height: 2px;
      background: @white;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__cta {
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 10;
    opacity: 0;
    transition: all 150ms linear;
    display: block;
    padding: 5px 15px;
    font-size: 14px;
    font-weight: 500;
    background: @green;
    color: @white;
    border-radius: 100px;

    @media @mobile {
      display: none;
    }

    &.visible {
      opacity: 1;
    }
  }

  &__slide {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translate(0, -50%);
    line-height: 1.3;
    opacity: 0;
    transition: all 150ms linear;
    display: flex;
    height: 100%;

    @media @mobile {
      padding: 0;
      font-size: 16px;
    }

    &.visible {
      opacity: 1;
    }

    &.static {
      @media @mobile {
        margin-top: 30px;
        position: static;
      }
    }
  }

  &__row {
    width: 50%;
    position: relative;

    &:first-child {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        top: 50%;
        height: 80%;
        width: 1px;
        background: rgba(0, 0, 0, 0.3);
        transform: translate(0, -50%);
      }
    }
  }


  &__video-thumb {
    height: 100%;
    margin-right: 22px;
    padding: 10px 0;
    text-align: right;

    img {
      height: 100%;
      max-width: 100%;
      width: auto;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      @media @mobile {
        object-fit: cover;
      }
    }
  }

  &__inner-text {
    width: 100%;
    font-size: 16px;
    font-weight: 500;

    .icon {
      vertical-align: middle;
      color: @green;
      margin-left: 5px;
      display: none;

      @media @mobile {
        display: inline-block;
      }

    }
  }

  &__fullrow {
    width: 100%;
    text-align: center;
    padding: 0 20px 0 20px;
    font-size: 14px;
    color: @black;
    display: flex;
    line-height: 1.7;
    justify-content: center !important;
    align-items: center;
    font-weight: 400;
    flex-wrap: wrap;

    .blue-cta {
      margin: 5px;
      padding-left: 10px;
      padding-right: 10px;
    }

    span {
      vertical-align: middle;
    }

    @media @mobile {
      padding: 0 10px;
      font-size: 11px;
    }

    strong {
      display: inline-block;
      font-weight: 500;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        bottom: 0px;
        height: 2px;
        left: 0;
        background: @green;

        @media @mobile {
          bottom: -1px;
        }
      }
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 500;

    @media @mobile {
      font-size: 14px;
    }
  }

  &__desc {
    font-size: 14px;
    font-weight: 300;

    @media @mobile {
      font-size: 12px;
    }
  }
}

.has-second-banner, .has-second-banner-bigger {

  .content__header {
    @media @tablet {
      padding-top: 42px;
    }

    @media @smallmobile {
      padding-top: 36px;
    }
  }

  .profil-block-wrapper.fixed-block {
    margin-top: 30px;
    max-height: calc(~"100vh - 150px") !important;

    @media @tablet {
      margin-top: 5px;
      max-height: initial !important;
    }
  }

  .content__header-wrapper {
    margin-top: 120px;

    @media @tablet {
      min-height: initial;
      margin-top: 40px;
    }
  }

  &.has-top-header {
    .content__header-wrapper {
      min-height: calc(~"100vh - 200px");

      @media @tablet {
        min-height: initial;
      }
    }

    .profil-block-wrapper.fixed-block {
      margin-top: 40px;
      max-height: calc(~"100vh - 200px") !important;

      @media @tablet {
        padding-top: 0;
        margin-top: -10px;
        height: auto !important;
        max-height: initial !important;

        .fixed-content-visible& {
          padding-top: 45px;
        }
      }

      @media @mobile {

        .fixed-content-visible& {
          padding-top: 42px;
        }
      }

      @media @verysmallmobile {
        padding-top: 0;

        .fixed-content-visible& {
          padding-top: 36px;
        }
      }
    }
  }
}

.has-second-banner-bigger {
  .content__header {
    @media @tablet {
      padding-top: 0;
    }

    @media @smallmobile {
      padding-top: 0;
    }
  }
  .content__header-wrapper {
    margin-top: 160px;

    @media @tablet {
      min-height: initial;
      margin-top: 120px;
    }
  }

  .profil-block-wrapper.fixed-block {
    margin-top: -20px;
    max-height: calc(~"100vh - 80px") !important;

    @media @tablet {
      margin-top: 5px;
      max-height: initial !important;
    }
  }
}

.has-top-header:not(.has-fixed-elements) {

  &.has-second-banner {
    .content__header-wrapper {
      top: 5px;
    }
  }

  .header, .section__search-wrapper {
    position: relative !important;
    top: 0 !important;
  }

  .header-wrapper {
    @media @tablet {
      margin-top: 0;
    }
  }

  .content__header-wrapper {
    top: -35px;
    margin-top: 0;
    overflow: hidden;

    @media @tablet {
      top: 0;
      margin-top: 0;
    }

    @media @mobile {
      width: 100%;
      margin: 0;
      border-radius: 0;
    }
  }

  .profil-block-wrapper.fixed-block {
    top: 145px;
    max-height: calc(~"100vh - 170px");

    @media @tablet {
      top: 0;
      max-height: initial;
    }
  }

  .profil-block-wrapper.fixed {
    top: 146px;
    max-height: calc(~"100vh - 170px");

    @media @tablet {
      top: 0;
      max-height: initial;
    }
  }

  .header-right__content-wrapper {
    /*max-height: calc(~"100vh - 370px");*/
    margin: 0 -20px;

    @media @tablet {
      margin: 0;
    }
  }

  .header__after-promo {
    margin-top: 0;
    margin-bottom: -@headerHeight;

    @media @mobile {
      margin-bottom: -@headerHeightMobile;
    }
  }

  &.medecin-profil .mapWrapper {
    margin-top: 0;
  }
}


.has-top-header.fixed-content-visible {
  .profil-block-wrapper.fixed-block {
    @media @tablet {
      margin-top: @headerHeight*2;
      max-height: calc(~"100vh - 120px");
    }
  }
}

.fixed-content-visible {
  .alert-infos.profil3 {
    max-width: 100%;
    border-radius: 0;
    top: 60px;

    .alert-infos__header {
      width: 100%;
      border-radius: 0;
    }
  }

  .fixed-block {
    height: calc(~"100vh - 60px");
    max-height: calc(~"100vh - 60px");
    top: 60px;
    left: auto;
    right: 0;
    transform: none;
    border-radius: 0;
  }

  &.has-second-banner {
    .fixed-block {
      margin-top: 40px;
      height: calc(~"100vh - 105px") !important;
      max-height: calc(~"100vh - 105px") !important;
    }
  }
}

.has-top-header {

  .content__header-wrapper {
    min-height: calc(~"100vh - 170px");

    @media @tablet {
      min-height: auto;
      margin-left: 0;
      width: 100%;
      border-radius: 0;
      margin-top: 66px;
    }
  }

  .top-header {
    height: @headerHeight;
    z-index: 300;

    @media @mobile {
      height: @headerHeightMobile;
    }
  }

  &.has-ad {
    .top-header {
      height: 0;
    }
  }
}

.popin-youtube {
  &.popin-med__super-wrapper.popin-visible {
    z-index: 200000;
  }

  iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }

  .popin-med__wrapper {
    @media @tablet {
      top: 0;
      height: 100%;
      max-height: 100%;
    }
  }
}

#telecons-faq {
  margin: 0 -20px;

  @media @tablet {
    margin: 0;
  }
}