.ads-slider {
  padding: 20px 0;
  overflow: hidden;
  min-width: 100%;
  display: flex;
  justify-content: center;

  @media @tablet {

  }

  @media @mobile {
    margin: 0 -20px;
  }

  @media @verysmallmobile {

  }

  .slick-dots {
    li {
      transition: width, height 150ms linear;
      vertical-align: middle;

      &.slick-active {

        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        background: @blue;
        border-radius: 100px;
        color: @white;
        box-shadow: 0 2px 2px rgba(0, 0, 0, .2);

        button {

          background: none;
          font-size: 16px;
          font-weight: 500;
          color: @white;
          display: inline;
          line-height: 1;

          &:before {
            display: none;
            color: @blue;
          }
        }

      }

      button:before {
        color: @blue;
      }
    }
  }

  .slick-next, .slick-prev {
    width: 40px;
    height: 40px;
    border-radius: 100px;
    /*border: 1px solid @black;*/
    opacity: 1;
    background: @blue;
    box-shadow: 0 1px 2px rgba(0, 0, 0, .3);
    z-index: 1;

    @media @mobile {
      display: none !important;
    }

    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 14px;
      height: 14px;
      border-top: 2px solid @white;
      border-left: 2px solid @white;
    }
  }

  .slick-next {
    right: 5px;

    &:before {
      transform: translate(-60%, -50%) rotate(135deg);
    }
  }

  .slick-prev {
    left: 5px;

    &:before {
      transform: translate(-40%, -50%) rotate(-45deg);
    }
  }

  .slide {
    width: 200px;
    max-width: 70vw;
    min-width: 200px;

    @media @mobile {
      min-width: 70vw;
    }

    opacity: 1;
    transition: all 150ms linear;

    &__cta {
      padding: 15px;

      .blue-cta {
        width: 100%;
        padding: 0 10px;
        font-size: 10px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &.slick-current {
      opacity: 1;
    }

    &__inner {
      border-radius: 10px;
      margin: 5px 15px 5px 5px;
      overflow: hidden;
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);

      @media @mobile {
        margin: 5px 5px 5px 15px;
      }
    }

    &__img {

      img {
        max-width: 100%;
        max-height: 100px;
        margin: 0 auto;

        @media @mobile {
        }
      }
    }

    &__infos {
      color: @black;
      padding: 20px;
      font-weight: 300;
      text-align: center;
    }

    &__title {
      font-weight: 400;
      line-height: 1.3;
      padding: 15px;
      font-size: 12px;
      text-align: left;
      min-height: 75px;

      @media @mobile {
        font-size: 14px;
      }
    }

    &__desc {
      text-transform: none;
      line-height: 1.5;
      font-size: 14px;
    }
  }
}