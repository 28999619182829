.ui-widget-header{
  background: @antracite;
  color: @white;
  border: 0;
  border-radius: 0;
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span{
  background: none;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    top: 50%;
    border-top: 2px solid @white;
    border-right: 2px solid @white;
    transform: rotate(45deg) translate(0, -50%);
    transition: all 150ms linear;
    transform-origin: 50% 0%;
  }
}

.ui-datepicker .ui-datepicker-next {
  &:after {
    right: 20px;
    transform: rotate(45deg) translate(0, -50%);
  }
}

.ui-datepicker .ui-datepicker-prev {
  &:after {
    left: 20px;
    transform: rotate(-135deg) translate(0, -50%);
  }
}

.ui-datepicker-prev.ui-state-hover, .ui-datepicker-prev.ui-state-focus,.ui-datepicker-prev:hover, .ui-datepicker-prev:focus,
.ui-datepicker-next.ui-state-hover, .ui-datepicker-next.ui-state-focus,.ui-datepicker-next:hover, .ui-datepicker-next:focus{
  border: 0;
  background: none;
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover{
  top: 2px;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active{
  border: 0;
  text-align: center;
}

.visible-datepicker {
  .ui-widget.ui-widget-content{
    border: 0;
    width: 100%;
    z-index: 2000000 !important;
  }
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover{
  background: @green;
  color: @white;
}

.ui-widget.ui-widget-content{
  padding: 0;
}

.ui-datepicker .ui-datepicker-title select{
  color: @white;
  background: @antracite;
  -webkit-appearance: none;
  border: 0;
}