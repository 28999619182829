.teleconsultation {

  .section {
    &__content {
      max-width: 960px;
      margin: 0 auto;
    }

    &__cta {
      text-align: center;
      padding: 50px 0 20px;
    }
  }

  .teleconsultation-header {
    background: @white;
    overflow: hidden;
    padding: 50px 0 60px;

    &.homepage-header {
      overflow: visible;
      padding-top: 30px;

      @media @mobile {
        margin-bottom: 70px;

        .section__col-visual {
          display: none;
        }
      }

      .search-nav {
        margin: 0 auto -64px;
      }
    }

    .center-link {
      display: none;
      text-align: center;
      font-size: 14px;
      font-weight: 500;
      color: @blue;
      width: 100%;
      margin: 30px 0 10px;

      @media @mobile {
        display: block;
      }
    }

    .section__cta {
      text-align: left;
      padding: 0;
      font-size: 0;

      @media @mobile {
        &.center {
          text-align: center;
          .blue-cta {
            margin: 0 auto;
          }
        }
      }

      .blue-cta {
        margin: 0 10px 0 0;

        @media @verysmallmobile {
          width: 100%;
          margin: 0 0 10px 0;
        }
      }
    }

    @media @mobile {
      padding: 20px 0 0;
    }

    .input-wrapper {
      padding-bottom: 30px;
      padding-left: 0;

      label {
        padding-left: 0;
      }

      @media @mobile {
        max-width: 230px;
        min-height: 100px;

        label {
          font-size: 15px;
        }

        select {
          font-size: 16px;
        }
      }
    }

    .section {
      &__content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @media @tablet {
          padding: 0 30px;
        }

        @media @mobile {
          padding: 0 20px;
        }
      }

      &__col-desc {
        width: 60%;

        @media @mobile {
          width: 100%;
          padding-bottom: 20px;
        }
      }

      &__col-visual {
        width: 40%;

        @media @mobile {
          width: 100%;
          height: 250px;
          padding: 30px 0 0 0;
          overflow: hidden;
        }
      }

      &__title {
        font-size: 38px;
        line-height: 1.1;
        padding-bottom: 20px;
        font-weight: 600;
        text-align: left;

        small {
          padding-top: 10px;
        }

        &.no-cab {
          font-size: 25px;
          padding-bottom: 30px;

          @media @mobile {
            font-size: 22px !important;
            margin-top: 10px;
          }
        }

        &.smaller {
          font-size: 28px;
          line-height: 1.3;

          @media @mobile {
            font-size: 22px;
          }
        }

        @media @mobile {
          font-size: 26px !important;
        }
      }
    }
  }

  &__visual {
    position: relative;
    height: 400px;
    width: 194px;
    margin: 0 auto;
    overflow: hidden;

    img {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      z-index: 2;
      border-radius: 24px;
    }
  }

  &__smartphone {
    position: relative;
    height: 400px;
    width: 194px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);

    &--mask {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 2;
      border-radius: 24px;
    }

    &--img {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: auto;
      z-index: 1;
      border-radius: 24px;
    }
  }

  .teleconsultation-nav {
    padding: 30px 30px 0 30px;
    margin-bottom: -30px;
    clear: both;

    @media @mobile {
      padding: 20px 20px 0 20px;
      margin-bottom: -20px;
    }
  }

  .teleconsultation-tarif {
    padding: 30px;

    @media @mobile {
      padding: 20px;
    }

    .section {
      &__content {

      }
    }
  }

  .teleconsultation-desc {
    background: @white;

    .section {
      &__content {
        padding: 0 20px;
      }

      &__cta {
        padding: 30px 0 40px;
      }
    }
  }

}

.teleconsultation-why {
  &--list-bullet {
    list-style: none;
    margin: 15px auto 0 auto;
    padding: 0 20px;
    max-width: 1150px;

    li {
      position: relative;
      padding-left: 20px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 30px;
      line-height: 1.5;

      &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        left: 2px;
        top: 9px;
        background: @green;
        border-radius: 100px;
      }
    }

    &.smaller {
      padding: 0;
      margin-top: 20px;

      li {
        margin-bottom: 10px;
        font-size: 14px;
        text-transform: none;
        font-weight: 400;
      }
    }
  }

  &--link {
    font-size: 16px;
    text-decoration: underline;
    background: none;
    font-weight: 300;
    color: @blue;
    margin-top: 15px;
    text-align: left;
    line-height: 1.5;
  }

  .section {
    &__content {
      padding: 40px 0;
    }

    &__title {
      font-size: 24px;
      color: @blue;
      padding: 0 20px 50px;
      text-align: center;
      margin: 0 auto;
      max-width: 530px;
      font-weight: 600;
      line-height: 1.3;

      @media @mobile {
        font-size: 20px;
      }

      &.bigger {
        font-size: 40px;
        line-height: 1;
        max-width: 100%;
        padding-bottom: 20px;

        @media @mobile {
          font-size: 30px;
        }

        small {
          font-size: 20px;
          font-weight: 500;
          color: @black;
          display: block;
          line-height: 1.3;
          padding-top: 10px;

          @media @mobile {
            font-size: 16px;
          }
        }
      }
    }

    &__pictos {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 760px;

      @media @mobile {
        padding: 0 20px;
      }

      .icon {
        font-size: 60px;
        margin: 0 0 40px;

        @media @mobile {
          font-size: 40px;
          margin-bottom: 20px;
        }
      }
    }
  }
}

.teleconsultation-motif {
  background: @white;

  .section {
    &__title {
      font-size: 24px;
      padding: 0 20px 50px;
      text-align: center;
      margin: 0 auto;
      font-weight: 600;
      line-height: 1.3;
      color: @black;

      @media @mobile {
        font-size: 20px;
      }
    }

    &__content {
      padding: 40px 20px;
      max-width: 960px;
    }

    &__motifs {
      position: relative;

      &--subject {
        width: 50%;

        @media @mobile {
          width: 100%;
        }
      }

      &--label {
        font-size: 18px;
        font-weight: 500;
        padding: 0 20px 15px;
        cursor: pointer;
        transition: all 150ms linear;

        @media @mobile {
          padding: 0 0 15px 0;
          font-size: 16px;
        }

        &.active {
          color: @green;
        }
      }

      &--list {
        position: absolute;
        width: 50%;
        right: 0;
        top: 0;
        opacity: 0;
        height: 0;
        overflow: hidden;
        transition: all 150ms linear;

        @media @mobile {
          width: 100%;
          position: relative;
        }

        &.active {
          opacity: 1;
          height: auto;
        }

        ul {
          list-style-position: inside;
          list-style-type: square;
          font-size: 0;

          li {
            font-size: 14px;
            font-weight: 400;
            opacity: 0.6;
            line-height: 1.3;
            padding-bottom: 10px;
            padding-right: 20px;
            width: 50%;
            display: inline-block;
            vertical-align: top;
          }
        }
      }
    }
  }
}

.teleconsultation-avis {
  padding: 30px 0;

  @media @mobile {
    padding: 20px 0;
  }

  .avis-item {
    background: @white;
    display: inline-block;
    vertical-align: middle;
    min-width: 300px;
    width: 300px;
    margin: 15px;
    border-radius: 10px;
    padding: 20px 20px 30px 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.02);

    @media @smallmobile {
      width: 70vw;
      min-width: 70vw;
      margin: 15px 0 15px 20px;
    }

    &__pict {
      margin: 0 auto 30px;
      width: 100px;
      height: 100px;
      border-radius: 200px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      font-weight: 400;
      font-size: 12px;
      text-align: center;
      line-height: 1.5;

      &--name {
        font-weight: 500;
      }

      &--stars {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 10px;

        .star {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .section {

    &__avis {
      display: flex;
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
      justify-content: center;

      @media @tablet {
        justify-content: flex-start;
      }
    }

    &__title {
      color: @blue;
      text-align: center;
      font-size: 102px;
      line-height: 1;

      @media @mobile {
        font-size: 72px;
      }

      &--subtitle {
        display: block;
        font-size: 30px;
        line-height: 1;

        @media @mobile {
          font-size: 21px;
          letter-spacing: 0.013em;
        }
      }

      &--subtitle2 {
        display: block;
        font-size: 19px;
        line-height: 1.5;
        letter-spacing: normal;

        @media @mobile {
          font-size: 16px;
        }
      }
    }

    &__content {

    }
  }
}

.teleconsultation-urgence {
  padding: 30px;
  background: @yellow;

  &--text {
    text-align: center;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;

    @media @mobile {
      font-size: 12px;
    }
  }

  @media @mobile {
    padding: 20px;
  }

  .section {

  }

}

.telecons-tabs {
  &__subscribe {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
    flex-wrap: wrap;

    &--row {
      background: @white;
      border-radius: 10px;
      width: 32%;
      padding: 20px 20px 30px;
      text-align: center;
      border: 5px solid @white;

      @media @mobile {
        width: 100%;
        margin-bottom: 10px;
      }

      &.free {
        border: 5px solid rgba(0, 0, 0, 0.01);

        .telecons-tabs__subscribe--title {
          color: @blue;
        }
      }

      &.tlc {
        color: @white;
        background: @turquoise;

        .telecons-tabs__subscribe--desc {
          font-size: 22px;
          font-weight: 500;
        }
      }

      &.cabinet {
        color: @white;
        background: @dargrey;

      }
    }

    &--title {
      font-size: 25px;
      font-weight: 700;
      padding-bottom: 20px;
    }

    &--desc {
      line-height: 1.5;
      font-size: 16px;
      font-weight: 400;
      min-height: 60px;
      padding-bottom: 20px;

      @media @mobile {
        min-height: initial;
      }
    }
  }

  &__nav-wrapper {
    min-height: 79px;

    @media @mobile {
      min-height: 69px;
    }
  }

  &__nav {
    padding-top: 10px;
    padding-bottom: 50px;
    transition: all linear 150ms;

    @media @mobile {
      padding-bottom: 40px;
    }

  }

  &__title {
    font-size: 24px;
    color: @blue;
    padding-bottom: 20px;

    @media @mobile {
      font-size: 20px;
      font-weight: 500;
    }
  }

  &__tarif {
    @media @mobile {
      padding-bottom: 20px;
    }

    &--value {
      color: @green;
      padding-bottom: 10px;

      @media @mobile {
        padding-bottom: 0;
      }
    }
  }

  &__text {
    padding-top: 10px;

    .header-right__content-wrapper & {
      padding-left: 20px;
      padding-right: 20px;

      @media @mobile {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .simple_link {
      text-decoration: underline;
      margin: 10px;
      font-size: 14px;
    }
  }

  &__bottom-text {
    font-weight: 400;
    font-size: 14px;
    text-align: left;
  }

  &__tab {
    display: block;
    background: @white;
    border-radius: 10px;
    padding: 40px;
    text-align: center;

    @media @mobile {
      padding: 30px 20px 20px;
    }

    .video-header {

      max-width: 100%;

      .video-header__inner {
        border: 1px solid rgba(0, 0, 0, 0.05);
        margin-bottom: 0;
      }

      @media @mobile {
        margin-top: 20px;
      }
    }
  }

  &__step {
    &--text {
      width: 50%;

      @media @mobile {
        width: 100%;
        padding-bottom: 20px;
      }

      .header-right__content-wrapper & {
        width: 100%;
        padding-bottom: 20px;
      }
    }

    &--desc {
      font-size: 24px;
      line-height: 1;
      font-weight: 600;

      @media @mobile {
        font-size: 22px;
      }

      .header-right__content-wrapper & {
        font-size: 22px;
      }

      small {
        font-weight: 400;
        font-size: 14px;

        @media @mobile {
          margin-top: 10px;
        }
      }
    }

    &--visual {
      border: 0;
      overflow: visible;

    }

    &.reverse {
      .telecons-tabs__step--text {
        text-align: right;

        @media @mobile {
          text-align: left;
          padding-bottom: 20px;
        }

        .header-right__content-wrapper & {
          text-align: left;
          padding-bottom: 20px;
        }
      }

      .tablet-wrapper {
        &__step {

          &--visual {
            .tablet-wrapper {
              float: left;

              @media @mobile {
                float: left;
              }

              .header-right__content-wrapper & {
                float: left;
              }
            }
          }
        }
      }
    }
  }
}

.telecons-tabs__nav.fixed {
  position: fixed;
  left: 0;
  width: 100%;
  background: @white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 20px;
  z-index: 20;
}

.teleconsultation-faq {
  .section {
    &__title {
      font-size: 24px;
      padding: 0 20px 40px;
      text-align: center;
      margin: 0 auto;
      max-width: 530px;
      font-weight: 600;
      line-height: 1.3;

      @media @mobile {
        font-size: 20px;
      }
    }

    &__content {
      padding: 30px 20px 10px;
      margin-top: 40px;
      border-radius: 10px;
      background: @white;

      @media @tablet {
        border-radius: 0;
      }
    }
  }
}

.teleconsultation-infos {
  .section {
    &__title {
      font-size: 24px;
      padding: 0 20px 40px;
      text-align: center;
      margin: 0 auto;
      font-weight: 600;
      line-height: 1.3;

      @media @mobile {
        font-size: 20px;
      }
    }

    &__content {
      padding: 30px 0 10px;
      margin-top: 40px;
      border-radius: 10px;
      /*background: @white;*/
      width: 100%;

      .simple_link {
        font-size: 16px;
        font-weight: 500;
      }

      @media @tablet {
        border-radius: 0;
      }
    }

    &__desc {
      font-size: 14px;
      line-height: 1.5;
    }
  }
}

.teleconsultation-keywords {
  .section {
    &__title {
      font-size: 24px;
      padding: 0 0 20px 0;
      margin: 0;
      line-height: 1.5;

      @media @mobile {
        font-size: 16px;
      }
    }

    &__content {
      padding: 30px 20px 10px;
      margin-top: 40px;
      border-radius: 10px;
      width: 100%;

      @media @tablet {
        border-radius: 0;
      }
    }

    &__desc {
      font-size: 14px;
      text-align: left;
    }
  }
}

.common-faq {
  margin: 0 -20px;
}

.tablet-wrapper {
  width: 400px;
  max-width: 100%;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  float: right;

  &__mask {
    position: relative;
    z-index: 2;
    display: block;
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  &__img {
    top: 50%;
    left: 6%;
    position: absolute;
    z-index: 1;
    display: block;
    width: 88%;
    height: auto;
    transform: translate(0, -50%);
    border-radius: 10px;
  }
}

.smartphone-wrapper {
  position: relative;
  height: 400px;
  width: 194px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 24px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);

  &--mask {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    border-radius: 24px;

    .half & {
      height: auto;
      border-radius: 24px 24px 0 0;
    }
  }

  &--img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: auto;
    z-index: 1;
    border-radius: 24px;

    .half & {
      height: auto;
      border-radius: 24px 24px 0 0;
    }
  }

  &--sticker {
    position: absolute;
    top: 14%;
    right: 6%;
    display: block;
    width: 25%;
    height: auto;
    z-index: 3;
    border: 1px solid rgba(0, 0, 0, 0.02);
  }

  &.half {
    height: 200px;
    border-radius: 24px 24px 0 0;
  }
}

.popin-notif-spe {

  .popin-med__wrapper {
    padding-top: 60px;
  }

  .return-url {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 1;
    font-size: 14px;
    line-height: 1;
    padding-left: 20px;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 5px;
      width: 10px;
      height: 10px;
      border-top: 2px solid @green;
      border-left: 2px solid @green;
      transform: translate(0, -50%) rotate(-45deg);
    }
  }

  .popin-med__title {
    font-size: 20px;
    line-height: 1.3;
    text-transform: none;
    margin: 0 auto;

    @media @mobile {
      font-size: 16px;
    }

    .spe {
      color: @blue;
      vertical-align: initial;
    }
  }

  .popin-med__subtitle {
    font-size: 16px;
    line-height: 1.5;
    max-width: 470px;
    margin: 0 auto;

    @media @mobile {
      font-size: 14px;
    }
  }

  .popin-med__cta-wrapper {
    padding: 0;

    .blue-cta {
      min-width: 100px;
    }
  }
}

.praticien-list {
  max-width: 980px;
  margin: 0 auto;

  .section__title {
    font-size: 24px;
    padding: 30px 20px;
    text-align: center;
    margin: 0 auto;
    font-weight: 600;
    line-height: 1.3;
    color: @black;

    @media @mobile {
      font-size: 20px;
    }
  }

  &__list {
    list-style: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
    background: @white;
    border-radius: 10px;
    margin: 10px;
    padding: 20px 10px;
    width: calc(~"50% - 20px");

    @media @tablet {
      width: 100%;
    }
  }

  &__name {
    font-size: 16px;
  }

  &__spe {
    font-weight: 300;
    font-size: 14px;
  }

  &__img {
    border-radius: 100px;
    margin: 10px;
    overflow: hidden;
    height: 80px;
    width: 80px;
    @media @mobile {
      width: 50px;
      height: 50px;
    }

    img {
      height: 100%;
      width: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &__desc {
    margin: 0 0 10px 0;
  }
}

.teleconsultation-lemedecin {
  max-width: 1024px;
  margin: 0 auto;
  padding-bottom: 40px;

  &__disclaimer {
    display: flex;
    align-items: center;
    padding: 30px 20px 0 20px;
    max-width: 650px;
    margin: 0 auto;

    &--logo {
      img {
        display: block;
        width: 50px;
        height: auto;

        @media @mobile {
          width: 40px;
        }
      }
    }

    &--text {
      line-height: 1.5;
      padding: 20px;
      font-size: 16px;

      @media @mobile {
        font-size: 14px;
        padding: 15px;
      }
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    line-height: 1.3;
    padding: 0 0 20px 0;
    margin: 0;

    @media @tablet {
      padding-top: 20px;
    }
  }

  &__desc {
    font-size: 14px;
    line-height: 1.3;
    padding-bottom: 30px;
    font-weight: 400;

    strong {
      font-weight: 500;
    }
  }

  &__list {
    padding: 10px 0;
    margin: 0;
    font-size: 14px;
    line-height: 1.3;
    font-weight: 400;

    &--item {
      display: flex;
      padding-bottom: 30px;
      align-items: center;
    }

    &--number {
      font-size: 16px;
      font-weight: 500;
      min-width: 30px;
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      color: @white;
      background: @blue;
      border-radius: 100px;
      margin-right: 20px;
    }
  }

  &__cta {
    text-align: right;

    .simple_link {
      font-size: 16px;
    }
  }
}

.telecons-faq {
  padding: 1px 0;

  &.others-visible {
    .telecons-faq__others {
      height: auto;
      visibility: visible;
      opacity: 1;
      overflow: visible;
    }

    .telecons-faq__showmore {
      display: none;
    }

    .telecons-faq__showless {
      display: inline-block;
    }
  }

  &__others {
    transition: all 150ms linear;
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  &__wrapper {
    margin: -1px -20px 0;
    border-top: 1px solid @lightgrey;
    border-bottom: 1px solid @lightgrey;
    padding: 13px 0 13px 20px;

    @media @mobile {
      padding: 15px 0 30px 20px;
    }

    &.active {
      .telecons-faq__answer {
        visibility: visible;
        height: auto;
        opacity: 1;
        padding-top: 20px;
      }

      .telecons-faq__question {
        &:after {
          transform: translate(0, -50%) rotate(135deg);
        }
      }
    }
  }

  &__question {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    position: relative;
    cursor: pointer;
    padding: 0 40px 0 0;

    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      transition: transform 150ms linear;
      height: 10px;
      width: 10px;
      border-top: 2px solid @green;
      border-right: 2px solid @green;
      transform: translate(0, -50%) rotate(45deg);
    }
  }

  &__answer {
    visibility: hidden;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: opacity, height, padding-top 150ms linear;
    padding: 0 20px 0 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.5;

    a {
      font-weight: 500;
    }
  }

  &__showmore, &__showless {
    margin: 13px 0;
    font-size: 12px;
    text-decoration: underline;
  }

  &__showless {
    display: none;
  }
}

.telecons-list {
  opacity: 0;
  transform: translate(100%, 0);
  visibility: hidden;
  pointer-events: none;
  transition: all 300ms @easeOut;
  height: 0;
  overflow: hidden;

  &__infos-link {
    text-transform: none;
    font-size: 12px;
    padding-top: 20px;
    text-align: center;

    a {
      vertical-align: initial;
    }
  }

  .anchor_link {
    position: relative;
    padding-right: 20px;
    font-size: 14px;
    display: none;

    @media @verysmallmobile {
      display: inline-block;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 42%;
      width: 7px;
      height: 7px;
      border-top: 1px solid @green;
      border-right: 1px solid @green;
      transform: translate(0, -50%) rotate(135deg);
    }
  }

  &__title {
    text-align: left;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    padding: 0 18px 20px;

    @media @verysmallmobile {
      font-size: 12px;
    }

    small {
      font-weight: 400;
      text-transform: none;
    }

    &.with-border {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 20px;
    }

    &.bis {
      padding: 10px 0;
      max-width: 400px;
      margin: 0 auto;
    }

    .icon {
      color: @blue;
      display: inline-block;
      font-size: 16px;
      width: 20px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      max-width: 540px;

      &.smaller {
        display: block;
        margin-top: 10px;
        font-size: 12px;
        font-weight: 200;
        text-transform: none;
      }

    }
  }

  &__desc {
    font-size: 12px;
    font-weight: 400;
    padding: 0 18px 10px;
  }

  #telecons-list-show {

    &.fullwidth {
      margin: 0 -20px;
    }

    .preview-list {
      position: relative;

      @media @mobile {
        margin: 0 -20px;
        .date-wrapper {
          /*width: 100%;
          padding: 8px 0 7px;*/

          .day-name, .date-formated {
            /*display: inline-block;
            margin: 0 2px;*/
          }
        }

        .date-list-wrapper {
          /*width: 100%;*/
        }
      }

      .date-list-item {
        .date-list-wrapper {
          padding-right: 30px;

          .date-item {
            background: none;
            color: @black;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 100px;

            &.has-majoration {
              background: rgba(0, 37, 255, 0.07);
            }

            &.selected {
              background: @blue;
              color: @white;
              border: 1px solid @darkblue;
            }

            @media @mobile {
              margin-right: 4px;
            }
          }
        }
      }
    }
  }

  &.active {
    opacity: 1;
    transform: translate(0, 0);
    visibility: visible;
    pointer-events: all;
    height: auto;
    overflow: visible;
    padding-bottom: 20px;
  }

  &__check {
    padding: 20px 0;

    .link {
      font-size: 0;
      font-weight: 500;
      background: none;
      line-height: 1.5;

      small {
        font-size: 12px;
        line-height: 1;
        font-weight: 400;
      }

      span, i {
        vertical-align: middle;
        font-size: 16px;
      }

      .icon {
        font-size: 20px;
        color: @green;
      }
    }
  }

  .preview-list {
    margin: 0;
    padding-bottom: 50px;

    @media @mobile {
      padding-bottom: 100px;
    }

    .date-list-item {
      display: block;
    }
  }
}

.telecons-select {
  display: none;
  position: relative;

  &__ud-logo {
    display: none;

    .is_prepaid & {
      display: block;
    }

    @media @mobile {
      margin: -20px 0 10px;
    }

    img {
      width: 150px;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }

  &__alert {
    border: 2px solid @green;
    padding: 15px;
    border-radius: 4px;
    max-width: 900px;
    margin: 0 auto 20px;

    strong {
      font-weight: 500;
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 10px 20px 0;

    @media @mobile {
      padding: 10px 0 0 0;
    }

    &--title {
      display: block;
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.3;
    }

    li {
      margin-bottom: 15px;
      position: relative;
      padding-left: 30px;
      line-height: 1.5;
      font-size: 13px;

      &:before {
        position: absolute;
        content: '';
        width: 15px;
        height: 9px;
        border-left: 3px solid @green;
        border-bottom: 3px solid @green;
        left: 0;
        top: 3px;
        transform: rotate(-45deg);
      }
    }
  }

  &.active {
    display: block;
  }

  &__inner {
    display: flex;
    align-items: flex-start;

    @media @mobile {
      flex-wrap: wrap;
    }
  }

  &__praticien {
    width: 50%;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;

    &.alone {
      width: 100%;
      border: 0;
    }

    @media @mobile {
      width: 100%;
      border: 0;
      padding: 0;
    }
  }

  &__infos {
    width: 50%;
    padding: 0 0 0 40px;

    @media @mobile {
      width: 100%;
      padding: 20px 0;
    }

    &--inner {
      font-size: 16px;
      line-height: 1.3;
      border-radius: 10px;
      font-weight: 400;
      padding: 20px;
      background: rgba(0, 0, 0, 0.04);
      border: 1px solid rgba(0, 0, 0, .05);

      .icon {
        color: @blue;
        font-size: 40px;
        display: block;
        padding-bottom: 10px;

        @media @mobile {
          display: none;
        }
      }

      @media @mobile {
        margin: 0 -20px;
        border-radius: 0;
      }
    }
  }

  &__table {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    padding-bottom: 30px;

    @media @mobile {
      padding-bottom: 10px;
    }

    &--row {
      display: flex;
      align-items: center;

      &:last-child {
        .telecons-select__table--details {
          border: 0;
        }
      }
    }

    &--picto {
      font-size: 20px;
      color: @green;
      padding: 10px 20px 10px 0;
    }

    &--details {
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5;
      width: 100%;
      padding: 10px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      .link-cta {
        font-size: 12px;
        font-weight: 500;
        color: @blue;
        text-decoration: underline;
        background: none;
      }

      small {
        opacity: 0.9;
      }
    }

    &--datetime {
      width: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;

      .date, .time {
        border-radius: 100px;
        font-size: 16px;
        padding: 5px 15px;
        margin: 0 10px;
        border: 1px solid @blue;
        font-weight: 400;
        display: inline-block;
        vertical-align: middle;
      }

      .date {
        background: @blue;
        color: @white;
      }

      .time {
        background: @white;
        color: @blue;
      }
    }
  }

  &__pay {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 10px;

    @media @mobile {
      align-items: center;
    }

    &--label {
      font-size: 20px;
      font-weight: 400;
      margin-right: 10px;
      line-height: 25px;
      white-space: nowrap;
    }

    &--price {
      font-size: 25px;
      font-weight: 500;
      line-height: 25px;

      small {
        font-size: 14px;
        font-weight: 400;
      }

      .verysmall {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 500;
    padding-bottom: 20px;
    line-height: 1.3;

    .time, .date {
      font-weight: 700;
      color: @blue;
    }
  }

  &__subtitle {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    padding-bottom: 10px;
  }

  &__profil {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__pict {
    width: 60px;
    height: 60px;
    position: relative;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 100px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__details {
    font-size: 16px;
  }

  &__name, &__job-title {
    font-weight: 500;
    vertical-align: initial;
  }

  &__spe {
    font-size: 12px;
    vertical-align: initial;
  }

  &__address, &__rpps {
    font-size: 12px;
    padding-top: 5px;
    line-height: 1.5;
  }

  &__rpps {
    font-weight: 500;
  }

  &__cta {
    padding: 10px 0 20px;
    text-align: center;

    /*@media @mobile {
      padding-bottom: 40px;
    }*/

    .blue-cta {
      margin: 5px;
    }
  }

  &__not-remb {
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    line-height: 1.3;
    color: @blue;
  }

  &__connect {
    font-weight: 400;
    font-size: 14px;
    color: @black;
    padding: 10px 0 20px;

    .link-cta {
      color: @blue;
      text-decoration: underline;
      cursor: pointer;
      display: inline;
      font-weight: 500;
      vertical-align: initial;
    }
  }
}

.telecons-confirm {
  border-radius: 4px;
  margin-bottom: 30px;
  display: none;
  overflow: hidden;

  #telecons-firstinfos-form {
    padding-top: 40px;
  }

  &__certif {

    &--list {
      list-style: none;
      margin: 0 auto;
      max-width: 600px;
      padding: 50px 0 20px;

      @media @mobile {
        padding-top: 40px;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: left;
        padding-bottom: 20px;

        .icon {
          color: @blue;
          font-size: 30px;
        }

        span {
          display: block;
          padding: 0 10px;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.3;
          text-align: left;

          @media @mobile {
            font-size: 14px;
          }
        }
      }
    }

    &--title {
      font-size: 22px;
      line-height: 1.3;
      text-align: center;
      font-weight: 700;
      padding-bottom: 20px;

      @media @mobile {
        font-size: 20px;
      }

      .subline {
        color: @blue;
      }
    }

    &--pict {
      img {
        display: block;
        margin: 0 auto;
        width: 130px;
        height: auto;
      }
    }
  }

  &__inner {
    display: flex;
    padding: 10px 0;
    align-items: flex-start;
    flex-wrap: wrap;
    overflow-x: hidden;
    position: relative;
    min-height: 250px;
  }

  &__question {
    text-align: center;
    opacity: 0;
    width: 100%;
    transform: translate(100%, 0);
    visibility: hidden;
    pointer-events: none;
    transition: all 300ms @easeOut;
    height: 0;
    overflow: hidden;

    &.active {
      opacity: 1;
      transform: translate(0, 0);
      visibility: visible;
      pointer-events: all;
      height: auto;
      overflow: visible;
    }

    &--text {
      font-weight: 500;
      padding-bottom: 20px;
      font-size: 18px;
      text-align: left;

      @media @mobile {
        font-size: 16px;
      }
    }

    &--text2 {
      padding-bottom: 40px;
      font-size: 14px;
      text-align: left;

      .simple_link {
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
      }

      @media @mobile {
        padding-bottom: 30px;
      }
    }

    &--cta {
      .blue-cta {
        margin: 5px;
        min-width: 100px;
      }
    }
  }

  &__profil {
    display: flex;
    align-items: flex-start;
    justify-content: center;

  }

  &__pict {
    width: 60px;
    height: 60px;
    position: relative;
    margin-right: 20px;
    overflow: hidden;
    border-radius: 100px;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__details {
    font-size: 16px;
  }

  &__descs {
    width: auto;
    margin: 0 auto;
    padding: 20px;
    //box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    font-weight: 400;
  }

  &__name {
    font-weight: 500;
    font-size: 20px;
  }

  &__spe {
    font-size: 14px;
    padding-bottom: 5px;
  }

  &__infos-more {
    font-size: 14px;
  }

  &__address {
    font-size: 12px;
    padding: 5px 0 10px;
    line-height: 1.5;
  }

  .date, .time {
    display: inline-block;
    padding: 5px 15px;
    border-radius: 100px;
    margin: 20px 5px;
    font-weight: 500;
    font-size: 16px;
  }

  .date {
    background: @green;
    color: @white;
  }

  .time {
    color: @black;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  &.active {
    display: block;
  }

  &__title {
    font-size: 22px;
    line-height: 1.5;
    font-weight: 400;
    text-align: center;
  }

  &__infos {
    text-align: center;
    width: 100%;
    padding: 40px 0 20px;

    @media @mobile {
      width: 100%;
      padding: 40px 0 20px;
    }

    &--title {
      font-weight: 500;
      font-size: 20px;
      padding-bottom: 10px;
    }

    &--desc {
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      line-height: 1.5;
    }
  }
}

.popin-med__super-wrapper {
  &.telecons {

    .popin-med__cta-wrapper {
      padding: 0;
      margin-top: 40px;
      position: relative;
    }

    .popin-med__title {
      padding-bottom: 10px;

      @media @mobile {
        font-size: 12px;
      }
    }

    .popin-med__cta-wrapper {
      .blue-cta {
        margin: 10px 5px;
      }

      .close-btn {
        .icon {
          margin: 0;
          font-size: 25px;
        }
      }
    }

    .popin-med__wrapper {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
      border-radius: 0;
      padding: 15px;

      @media @tablet {
        top: 0 !important;
      }
    }
  }

  &.popin-validate-tlc {

    .popin-med__subtitle {
      font-weight: 500;
      padding-bottom: 0;
    }

    hr {
      max-width: 300px;
      margin: 0 auto;
    }

    .cta-wrapper {
      text-align: center;
      margin: 20px auto 10px;
      width: 300px;
      max-width: 100%;

      .blue-cta {
        width: 100%;
        padding: 0 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &.cmu {
        display: flex;
        align-items: center;

        .blue-cta {
          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    .cta-label {
      font-size: 14px;
      width: 300px;
      max-width: 100%;
      margin: 0 auto;
      padding-top: 20px;
      padding-bottom: 10px;
      text-align: left;
    }

    .cta-desc {
      font-size: 12px;
      font-weight: 400;
      width: 300px;
      max-width: 100%;
      padding-bottom: 10px;
      text-align: left;
      margin: 0 auto;

      &__tarif {
        display: flex;
        align-items: center;
        padding-top: 20px;
      }
    }

    .popin-med__wrapper {

    }

    &.popin-visible {
      z-index: 300000;
    }
  }
}

.fixed-block-back-btn {
  height: 25px;
  position: relative;
  width: 50px;
  display: block;
  cursor: pointer;
  margin: -20px auto 20px;
  background: @antracite;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  border-radius: 0 0 4px 4px;

  &:after {
    position: absolute;
    content: '';
    border-top: 2px solid @white;
    border-right: 2px solid @white;
    width: 15px;
    height: 15px;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &.disabled {
    pointer-events: none;
    background: rgba(0, 0, 0, 0.15);
    display: none;

    @media @tablet {
      display: none;
    }

    &:after {
      top: 30%;
      transform: translate(-50%, -50%) rotate(135deg);
    }
  }

  @media @tablet {
    position: absolute;
    top: 10px;
    right: 30px;
    margin: 0;
    background: none;
    box-shadow: none;
    width: 30px;
    height: 30px;
    z-index: 10;

    &:after, &:before {
      position: absolute;
      content: '';
      border: 0;
      background: @antracite;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 4px;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }

  @media @mobile {
    right: 10px;
  }
}

.slide-tlc-alert {

  display: none;

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

  }

  &.column {
    display: block;
    width: calc(~"100%/2 - 10px");
    background: @white;
    border-radius: 10px;
    padding: 40px 20px;

    @media @mobile {
      width: 100%;
      margin-bottom: 20px;
      border-radius: 0;
    }

    &:first-child {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      padding: 20px;

      @media @mobile {
        display: block;
      }

      .cvit {
        margin: 0;
        width: 150px;

        img {
          border-radius: 10px;
          width: 100%;
          max-width: 100%;
        }

        @media @mobile {
          width: 100%;
          margin-bottom: 30px;
          display: block;
          margin-top: 20px;

          img {
            max-width: 70%;
          }
        }
      }

      .slide-tlc-alert__title {
        margin: 0;
        font-size: 20px;
        text-align: left;
        width: calc(~"100% - 150px");
        padding-left: 30px;

        @media @mobile {
          width: 100%;
          margin-bottom: 20px;
          text-align: center;
          padding-left: 0;
        }
      }
    }
  }

  &.selected {
    display: block;
  }

  &__check {
    position: relative;
    height: 50px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media @mobile {
      position: fixed;
      left: 0;
      top: 0;
      width: 100%;
      padding: 25px 0;
      height: 40px;
      background: rgba(255, 255, 255, 0.9);
    }

    &--blank {
      position: relative;
      height: 0;
      display: none;

      @media @mobile {
        display: block;
      }
    }

    &--picto {
      height: 50px;
      width: 50px;
      position: relative;
      margin: 0 20px;

      @media @mobile {
        height: 30px;
        width: 30px;
      }

      &.off {
        &:after {
          opacity: 0;
        }

        &:before {
          transform: translate(-50%, -50%) scale(0.5);
        }

      }

      &.checked {

        &:before {
          animation: 0.8s linear 0s forwards anim-cheched-bg;
        }

        &:after {
          border-left: 3px solid @white;
          border-bottom: 3px solid @white;
          animation: 0.8s linear 0s forwards anim-cheched-picto;
        }
      }

      &:after, &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        display: block;
        transition: all 150ms linear;
      }

      &:before {
        height: 50px;
        width: 50px;
        border-radius: 100px;
        z-index: 1;
        background: @lightgrey;
        transform: translate(-50%, -50%);

        @media @mobile {
          height: 30px;
          width: 30px;
        }
      }

      &:after {
        z-index: 2;
        width: 20px;
        height: 10px;
        border-left: 3px solid @dargrey;
        border-bottom: 3px solid @dargrey;
        transform: translate(-50%, -80%) rotate(-45deg);
        transition: background-color, border-left-color, border-right-color linear 300ms;

        @media @mobile {
          width: 15px;
          height: 6px;
        }
      }
    }
  }

  &__title {
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
    text-align: left;
    padding: 0 50px;
    font-weight: 700;

    @media @mobile {
      padding: 20px 10px 0;
      font-size: 18px;
    }

    @media @verysmallmobile {
      font-size: 16px;
    }

    strong {
      color: @blue;
    }
  }

  &__aside {
    width: 200px;
    min-width: 200px;

    &.smaller {
      width: 170px;
      min-width: 170px;
      padding-right: 30px;

      @media @mobile {
        padding: 0 0 30px 0;
        width: 100%;
        min-width: 100%;

        img {
          height: 130px;
        }
      }
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    @media @mobile {
      width: 100%;
      min-width: 100%;

      img {
        height: 130px;
        width: auto;
        margin: 0 auto;
      }
    }
  }

  &__text-wrapper {
    display: flex;

    @media @mobile {
      flex-wrap: wrap;
    }

    &.smaller {
      padding: 30px 40px 20px;

      .slide-tlc-alert__text {
        padding-left: 20px;
      }

      @media @mobile {
        padding: 0;

        .slide-tlc-alert__text {
          padding: 10px 10px 0;
        }
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 1.5;
    padding-left: 50px;
    color: @dargrey;
    text-align: left;

    &.for-hp {
      padding: 40px 40px 10px;

      @media @mobile {
        padding: 40px;
      }
    }

    .popin-med__alert-list {
      color: @black;

      @media @mobile {
        ul {
          margin: 0;
        }
      }
    }

    @media @mobile {
      padding: 20px 0 0 0;
    }

    p {
      margin-bottom: 30px;

      @media @mobile {
        margin-bottom: 20px;
      }
    }

    strong {
      font-size: 16px;
      font-weight: 700;
      color: @black;
      line-height: 1.3;
      margin-bottom: 8px;
      display: block;

    }
  }

  &__source {
    font-size: 12px;
    opacity: 0.6;
    margin-top: 10px;
    text-align: left;
    position: absolute;
    bottom: 70px;
    left: 80px;

    strong {
      font-weight: 500;
      font-size: 12px;
      display: inline;
    }

    @media @tablet {
      position: static;
      padding: 20px 40px;
    }

    @media @mobile {
      padding: 10px;
    }
  }

  &__img {
    margin: 40px 0 50px;

    img {
      display: block;
      margin: 0 auto;
      max-width: 70%;
    }

    &.cvit {
      margin-bottom: 50px;

      img {
        border-radius: 20px;
        max-width: 70%;
      }
    }
  }

  &__cta {
    padding-top: 30px;

    @media @mobile {
      padding-top: 0 !important;
      margin-top: 0;
    }

    .input-wrapper.docked-right {
      .blue-cta {
        margin-left: 10px;
        min-width: 120px;
      }
    }
  }

  &__samu {
    background: @lightgrey;
    color: @black;
    padding: 8px 10px;
    border-radius: 5px;
    width: 100%;
    font-size: 12px;
    line-height: 1.3;
    font-weight: 500;
    margin-top: 30px;

    @media @mobile {
      margin: 10px 0 20px;
    }
  }
}

@keyframes anim-cheched-bg {
  0% {
    transform: translate(-50%, -50%) scale(1);
    background: @lightgrey;
  }

  25% {
    transform: translate(-50%, -50%) scale(1.5);
    background: @green;
  }

  50% {
    transform: translate(-50%, -50%) scale(1);
    background: @green;
  }

  90% {
    transform: translate(-50%, -50%) scale(1);
    background: @green;
  }

  100% {
    transform: translate(-50%, -50%) scale(0.5);
    background: @green;
  }
}

@keyframes anim-cheched-picto {
  0% {
    transform: translate(-50%, -80%) rotate(-45deg) scale(1);
    border-left: 3px solid @dargrey;
    border-bottom: 3px solid @dargrey;
  }

  25% {
    transform: translate(-50%, -80%) rotate(-45deg) scale(1.5);
    border-left: 3px solid @white;
    border-bottom: 3px solid @white;
  }

  50% {
    transform: translate(-50%, -80%) rotate(-45deg) scale(1);
    border-left: 3px solid @white;
    border-bottom: 3px solid @white;
  }

  90% {
    transform: translate(-50%, -80%) rotate(-45deg) scale(1);
    border-left: 3px solid @white;
    border-bottom: 3px solid @white;
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -80%) rotate(-45deg) scale(1);
    border-left: 3px solid @white;
    border-bottom: 3px solid @white;
    opacity: 0;
  }
}

.rdv-infos {
  font-size: 16px;
  padding: 0 10px 10px;
  text-align: left;
  margin: 0 0 20px;

  @media @mobile {
    font-size: 14px;
  }

  &__confirm {
    text-align: center;
    font-size: 16px;

    &--datetime {
      font-weight: 700;
      font-size: 30px;
      color: @green;

      @media @mobile {
        font-size: 20px;
      }
    }
    &--title {
      font-size: 18px;
      margin-bottom: 30px;

      @media @mobile {
        font-size: 20px;
      }
    }

    &--with {
      font-size: 16px;
      margin: 25px 0 20px;
      line-height: 1.5;
    }

    &--pratinfos {
      margin: 10px 0 20px;
    }
  }

  span {
    vertical-align: initial;
  }

  &__text {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1.5;
  }

  &__row {
    display: flex;
    align-items: center;
    font-weight: 400;

    .medecin-name, .rdv-date, .rdv-time, strong {
      font-weight: 500;
    }

    .simple_link {
      font-size: 12px;
      text-decoration: underline;
      display: inline-block;
      width: 100%;
      text-align: left;
    }

    .icon {
      margin-right: 10px;
      margin-bottom: 2px;
      font-size: 18px;
      color: @blue;
    }
  }
}