.regul-rdv-confirm {
  .popin-med__title {
    color: @green;
  }

  &__details {
    font-size: 16px;
    line-height: 1.5;

    &--title {
      font-size: 16px;
      font-weight: 700;
      color: @antracite;
    }
  }
}

.regul-rdv {
  .popin-med__center-content {
    text-align: left;
  }

  &__note {
    margin: 10px 0 0 10px;
  }

  select {
    height: 50px;
  }

  &__ast {
    color: @red;
    font-size: 20px;
    line-height: 0;
  }

  &__details {
    padding: 20px;
    line-height: 1.5;
    background: @yellowlt2;
    border-radius: 10px;
    font-weight: 500;
    margin-bottom: 40px;

    &--pratname {
      font-size: 18px;
      font-weight: 700;
    }

    &--spe {
      font-size: 12px;
      font-weight: 400;
      text-transform: capitalize;
    }

    &--address {
      margin-top: 10px;
      font-size: 14px;
    }

    &--datetime {
      display: flex;
      align-items: center;
      font-weight: 700;
      justify-content: center;
      font-size: 25px;
      margin-top: 30px;

      @media @mobile {
        font-size: 22px;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }

  .popin-med__title {
    font-size: 20px;
    font-weight: 700;
  }
  &__form{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    &.select-type {
      margin: 0 0 20px;
      padding: 15px 20px 0;
      border-radius: 10px;
      border: 1px solid @s_blue_darker;
      background: @s_blue_lt;

      .input-wrapper select {
        font-weight: 500;
        font-size: 14px;
      }
    }

    @media @mobile {
      margin: 0;
    }
  }

  .subtitle {
    font-size: 18px;
    font-weight: 700;
    margin: 20px 0 40px;
    color: @antracite;
    display: flex;

    .icon {
      margin-right: 10px;
      font-size: 20px;
      opacity: 0.5;
    }
  }
}

.popin-agenda {

  .input-wrapper {

    &.no-remplacant {

      .inline-block {
        display: none;

        &.mentions {
          display: block;
        }
      }

      input[type="radio"] {
        display: none;
      }
    }

    label, span {
      vertical-align: middle;
    }

    label {
      display: block;

      &.inline-block {
        display: inline-block;
        width: auto;
      }

      &.mentions {
        white-space: normal;
        opacity: 0.6;
        font-size: 12px;
        display: none;
      }
    }

    input[type="radio"] {
      vertical-align: middle;
      margin-right: 10px;
      margin-left: 20px;
    }

    &.dayweek-wrapper {
      label {
        position: relative;
        display: inline-block;
      }

      input[type="checkbox"] {
        opacity: 0;
        position: absolute;
        left: 0;

        &:checked + .day-label {
          background: @blue;
          color: @white;
        }
      }

      .day-label {
        transition: all linear 150ms;
        border: 1px solid rgba(35, 41, 57, .3);
        width: 30px;
        height: 30px;
        padding: 0;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        margin: 2px;
        cursor: pointer;
      }
    }
  }

  &.has-date-selected {
    #agenda-rdv-list, .subtitle.date {
      display: none;
    }
  }

  .preview-list {

    .date-wrapper {
      text-align: left;
      width: 100%;
      padding-bottom: 10px;
      cursor: pointer;

      .status {
        font-size: 16px;
        font-weight: 700;
        display: inline-block;
        vertical-align: middle;
        padding-left: 30px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 99px;
          overflow: hidden;
          left: 6px;
          top: 50%;
          transform: translate(0, -50%);
        }

        &.green {
          &:before {
            background: @green;
          }
        }

        &.orange {
          &:before {
            background: @orange;
          }
        }

        &.red {
          &:before {
            background: @red;
          }
        }
      }

      &:after {
        display: none;
      }

      .day-name, .date-formated {
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
        min-width: 50px;
      }
    }

    .date-list-wrapper {
      width: 100%;
      padding-left: 0;
      transition: all linear 150ms;
      visibility: hidden;
      opacity: 0;
      overflow: hidden;
      height: 0;

      &.active {
        visibility: visible;
        opacity: 1;
        height: auto;
      }

      .date-item {
        background: @white;
        border: 1px solid @blue;
        color: @blue;

        &.selected {
          background: @blue;
          color: @white;
        }

        &.has-rdv {
          pointer-events: none;
          cursor: not-allowed;
          color: @black;
          background: @white;
          border-color: @black;
          opacity: 0.2;
        }

        @media @desktoponly {

          &:hover {
            background: @blue;
            color: @white;
            opacity: 0.5;
          }
        }
      }
    }
  }

  .step__next, .step__prev {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: none;
    width: 30px;
    height: 30px;
    z-index: 200;

    &:after {
      content: '';
      position: absolute;
      top: 8px;
      width: 15px;
      height: 15px;
      border-top: 2px solid @blue;
      border-right: 2px solid @blue;
    }

    &.disabled {
      opacity: 0.1;
      pointer-events: none;
    }
  }

  .step__next {
    right: 10px;

    &:after {
      transform: rotate(45deg);
      right: 10px;
    }
  }

  .step__prev {
    left: 20%;

    &:after {
      transform: rotate(-135deg);
      left: 10px;
    }
  }

  .step-wrapper {
    transition: opacity, height 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
    transform: translate(180%, 0);
    visibility: hidden;
    position: fixed;
    width: 100%;
    opacity: 0;
    height: 0;
    overflow: hidden;

    &.visible {
      transform: translate(0, 0);
      visibility: visible;
      position: relative;
      width: auto;
      opacity: 1;
      height: auto;
      display: none;
    }

    &.validated {
      transform: translate(-180%, 0);
    }
  }

  .submit, .validate {

    @media @mobile {
      width: 100%;
    }

    &.visible {
      display: inline-block !important;
    }

    &.hidden {
      display: none;
    }
  }

  .popin-med__center-content {
    text-align: left;
    min-height: 200px;

    .hidden {
      &.selected {
        display: block !important;
      }
    }
  }

  .popin-med__title {
    .address {
      display: inline-block;
      vertical-align: middle;
      background: @blue;
      color: @white;
      border-radius: 4px;
      padding: 2px 7px;
      margin: 0 8px;
      font-size: 14px;
    }
  }

  #consult-type input {
    max-width: 100% !important;
  }

  select {
    display: inline-block;
    vertical-align: middle;

    &[multiple] {
      background: none;
      padding: 0;
      max-height: 80px;

      option {
        padding: 5px 10px;
      }
    }

    &.full {
      width: 100%;
      max-width: 100%;
    }

    @media @mobile {
      width: 100%;
      max-width: 100%;
    }
  }

  input, div.selector {

    &.error {
      border-color: @red;
    }

    @media @mobile {
      margin: 0;
      max-width: 100% !important;
    }
  }

  @media @mobile {
    input[type="date"] {
      width: 90%;
      max-width: 90% !important;
      margin-bottom: 20px;
    }
  }

  .subtitle {
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    padding: 40px 0 20px;

    &.date {
      text-align: left;
      padding-top: 20px;
    }
  }

  div.selector {

    &.error {
      span {
        color: @red;
      }

    }
  }

  @media @mobile {
    [data-calendar="date"] .horairesWrapper .selector {
      max-width: 100% !important;
      margin: 0 0 20px 0;
    }
  }

  label {
    font-size: 14px;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    margin: 10px 0;
    text-transform: none;
    line-height: 1.3;

    &.full {
      width: 100%;
    }

    @media @mobile {
      br {
        display: none;
      }
    }
  }

  .input-wrapper.horairesrecurrenceWrapper {
    label {
      display: block;
      padding: 20px 0 10px;
    }

    div.selector {
      max-width: 100% !important;
    }
  }

  .optionsWrapper {
    text-align: left;

    label {
      margin: 0;
      padding: 0 !important;
    }

    .optionAgenda {
      display: inline-block;
      margin: 10px 20px;
      white-space: nowrap;

      input {
        margin: 0 10px 0 0;
      }

      label {
        width: 100%;
      }
    }
  }

  &.popin-visible .step-wrapper.visible {
    display: block;
  }
}

.popin-settings {

  .input-wrapper {
    input[type="text"], textarea {
      border-style: dashed;
    }
  }

  strong, b {
    font-weight: 500;
  }

  .popin-med__subtitle {
    padding-bottom: 10px;
    font-size: 14px;

    .icon {
      margin-right: 5px;
      color: @blue;
    }

    &.settings-email {
      display: inline-block;
      vertical-align: middle;
      width: 100%;
      text-align: center;

      @media @mobile {
        display: block;
        width: 100%;
        text-align: center;
      }
    }
  }

  .replace-list {
    padding-bottom: 20px;
    text-align: left;

    &__label {
      font-size: 12px;
      display: block;
      text-align: left;
      padding-bottom: 10px;
    }

    &__item {
      display: inline-block;
      position: relative;
      font-size: 14px;
      line-height: 1.3;
      padding: 5px 30px 5px 10px;
      color: @black;
      text-align: left;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin: 0 5px 5px 0;

      &:after {
        content: 'X';
        color: @white;
        background: @blue;
        font-size: 10px;
        width: 15px;
        text-align: center;
        height: 15px;
        line-height: 15px;
        border-radius: 100px;
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translate(0, -50%);
      }

    }
  }

  .medecin-email {
    width: auto;

    input {
      font-weight: 500;
      text-align: center;
      border-style: dashed;
    }
  }

  .input-wrapper {
    padding-left: 0;
    padding-right: 0;

    @media @mobile {
      input, textarea {
        font-size: 16px;
      }
    }
  }
}

.medecin-agenda {
  position: relative;

  @media @tabletonly {
    &[data-role="medecin-agenda-tab"] {
      white-space: nowrap;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      * {
        white-space: normal;
      }
    }
  }

  .blue-cta {
    &.small {
      font-size: 10px;
      line-height: 20px;
      height: 20px;
      padding: 0 10px;
      box-shadow: none;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;

      @media @mobilelandscape {
        padding: 0 5px;
        white-space: nowrap;

      }
    }
  }

  &__cta {
    .blue-cta {
      margin: 0 10px 10px 10px;
    }
  }

  &__selected-name {
    bottom: 30px;
    position: fixed;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, 0);
    background: @yellowpj;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    max-width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    color: @black;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    padding: 0 10px 0 30px;
    display: flex;
    justify-content: left;
    white-space: nowrap;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      width: 10px;
      height: 10px;
      border-top: 2px solid @black;
      border-left: 2px solid @black;
      transform: translate(0, -50%) rotate(-45deg);
    }

    .icon {
      margin-left: 20px;
      font-size: 20px;
    }

    .medecin-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 8px;
    }

    @media @mobilelandscape {
      left: 20px;
      transform: translate(0, 0);
    }

    @media @mobile {
      width: 100%;
      max-width: 100%;
      left: 0;
      border-radius: 0;
      bottom: 20px;
      transform: translate(0, 0);
    }
  }

  &__tabs, &__fiches {
    padding: 0;
    text-align: center;

    &.fixed {
      position: fixed;
      bottom: 30px;
      left: 50%;
      z-index: 100;
      transform: translate(-50%, 0);
      background: @blue;
      height: 30px;
      line-height: 30px;
      border-radius: 4px;
      max-width: 400px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);

      @media @mobilelandscape {
        left: 20px;
        transform: translate(0, 0);
      }

      @media @mobile {
        width: 100%;
        max-width: 100%;
        left: 0;
        border-radius: 0;
        bottom: 20px;
        transform: translate(0, 0);
      }

      .icon-office {
        display: inline-block;
        vertical-align: middle;
        width: 15px;
        font-size: 15px;
        color: @white;
      }

      select {
        vertical-align: middle;
        background: none;
        border: 0;
        -moz-appearance: none;
        -webkit-appearance: none;
        color: @white;
        font-size: 16px;
        padding: 0 40px 0 10px;
        font-weight: 500;
        z-index: 200;
        position: relative;
        width: calc(~"100% - 35px");

        option {
          color: @black;
        }

        ::-ms-expand {
          display: none;
        }
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: 17px;
        width: 8px;
        height: 8px;
        border-top: 1px solid @white;
        border-right: 1px solid @white;
        transform: translate(0, -50%) rotate(-45deg);
      }

      &.hidden {
        visibility: hidden;
        opacity: 0;
      }
    }
  }

  &__subtitle {
    text-align: center;
    padding: 0 0 20px !important;
  }

  &__nav, &__navmonth {
    position: relative;
    @media @tabletonly {
      text-align: center;
      margin-top: -30px;
      margin-bottom: 5px;

      .medecin-agenda__prev, .medecin-agenda__next, .medecin-agenda__goto, .medecin-agenda__prevmonth, .medecin-agenda__nextmonth {
        position: relative;
      }
    }
  }

  &__prev, &__next,
  &__prevmonth, &__nextmonth {
    position: absolute;
    top: 7px;
    background: none;
    width: 30px;
    height: 30px;
    z-index: 200;
    display: inline-block;
    vertical-align: middle;

    @media @mobile {
      position: fixed;
      top: 87px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 7px;
      width: 15px;
      height: 15px;
      border-top: 2px solid @blue;
      border-right: 2px solid @blue;
    }

    &.disabled {
      opacity: 0.1;
      pointer-events: none;
    }
  }

  &__month {
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    top: 7px;
    position: relative;
    padding: 0 10px;
    font-size: 16px;

    @media @mobile {
      position: fixed;
      left: 50%;
      transform: translate(-50%, 0);
      top: 87px;
      box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
      background: @white;
      border-radius: 100px;
      z-index: 10;
    }
  }

  &__goto {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 30px;
    line-height: 30px;
    top: 7px;
    margin: 0 10px;

    @media @mobile {
      position: fixed;
      z-index: 1000;
      background: @blue;
      left: 50%;
      transform: translate(-50%, 0);
      width: 40px;
      text-align: center;
      top: 79px;
      height: 40px;
      line-height: 40px;
      border-radius: 100px;
      box-shadow: 1px -2px 2px rgba(0, 0, 0, 0.3);
    }

    label {
      font-size: 20px;
      margin: 0;
      line-height: 1;
      vertical-align: middle;
      color: @blue;
      cursor: pointer;
      @media @mobile {
        color: @white;
        font-size: 19px;
        margin: 0 -2px -2px 0;
      }

    }

    input[type="text"] {
      margin: 0;
      border: 0;
      font-size: 16px;
      text-align: center;
      opacity: 0;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  &__prev, &__prevmonth {
    left: 0;

    &:after {
      transform: rotate(-135deg);
      left: 10px;
    }
  }

  &__next, &__nextmonth {
    right: 0;

    &:after {
      transform: rotate(45deg);
      right: 10px;
    }
  }

  &__refresh {
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 14px;
    color: @white;
    background: @blue;
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    margin-top: 5px;
    margin-left: 90px;
    transform: translate(-50%, -50%);

    @media @mobile {
      position: fixed;
      z-index: 1000;
      transform: translate(-50%, 0);
      top: 76px;
      margin-top: 45px;
      margin-left: 38px;

    }
  }

  &__col {
    width: 50%;
    float: left;

    &.before_today {
      opacity: 0.5;
      pointer-events: none;
      font-weight: 700;

      .medecin-agenda__header {
        opacity: 0.5;
      }
    }

    &.today {
      .medecin-agenda__header {
        color: @blue;
      }
    }

    @media @tabletonly {
      width: calc(~"100% / 7");
      float: none;
      display: inline-block;
      min-width: 200px;
      vertical-align: top;
    }

    @media @mobilelandscape {
      min-width: 0;
    }
  }

  &__header {
    font-size: 13px;
    color: @black;
    text-align: center;
    padding: 5px 5px 10px 20px;
    font-weight: 300;

    @media @mobilelandscape {
      position: fixed;
      top: 0;
      margin-left: -5px;
      width: 50%;
      text-align: left;
      background: rgba(255, 255, 255, 0.9);
      z-index: 1999;
      padding-top: 10px;
    }

    @media @mobile {
      position: fixed;
      top: 60px;
      margin-left: -5px;
      width: 50%;
      text-align: left;
      background: rgba(255, 255, 255, 0.9);
      z-index: 100;
      padding-top: 20px;
    }

    .day-name {
      display: block;
      font-size: 16px;
      font-weight: 500;
      text-transform: capitalize;

      @media @mobile {
        text-align: center;
      }
    }

    .date-formated {
      display: block;

      @media @mobile {
        text-align: center;
      }
    }
  }

  &__wrapper {
    height: 1440px;
    position: relative;

    .medecin-agenda__item.option.selected {
      left: 0 !important;
    }

    @media @mobilelandscape {
      margin-top: 5px;
      top: 57px;
    }

    @media @mobile {
      margin-top: 50px;
    }
  }

  &__graduation {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 1;
    padding: 0 10px 0 25px;

    @media @desktoponly {
      padding: 0 0 0 25px;
    }

    @media @mobile {
      padding: 0 10px 0 25px;
    }

    &--line {
      display: block;
      height: 31px;
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, .1);
      margin: 10px 0 39px;
      position: relative;
      float: left;
      clear: both;

      &:after, &:before {
        position: absolute;
        content: '';
        height: 1px;
        left: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.1);
      }

      &:after {
        top: -11px;
        background: rgba(0, 0, 0, 0.3);
      }

      &:before {
        bottom: -11px;
        display: none;
      }
    }

    &--hour {
      font-size: 10px;
      font-weight: 400;
      font-family: Sans-Serif, Monospace !important;
      position: absolute;
      left: -20px;
      top: -15px;

      @media @desktoponly {
        font-size: 8px;
        left: -17px;
      }

      @media @mobilelandscape {
        left: -20px;
      }

      @media @mobile {
        font-size: 9px;
        left: -20px;
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 3;

    .medecin-agenda__item {
      background: @white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      @media @desktoponly {
        width: calc(~"100% - 45px");
      }
    }

    &.to-book-content {
      z-index: 2;
    }
  }

  &__item, &__item-cab {
    position: absolute;
    height: 20px;
    background: transparent;
    left: 25px;
    width: calc(~"100% - 35px");
    padding: 0 10px;
    transition: all 150ms linear;
    cursor: pointer;

    @media @desktoponly {
      width: calc(~"100% - 25px");

      &:hover:not(.selected) {
        &:before {
          content: 'Ajouter Rdv/Supprimer';
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 100%;
          height: 100%;
          border: 1px dashed @green;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 20;
          background: @white;
          color: @grey;
          padding: 0 10px;
          font-size: 12px;
        }
      }
    }

    @media @mobilelandscape {
      width: calc(~"100% - 35px");
    }

    &.has-rdv, &.to-book {
      &:before {
        display: none;
      }
    }

    &.to-book {

      background: rgba(0, 0, 0, 0.04);
      z-index: 1;

      &.selected {
        z-index: 100;
        box-shadow: none;

        .medecin-agenda__item--type {
          display: none;
        }
      }

      &:after {
        display: none;
      }


      .medecin-agenda__item--type {
        opacity: 0;
      }

      @media @desktoponly {
        &:hover {
          background: @blue;
          opacity: 0.5;

          .medecin-agenda__item--type {
            opacity: 1;
          }

          .medecin-agenda__item.option:hover {
            &:before {
              display: none !important;
            }
          }

          &:after {
            position: absolute;
            z-index: 10;
            content: '+';
            right: 0;
            top: 0;
            display: block;
            font-size: 14px;
            color: @white;
            line-height: 20px;
            width: 14px;
            background: none;
          }
        }

        &.selected:hover {
          opacity: 1;
          background: none;
        }
      }
    }

    &.has_replace_medecin {
      .medecin-agenda__item--type {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .setMultiCol2(2, 'even');
    .setMultiCol2(2, 'odd');
    .setMultiCol3(3, 'even');
    .setMultiCol3(3, 'odd');
    .setMultiCol4(4, 'even');
    .setMultiCol4(4, 'odd');
    .setMultiCol5(5, 'even');
    .setMultiCol5(5, 'odd');
    .setMultiCol6(6, 'even');
    .setMultiCol6(6, 'odd');
    .setMultiCol7(7, 'even');
    .setMultiCol7(7, 'odd');
    .setMultiCol8(8, 'even');
    .setMultiCol8(8, 'odd');

    &.selected {
      .medecin-agenda__item--type {
        color: @white !important;

        .icon-video-camera, .icon-seat, .icon-loop2 {
          color: @white;
          opacity: 1;
        }
      }
    }
    &.has-rdv {
      z-index: 200;

      @media @desktoponly {
        max-width: calc(~"100% - 45px");

      }

      &.selected {
        max-width: 100% !important;
        left: 25px !important;
      }

      .medecin-agenda__item--type {
        font-size: 11px;
        color: @black;

        .icon-video-camera, .icon-seat, .icon-loop2 {
          color: @black;
          opacity: 1;
        }
      }

      &:after {
        background: @blue;
        width: 5%;
        height: 20px;
        top: 0;
        right: 0;

        @media @desktoponly {
          width: 5%;
        }
      }

      &.consultationdistante {
        &:after {
          background: @porange2;
        }
      }

      &.consultationcabinet {
        &:after {
          background: @blue;
        }
      }
    }

    &.absence {
      background: #e4e4e4;
      z-index: 200;
      border-bottom: 0;

      &.first {
        border-top: 1px solid #bbb;
      }

      .medecin-agenda__item--type {
        font-size: 11px;
        color: @black;
        font-weight: 400;
      }

      &.selected {
        background: #666;

        .medecin-agenda__item--type {
          color: @white;
        }
      }

      &:after {
        display: none;
      }

      &.consultationdistante {
        &:after {
          background: @porange2;
        }
      }

      &.consultationcabinet {
        &:after {
          background: @olive;
        }
      }

      & + .absence {
        .medecin-agenda__item--type {
          color: transparent;
        }
      }
    }

    @media @mobile {
      left: 25px;
      width: calc(~"100% - 35px");
    }

    &[data-time="07h00"] {
      top: 0;
    }

    &[data-time="07h15"] {
      top: 20px;
    }

    &[data-time="07h30"] {
      top: 40px;
    }

    &[data-time="07h45"] {
      top: 60px;
    }

    &[data-time="08h00"] {
      top: 80px;
    }

    &[data-time="08h15"] {
      top: 100px;
    }

    &[data-time="08h30"] {
      top: 120px;
    }

    &[data-time="08h45"] {
      top: 140px;
    }

    &[data-time="09h00"] {
      top: 160px;
    }

    &[data-time="09h15"] {
      top: 180px;
    }

    &[data-time="09h30"] {
      top: 200px;
    }

    &[data-time="09h45"] {
      top: 220px;
    }

    &[data-time="10h00"] {
      top: 240px;
    }

    &[data-time="10h15"] {
      top: 260px;
    }

    &[data-time="10h30"] {
      top: 280px;
    }

    &[data-time="10h45"] {
      top: 300px;
    }

    &[data-time="11h00"] {
      top: 320px;
    }

    &[data-time="11h15"] {
      top: 340px;
    }

    &[data-time="11h30"] {
      top: 360px;
    }

    &[data-time="11h45"] {
      top: 380px;
    }

    &[data-time="12h00"] {
      top: 400px;
    }

    &[data-time="12h15"] {
      top: 420px;
    }

    &[data-time="12h30"] {
      top: 440px;
    }

    &[data-time="12h45"] {
      top: 460px;
    }

    &[data-time="13h00"] {
      top: 480px;
    }

    &[data-time="13h15"] {
      top: 500px;
    }

    &[data-time="13h30"] {
      top: 520px;
    }

    &[data-time="13h45"] {
      top: 540px;
    }

    &[data-time="14h00"] {
      top: 560px;
    }

    &[data-time="14h15"] {
      top: 580px;
    }

    &[data-time="14h30"] {
      top: 600px;
    }

    &[data-time="14h45"] {
      top: 620px;
    }

    &[data-time="15h00"] {
      top: 640px;
    }

    &[data-time="15h15"] {
      top: 660px;
    }

    &[data-time="15h30"] {
      top: 680px;
    }

    &[data-time="15h45"] {
      top: 700px;
    }

    &[data-time="16h00"] {
      top: 720px;
    }

    &[data-time="16h15"] {
      top: 740px;
    }

    &[data-time="16h30"] {
      top: 760px;
    }

    &[data-time="16h45"] {
      top: 780px;
    }

    &[data-time="17h00"] {
      top: 800px;
    }

    &[data-time="17h15"] {
      top: 820px;
    }

    &[data-time="17h30"] {
      top: 840px;
    }

    &[data-time="17h45"] {
      top: 860px;
    }

    &[data-time="18h00"] {
      top: 880px;
    }

    &[data-time="18h15"] {
      top: 900px;
    }

    &[data-time="18h30"] {
      top: 920px;
    }

    &[data-time="18h45"] {
      top: 940px;
    }

    &[data-time="19h00"] {
      top: 960px;
    }

    &[data-time="19h15"] {
      top: 980px;
    }

    &[data-time="19h30"] {
      top: 1000px;
    }

    &[data-time="19h45"] {
      top: 1020px;
    }

    &[data-time="20h00"] {
      top: 1040px;
    }

    &[data-time="20h15"] {
      top: 1060px;
    }

    &[data-time="20h30"] {
      top: 1080px;
    }

    &[data-time="20h45"] {
      top: 1100px;
    }

    &[data-time="21h00"] {
      top: 1120px;
    }

    &[data-time="21h15"] {
      top: 1140px;
    }

    &[data-time="21h30"] {
      top: 1160px;
    }

    &[data-time="21h45"] {
      top: 1180px;
    }

    &[data-time="22h00"] {
      top: 1200px;
    }

    &[data-time="22h15"] {
      top: 1220px;
    }

    &[data-time="22h30"] {
      top: 1240px;
    }

    &[data-time="22h45"] {
      top: 1260px;
    }

    &[data-time="23h00"] {
      top: 1280px;
    }

    &[data-time="23h15"] {
      top: 1300px;
    }

    &[data-time="23h30"] {
      top: 1320px;
    }

    &[data-time="23h45"] {
      top: 1340px;
    }

    &[data-duration="15"] {
      height: 20px;

      &:after {
        height: 20px;
      }
    }

    &[data-duration="30"] {
      height: 40px;

      &:after {
        height: 40px;
      }
    }

    &[data-duration="45"] {
      height: 60px;

      &:after {
        height: 60px;
      }
    }

    &[data-duration="60"] {
      height: 80px;

      &:after {
        height: 80px;
      }
    }

    &[data-duration="75"] {
      height: 100px;

      &:after {
        height: 100px;
      }
    }

    &[data-duration="90"] {
      height: 120px;

      &:after {
        height: 120px;
      }
    }

    &[data-duration="105"] {
      height: 140px;

      &:after {
        height: 140px;
      }
    }

    &[data-duration="120"] {
      height: 160px;

      &:after {
        height: 160px;
      }
    }

    &.option {
      width: 100%;
      left: 0;
      top: 0;
    }

    &.selected {
      height: auto;
      background: @green;
      z-index: 250;
      padding: 5px 10px 10px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
      cursor: default;
      max-width: 100% !important;
      left: 25px !important;

      @media @mobilelandscape {
        padding: 25px 5px 5px;
      }

      &.has-rdv {
        background: @dark;
        min-height: 150px;

        .medecin-agenda__item--type {
          color: @white;

          .icon-video-camera {
            color: @white;
          }
        }
      }

      .medecin-agenda__item--close, .medecin-agenda__item--infos {
        display: block;
      }

      &:after {
        opacity: 0;
      }
    }

    &--close {
      position: absolute;
      top: 0;
      right: 2px;
      color: @white;
      font-size: 14px;
      font-weight: 500;
      background: none;
      z-index: 100;
      display: none;
      padding: 5px;
    }

    &--type, &--infos {
      display: block;
      height: 20px;
      line-height: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: @white;
      font-size: 12px;
      position: relative;
      z-index: 10;

      .icon-video-camera {
        color: @green;
        opacity: 0.4;
      }

      .icon-seat {
        color: @orange;
        opacity: 0.4;
      }

      .icon-loop2 {
        color: @blue;
        opacity: 0.4;
      }

      @media @mobilelandscape {
        font-size: 10px;
      }

      @media @mobile {
        font-size: 10px;
        white-space: normal;
      }
    }

    &--type {
      padding-right: 10px;
      white-space: nowrap !important;
      text-overflow: initial;

      span {
        white-space: nowrap !important;
      }
    }

    &--infos {
      display: none;
      height: auto;
      padding-bottom: 2px;

      .blue-cta {
        margin-top: 5px;

        @media @mobile {
          font-size: 9px;
          padding: 0 6px;
        }
      }
    }

    &:after {
      position: absolute;
      content: '';
      right: 0;
      top: 0;
      background: @green;
      width: 5%;
      transition: all 150ms linear;
      opacity: 1;
      z-index: 21;
    }
  }
}

.month-agenda {
  &__wrapper {
    position: relative;
    padding: 1px 0;
    margin-top: 30px;
    overflow: hidden;

    @media @mobile {
      margin-left: -21px;
      margin-right: -24px;
    }

    &:before {
      content: '';
      background: @lightgrey;
      top: 0;
      left: 0;
      height: 100%;
      width: calc(~"100% - 7px");
      z-index: 0;
      position: absolute;
    }
  }

  &__month-day {
    float: left;
    position: relative;
    width: calc(~"100% / 7");
    padding-bottom: calc(~"100% / 7");
    height: 0;
    background: @white;
    margin: -1px -1px 0 0;
    border: 1px solid @lightgrey2;

    @media @mobile {
      width: calc(~"100% / 4");
      padding-bottom: calc(~"100% / 3");
    }

    &.before_today {
      opacity: 0.5;

      .month-agenda__month-day--label {
        background: @grey;
      }
    }

    &.today {

      z-index: 10;
      border-color: @green;

      .month-agenda__month-day--label {
        background: @green;
      }
    }

    &:first-child {
      &[data-day="0"] {
        margin-left: calc(~"(100% / 7)*6 - 6px");

        @media @mobile {
          margin-left: 0;
        }
      }

      &[data-day="2"] {
        margin-left: calc(~"(100% / 7)*1 - 1px");

        @media @mobile {
          margin-left: 0;
        }
      }

      &[data-day="3"] {
        margin-left: calc(~"(100% / 7)*2 - 2px");

        @media @mobile {
          margin-left: 0;
        }
      }

      &[data-day="4"] {
        margin-left: calc(~"(100% / 7)*3 - 3px");

        @media @mobile {
          margin-left: 0;
        }
      }

      &[data-day="5"] {
        margin-left: calc(~"(100% / 7)*4 - 4px");

        @media @mobile {
          margin-left: 0;
        }
      }

      &[data-day="6"] {
        margin-left: calc(~"(100% / 7)*5 - 5px");

        @media @mobile {
          margin-left: 0;
        }
      }
    }

    &--label {
      font-size: 12px;
      position: absolute;
      top: 10px;
      right: 10px;
      background: @blue;
      color: @white;
      height: 20px;
      line-height: 20px;
      width: 20px;
      text-align: center;
      border-radius: 100px;

      @media @tablet {
        font-size: 8px;
        top: 5px;
        right: 5px;
        height: 15px;
        line-height: 15px;
        width: 15px;
      }
    }
  }

  &__content {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    padding: 0 10px;
    margin-top: 10px;

    &--sum {
      display: inline-block;
      border-radius: 10px;
      padding: 2px 10px;
      margin: 2px 5px;
      max-width: calc(~"100% - 10px");
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 10px;

      &.absence {
        background: @lightgrey3;
      }

      &.rdv {
        background: @purple;
        color: @white;
      }

      &.dispo {
        background: @blue;
        color: @white;
      }
    }

    &--number {
      font-size: 12px;
      font-weight: 700;
      padding-right: 5px;
    }

    &--label {
      font-size: 10px;
      font-weight: 700;
      padding-right: 5px;
    }
  }
}

.cab-medecins {

  &__date {
    padding-bottom: 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
  }

  &__label {
    height: 30px;
    line-height: 30px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    font-size: 16px;
    color: @black;
    margin: 0 10px;

    @media @mobile {
      font-size: 14px;
    }
  }

  &__select-inline {
    background: none;
    height: 30px;
    line-height: 30px;
    max-width: 300px;
    display: inline-block;
    position: relative;
    vertical-align: middle;

    .icon-office {
      display: inline-block;
      vertical-align: middle;
      width: 15px;
      font-size: 15px;
      color: @white;
    }

    select, input[type="text"] {
      vertical-align: middle;
      background: transparent url("../assets/images/chevron-down.png") 100% 50% no-repeat;
      background-size: auto 20px;
      border-radius: 0;
      border: 0;
      -moz-appearance: none;
      -webkit-appearance: none;
      color: @black;
      font-size: 16px;
      padding: 0 30px 0 0;
      line-height: 1.5;
      border-bottom: 2px solid @green;
      font-weight: 500;
      z-index: 200;
      position: relative;
      max-width: 100%;

      ::-ms-expand {
        display: none;
      }
    }

    input[type="text"] {
      width: 140px;

      @media @mobile {
        width: 120px;
      }
    }

    &:after {
      content: '';
      position: absolute;
      top: 42%;
      right: 14px;
      width: 8px;
      height: 8px;
      border-top: 1px solid @white;
      border-right: 1px solid @white;
      transform: translate(0, -50%) rotate(135deg);
    }
  }

  &__planning {
    .preview-list {
      padding: 0;

      .date-wrapper {
        display: flex;
        text-align: center;
        justify-content: center;
        width: 100%;
        padding: 30px 0 10px;

        span {
          margin: 0 10px;
        }

        &:after {
          display: none;
        }
      }

      .date-list-wrapper {
        padding: 10px;
        width: 100%;
        text-align: center;

        .date-item {
          margin: 5px;
          padding-right: 25px;

          span, .icon {
            vertical-align: middle;
          }

          span {
            font-family: Sans-Serif, monospace !important;
          }

          .icon {
            margin-right: 5px;
          }

          &.opened {
            background: @green;
          }

          &.taken {
            background: @black;
          }
        }
      }
    }
  }

  &__name {
    font-size: 16px;
    text-align: center;
    padding-bottom: 20px;
  }

  &__wrapper {

    overflow: auto;
    -webkit-overflow-scrolling: touch;
    display: flex;
    margin: 0 -40px;
    padding: 0 20px;
    white-space: nowrap;
    flex-wrap: nowrap;

    @media @tablet {
      margin: 0 -40px;
      white-space: nowrap;
      flex-wrap: nowrap;
    }

    @media @mobile {
      margin: 0 -20px;
    }
  }

  &__cta {
    text-align: center;
    padding-bottom: 20px;
  }

  &__col {
    display: block;
    min-width: 400px;
    max-width: 400px;
    background: @white;
    white-space: normal;
    vertical-align: top;
    position: relative;
    margin: 10px 10px 0 0;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.07);

    @media @tablet {
      min-width: 64vw;
      max-width: 64vw;
    }

    @media @mobile {
      min-width: 84vw;
      max-width: 84vw;
    }

    .medecin-agenda__wrapper {
      margin: 0 -20px;
      height: 1360px;
      cursor: pointer;

      @media @tablet {
        margin: 0 -10px;
      }
    }

    .medecin-agenda__graduation {
      width: calc(~"100% - 20px");

      @media @tablet {
        padding-right: 0;
        width: calc(~"100% - 10px");
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: calc(~"100% - 25px");
        background: rgba(0, 125, 255, 0.04);
        z-index: 1;
      }
    }

    .medecin-agenda__item-cab {
      font-size: 11px;
      z-index: 10;
      background: @white;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: flex-start;
      width: calc(~"100% - 45px");
      white-space: nowrap;
      line-height: 20px;

      &.has-rdv {

      }

      &.absence {
        background: #e4e4e4;
        z-index: 200;
        border-bottom: 0;

        &.first {
          border-top: 1px solid #bbb;
        }

        &:after {
          display: none;
        }

        & + .absence {
          color: transparent;
        }
      }

      @media @tablet {
        width: calc(~"100% - 35px");
      }

      &:after {
        height: 100%;
      }

      &:before {
        display: none;
      }

      span {
        font-family: Sans-Serif, monospace !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        margin-right: 10px;
        font-size: 12px;

        &.icon-video-camera {
          color: @green;
        }

        &.icon-loop2 {
          color: @blue;
        }

        &.icon-seat {
          color: @orange;
        }
      }
    }
  }
}

.agenda-form {
  position: relative;
}

[data-role="medecin-agenda-tab"] {
  display: none;

  &.selected {
    display: block;
  }

  &.loading {
    > * {
      opacity: 0.5;
    }

    &:after {
      position: fixed;
      z-index: 1000;
    }

    &:before {
      position: fixed;
    }
  }
}

[data-role="cab-agenda"] {
  .preview-list, .month-agenda {
    display: none !important;
  }

  .medecin-agenda {
    display: block !important;
  }
}