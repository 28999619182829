@header-height: 60px;

.header {
  height: @header-height;
  line-height: @header-height;
  background: @blue;
  color: @white;
  position: fixed;
  width: 100%;
  text-align: right;
  z-index: 10000;
  padding: 0 20px;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);

  &.dark {
    background: @dargrey;
  }

  &.logo-left {
    .logo-medecin {

      @media @mobile {
        left: 20px;
        transform: translate(0, -50%);
      }
    }
  }

  &.with-sephira {
    .logo-medecin {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo-medecin {
        &__lm {
          width: 140px;
          height: auto;
        }

        &__cross {
          margin: 0 10px;
          font-size: 14px;
          color: @white;
        }

        &__sephira {
          width: 100px;
          height: auto;
        }
      }
    }


  }

  .medecin-profil & {
    z-index: 1000;
  }

  &__search-hp {
    display: inline-block;
    vertical-align: middle;
    margin-right: 30px;

    @media @tablet {
      position: absolute;
      top: 19px;
      right: 90px;
    }

    @media @mobile {
      right: 50px;
    }

    .icon {
      color: @white;
      font-size: 25px;
      text-shadow: 2px 3px 0 rgba(0,0,0,0.2);
    }
  }

  &__nav {
    @media @tablet {
      margin: 60px -20px 0 -20px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
    }
  }

  &.maladie {
    .logo-medecin {
      left: 20px;
      transform: translate(0, -50%);

      @media @mobile {
        width: 20px;
        overflow: hidden;

        img {
          width: 140px;
        }
      }
    }
  }

  &.lite {
    .logo-medecin {
      left: 50%;
      transform: translate(-50%, -50%);

      @media @tablet {
        left: auto;
        right: 5%;
        transform: translate(0, -50%);

      }
    }

    .header__rollback {
      display: flex !important;
      align-items: center;

      &--text {
        color: @white;
      }
    }
  }

  &__search-cta {
    background: @white;
    position: relative;
    font-size: 20px;
    color: @blue;
    vertical-align: top;
    width: 62px;
    height: 60px;
    line-height: 60px;
    margin-right: -20px;
    border: 1px solid @blue;

    @media @tablet {
      display: none;
    }

    &:after, &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 60%;
      width: 4px;
      background: @blue;
      transition: all 150ms linear;
      transform: translate(-50%, -50%);
      opacity: 0;
    }

    &.active {
      color: transparent;

      &:after {
        opacity: 1;
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &:before {
        opacity: 1;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }

  &__rollback, &__forward {
    display: none;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    background: none;
    width: 120px;
    height: 14px;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 14px;
      height: 14px;
      border-top: 2px solid @white;
      border-left: 2px solid @white;
    }

    &--text {
      font-size: 14px;
      text-transform: uppercase;
      color: @white;
      font-weight: 500;
    }

  }

  &__rollback {
    left: 1%;
    padding-left: 30px;
    text-align: left;

    &:after {
      left: 10px;
      transform: rotate(-45deg);
    }
  }

  &__forward {
    right: 1%;
    padding-right: 30px;
    text-align: right;

    &:after {
      right: 10px;
      transform: rotate(135deg);
    }

  }

  &.inBookingProcess {

    .header__rollback, .header__forward {
      pointer-events: none;
      display: block;
      opacity: 0;
      transition: opacity 150ms linear;

      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }

    .user-header {
      display: none;
    }

    .logo-medecin {

      pointer-events: none;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
    }
  }

  @media @tablet {
    z-index: 100001;
  }

  .medecinPage & {
    @media @mobilelandscape {
      display: none;
    }
  }

  &__after-promo {
    display: block;
    background: @blue;
    padding: 30px 20px;
    position: relative;
    box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.05) inset;
    margin-bottom: -@header-height*2;
    z-index: 1000;
    width: 100%;
    margin-top: @header-height;

    &--text {
      font-size: 16px;
      line-height: 1.3;
      font-weight: 500;
      color: @white;
      text-align: center;

      strong {
        display: inline-block;
        font-weight: 500;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          bottom: -2px;
          height: 2px;
          left: 0;
          background: @green;
        }

      }
    }

    @cta-width: 280px;

    &--cta {
      font-size: 0;
      text-align: center;
      padding-top: 20px;

      .progress-cta {
        display: inline-block;
        height: 10px;
        line-height: 10px;
        position: relative;
        width: @cta-width;
        background: @white;
        border-radius: 100px;
        overflow: hidden;
        transition: all 300ms linear;

        &--text {
          font-size: 16px;
          height: 100%;
          display: block;
          overflow: hidden;
          position: absolute;
          width: 100%;
          text-align: center;
          white-space: nowrap;
          font-weight: 500;
          top: 50%;
          left: 0;
          transform: translate(0, -50%);

          strong {
            display: block;
            font-size: 16px;
            position: absolute;
            width: @cta-width;
            line-height: 50px;
            left: 0;
          }

          &.first {
            color: @white;
            background: @green;
            z-index: 3;
            transition: all 5000ms linear;
            width: 0;

            &.animate {
              width: 100%;
            }
          }

          &.second {
            color: @green;
            z-index: 1;
          }

        }

        &.opened {
          height: 50px;
          line-height: 50px;
        }
      }
    }
  }

  .user-options {
    visibility: hidden;
    opacity: 0;
    transition: all 100ms linear;
    position: fixed;
    right: 10px;
    top: @header-height;
    width: 250px;
    background: @white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 10px 10px;

    .user-option-opened & {
      opacity: 1;
      visibility: visible;
    }

    &:after {
      content: '';
      position: absolute;
      right: 27px;
      top: -5px;
      border-bottom: 5px solid @white;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    &__list {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
    }

    &__item {
      display: block;
      border-top: 1px solid rgba(0, 0, 0, .1);
      width: 100%;

      &:first-child {
        border-top: 0;
      }
    }

    &__cta {
      background: transparent;
      border: 0;
      display: block;
      padding: 0 40px 0 15px;
      height: 50px;
      line-height: 50px;
      text-align: left;
      font-size: 0;
      color: @black;

      .icon {
        width: 20px;
        font-size: 16px;
      }

      span {
        display: inline-block;
        margin-right: 5px;
        vertical-align: middle;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.2;
      }

      &--text {
        max-width: calc(~"100% - 30px");
      }
    }
  }

  .mobile-header-visible & {
    text-align: left;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      left: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.2);
      position: absolute;
    }
  }

  [class^="icon-"], [class*=" icon-"] {
    font-family: 'icomoon' !important;

    &:before {
      font-family: 'icomoon' !important;
    }
  }

  &:before {
    content: '';
    opacity: 0;
    visibility: hidden;
    height: 100vh;
    width: 100%;
    background: @blue;
    position: fixed;
    top: 0;
    left: 0;
    transition: all 150ms linear;

    @media @tablet {
      transition: none;
    }
  }

  .mobile-header-visible & {
    &:before {
      content: '';
      opacity: 1;
      visibility: visible;
    }
  }

  &__burger {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translate(0, -50%);
    width: 25px;
    height: 25px;
    display: none;

    @media @tablet {
      display: block;
    }

    &.with-profil {
      span {
        display: none;
      }

      .mobile-header-visible & {
        .icon {
          display: none;
        }

        span {
          display: block;
        }
      }
    }

    .icon {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 26px;
    }

    span {
      display: block;
      height: 2px;
      width: 100%;
      margin: 5px 0;
      background: @white;

      .mobile-header-visible & {
        position: absolute;
        top: 7px;

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .full-dimming-overlay {
    display: none;
  }

  .logo-medecin {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 140px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    @media @tablet {
      left: 50%;
      transform: translate(-50%, -50%);

      .connectedPage & {
        width: 25px;
        overflow: hidden;
        left: 5%;
        transform: translate(0, -50%);

        img {
          max-width: 150px;
          width: 150px;
        }
      }
    }
  }

  .user-header {
    text-align: right;
    display: inline-block;
    cursor: pointer;

    .user-name__count {
      position: absolute;
      width: 15px;
      height: 15px;
      top: 12px;
      right: 67px;
      line-height: 15px;
      font-size: 12px;
      border-radius: 20px;
      text-align: center;
      background: @yellowpj;
      color: @black;
    }

    .user-name__infos-account {
      position: absolute;
      top: 53px;
      right: 10px;
      background: @yellowpj;
      color: @black;
      font-size: 12px;
      padding: 5px;
      width: 240px;
      line-height: 1.3;
      border-radius: 4px;
      display: none;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);

      &:after {
        content: '';
        position: absolute;
        top: -10px;
        right: 50px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid @yellowpj;
      }
    }

    .user-infos {
      display: inline-block;
      vertical-align: middle;

      .user-name, .my-space {
        display: block;
        font-size: 12px;
        text-transform: uppercase;
        line-height: 1.3;
        position: relative;

        &__bullet {
          position: absolute;
          top: -3px;
          z-index: 2;
          text-transform: uppercase;
          right: -19px;
          font-size: 8px;
          text-align: center;
          padding: 1px 3px;
          line-height: 1;
          background: @yellowpj;
          color: @black;
          border-radius: 100px;
          font-weight: 400;

        }
      }

      .my-space {
        color: @white;
        opacity: 0.5;
      }

    }

    .initial {
      display: inline-block;
      width: 40px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 10px;
      color: @blue;
      background: @white;
      border-radius: 99px;
      text-transform: uppercase;
      margin-left: 20px;
      vertical-align: middle;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .header-link {
    color: @white;
    text-transform: uppercase;
    font-weight: 300;
    font-size: 12px;
    margin-right: 20px;
    position: relative;

    &.praticien {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0, -50%);

      > span {
        text-transform: none;
        font-weight: 500;
        border: 1px solid @white;
        border-radius: 4px;
        padding: 8px;
        line-height: 1;
      }

      @media @tablet {
        margin-top: 10px;
        position: relative;
        left: 0;
        top: 0;
        transform: none;
      }
    }

    @media @tablet {
      margin-right: 0;
      display: none;
      padding: 0 20px;
      width: 100%;

      .mobile-header-visible & {
        display: block !important;
      }
    }

    &.medecin-cta {
      margin-right: 40px;

      @media @tablet {
        margin-right: 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      }

      &:after {
        content: '';
        position: absolute;
        height: 20px;
        width: 1px;
        background: @white;
        top: 50%;
        transform: translate(0, -50%);
        right: -24px;
        opacity: 0.2;

        @media @tablet {
          display: none;
        }
      }
    }

    i {
      font-size: 17px;
      margin-right: 10px;
      vertical-align: middle;
    }

    span {
      color: @white;
      vertical-align: initial;

      @media @tablet {
        &.hidden-mobile {
          display: none;
        }
      }
    }
  }
}

.header-mg {

  width: 100%;
  min-height: 550px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 1000;
  height: calc(~"100vh - 270px");

  &.fix-h {
    height: auto;
    min-height: auto;
  }

  &.for-hp {
    height: calc(~"100vh - 600px");

    @media @mobile {
      height: calc(~"100vh - 220px");
      height: calc(~"var(--vh, 1vh) * 100 - 220px");
      min-height: 400px;
      max-height: 700px;
      transition: height linear 150ms;
      overflow: visible;

      .header-mg__content {
        padding-top: 0;
      }
    }
  }

  @media @mobile {
    height: calc(~"100vh - 150px");
    height: calc(~"var(--vh, 1vh) * 100 - 150px");
    min-height: 450px;
    max-height: 700px;
    transition: height linear 150ms;
  }

  &__notes {
    font-size: 16px;
    opacity: 0.8;
    margin-top: 40px;

    @media @mobile {
      font-size: 14px;
    }
  }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @media @mobile {
      filter: brightness(60%);
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__inner {
    width: 100%;
    max-width: 1270px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  &__content {
    position: relative;
    width: 100%;
    z-index: 3;
    color: @white;
    padding: 40px 420px 50px 40px;

    @media @tablet {
      padding: 20px 20px 20px 20px;
    }

    @media @mobile {
      padding: 30px 20px 0 20px;
    }
  }

  &__top {
    font-size: 25px;
    line-height: 1.3;
    font-weight: 500;
    margin-bottom: 30px;
    text-shadow: 0 0 6px @black;

    span {
      background-image: linear-gradient(to right, @turquoise 100%, transparent 100%);
      background-position: 0 1.1em;
      background-repeat: repeat-x;
      background-size: 2px 2px;
      display: inline;
    }

    @media @mobile {
      font-size: 18px;
      text-shadow: 0 0 1px @black;
    }
  }

  &__cta {
    .std-btn {
      font-weight: 700;
      font-size: 16px;
      background: @yellow;
      color: @black;
      text-transform: none;
      padding: 0 30px;
      line-height: 50px;
      height: 50px;
    }

    @media @mobile {
      position: fixed;
      z-index: 100;
      left: 0;
      bottom: 0;
      width: 100%;
      text-align: center;
      background: @white;
      padding: 20px;
      box-shadow: 0 0 5px rgba(0,0,0,0.2);

      .std-btn {
        width: 100%;
      }
    }
  }

  &__list {
    margin: 50px 10px 60px;
    list-style: none;

    @media @mobile {
      margin: 30px 10px 40px;
    }

    li {
      position: relative;
      font-size: 16px;
      line-height: 1.3;
      margin-bottom: 15px;
      font-weight: 700;
      padding-left: 30px;
      text-shadow: 0 0 6px @black;

      &:before {
        position: absolute;
        content: '';
        width: 14px;
        height: 6px;
        border-left: 3px solid @turquoise;
        border-bottom: 3px solid @turquoise;
        left: 0;
        top: 0;
        transform: rotate(-45deg);
      }
    }
  }

  &__title {
    font-weight: 900;
    line-height: 1.2;
    font-size: 58px;
    text-shadow: 0 0 6px @black;

    @media @mobile {
      font-size: 30px;
      text-shadow: 0 0 1px @black;
    }
  }

  &__hand {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    height: 100%;
    width: 400px;

    @media @tablet {
      filter: brightness(50%);
    }

    @media @mobile {
      filter: brightness(30%);
    }

    img {
      display: block;
      height: 70%;
      width: auto;
      position: absolute;
      bottom: 0;
      left: 0;
      object-position: bottom;
      object-fit: contain;

      @media @mobile {
        width: 100%;
        left: auto;
        right: -50%;
      }
    }
  }
}