@easeOut: cubic-bezier(0.165, 0.840, 0.440, 1.000);

.preconsult {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;

  &__ongoing-tlc {
    margin: 10px 0;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid @yellowlt;
    background: @yellowlt2;
    position: relative;

    &--list {
      font-size: 12px;
      margin-top: 15px;
    }

    &--link {
      color: @black;
      font-weight: 500;
      margin-bottom: 20px;
      line-height: 1.3;
      display: flex;
      width: 100%;
      align-items: center;

      .icon {
        margin-right: 5px;
        font-size: 14px;
        color: @porange3;
        opacity: 0.5;
      }

      .blue-cta {
        height: 20px;
        margin-left: 10px;
        line-height: 20px;
        font-size: 10px;
        padding: 0 10px;
        font-weight: 700;
      }
    }

    &--title {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .icon {
        color: @porange3;
        font-size: 16px;
        margin-right: 10px;
      }
    }

    &--desc {
      font-size: 14px;
      line-height: 1.5;

      .blue-cta {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        padding: 0 10px;
        font-weight: 700;
        margin-top: 10px;
      }
    }
  }

  &__search-list {
    font-size: 14px;
    text-align: left;
    padding: 0 20px 20px;

    &--none {
      font-weight: 500;
      color: @blue;
      line-height: 1.3;
      padding: 20px 0;
      text-align: center;
      width: 100%;
    }

    &--item {
      display: flex;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      padding-bottom: 10px;
      margin-bottom: 20px;
      cursor: pointer;
      justify-content: space-between;

      .medecin-name {
        font-weight: 500;
        text-transform: uppercase;
        line-height: 1.3;
        display: block;
      }

      .medecin-spe {
        font-weight: 300;
        font-size: 12px;
        display: block;
        line-height: 1.3;
        padding-bottom: 3px;
      }

      .medecin-address {
        font-size: 13px;
        font-weight: 400;
        display: block;
        line-height: 1.3;
      }
    }

    &--content {

    }

    &--cta {

    }
  }

  &__result {
    display: flex;
    align-items: flex-start;
    padding: 0 0 20px;

    &.header-title {
      font-size: 16px;
      margin-bottom: 10px;
    }

    b {
      font-weight: 500;
    }

    &--wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--visual {
      padding-left: 10px;

      @media @mobile {
        display: none;
      }

      img {
        display: block;
        width: 170px;
        height: auto;
        opacity: 0.5;
        margin-bottom: 20px;
      }
    }

    &--label {
      font-size: 16px;
      font-weight: 500;
      padding: 20px 0 10px;

      &.no-pad-mobile {
        @media @mobile {
          padding-top: 0;
        }
      }

      @media @verysmallmobile {
        font-size: 14px;
      }
    }

    &--text {
      font-size: 14px;
      padding: 0;
    }
  }

  &__result-cta {
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    margin: 0 auto;

    @media @tablet {
      max-width: 100%;
    }

    .header-right__telecons-desc--center {
      font-size: 14px;
      text-align: left;
      padding: 0;
      line-height: 1.5;

      @media @verysmallmobile {
        font-size: 12px;
        padding-bottom: 0;
      }
    }

    .input-wrapper.fixed-width {
      margin: 0;

      @media @mobile {
        margin: 0 auto;
      }
    }
  }

  &__faq-no-bg {
    margin-top: 20px;

    @media @mobile {
      margin-top: 0;
    }

    .telecons-faq__wrapper {
      background: #f6f6f6;
    }
  }

  &__icon-cross, &__icon-check {
    position: relative;
    width: 20px;
    min-width: 20px;
    height: 20px;
    margin-right: 15px;
    margin-top: 2px;
  }

  &__icon-cross {
    &:after, &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 100%;
      background: @red;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  &__icon-check {
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 3px;
      width: 18px;
      height: 10px;
      border-left: 2px solid @green;
      border-bottom: 2px solid @green;
      transform: rotate(-45deg);
    }
  }

  &__icon-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.5;

    @media @mobile {
      font-size: 12px;
    }

    strong {
      display: inline-block;
      font-size: 16px;
      padding-bottom: 10px;

      @media @mobile {
        font-size: 14px;
      }
    }

    &.no-icon {
      padding-left: 35px;
      margin-top: -10px;
      display: block;
    }

    .error-tel {
      font-size: 12px;
      font-weight: 400;
      color: @black;
      text-decoration: underline;

      @media @mobile {
        margin-top: 10px;
        font-size: 10px;
      }
    }

    &.change-infos {
      padding-left: 35px;
      margin-top: 10px;
    }

    small {
      font-size: 12px;
      margin-top: 10px;
      font-weight: 300;

      a {
        font-size: 12px;
        color: @black;
        font-weight: 300;
        text-decoration: underline;
      }
    }

    .blue-cta.with-chevron {
      margin: 20px 0;
      text-align: left;
      padding-right: 30px;

      @media @verysmallmobile {
        span {
          font-size: 12px;
        }
      }
    }

    @media @verysmallmobile {
      font-size: 12px;
    }

    a {
      font-weight: 500;

      &.black {
        font-weight: 400;
        color: @black;
      }

      .icon {
        margin-right: 10px;

        @media @mobile {
          font-size: 30px;
          margin-right: 20px;
        }
      }
    }

    .phone-others-cases-pj {
      margin-top: 15px;
    }
  }

  &__bottom-text {
    font-weight: 400;
    font-size: 12px;
    line-height: 1.3;
    padding: 10px 0 20px;
  }

  &__note {
    font-weight: 500;
    text-align: left;
    font-size: 12px;
    padding-bottom: 20px;
  }

  .animTelecons {
    opacity: 0;
    pointer-events: none;
    transition: all 300ms @easeOut;
    transform: translate(0, 40px);

    &.done {
      opacity: 1;
      pointer-events: all;
      transform: translate(0, 0);
    }
  }

  &__nav {
    padding-bottom: 20px;

    &.alter-style {
      text-align: center;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      margin-top: 10px !important;
      padding-top: 20px;
      margin-bottom: 20px;

      .preconsult__nav--rollback {
        padding: 0;
        line-height: 1.5;
        width: 100%;
        text-align: center;

        .preconsult__nav--text, .preconsult__nav--text-underline {
          font-weight: 700;
          text-transform: none;
          font-size: 16px;
          text-align: center;
        }

        .preconsult__nav--text {
          color: @black;
        }

        .preconsult__nav--text-underline {
          color: @blue;
          text-decoration: underline;
        }

        &:after {
          display: none;
        }
      }
    }

    #popin-search-agenda & {
      margin-top: -20px;
    }

    &--rollback {
      opacity: 0;
      transition: opacity 150ms linear;
      pointer-events: none;
      position: relative;
      background: none;
      width: 240px;
      height: 14px;
      left: 1%;
      padding-left: 30px;
      text-align: left;

      &.active {
        opacity: 1;
        pointer-events: all;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 14px;
        height: 14px;
        border-top: 2px solid @blue;
        border-left: 2px solid @blue;
        left: 10px;
        transform: rotate(-45deg);
      }
    }

    &--text {
      font-size: 14px;
      text-transform: uppercase;
      color: @blue;
      font-weight: 500;
    }
  }

  &__telecons-presentation {

    .link-cta {
      color: @blue;
      font-weight: 500;
      text-decoration: underline;
      cursor: pointer;
    }

    .telecons-adv-list {
      list-style: none;
      margin: 0;
      padding: 20px 0 0 0;

      &__item {
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: left;
        padding-bottom: 15px;

        &.adv-title {
          font-weight: 500;
          padding-top: 10px;
        }

        &--picto {
          line-height: 1;
          font-size: 26px;
          padding-right: 20px;
          color: @blue;

          img {
            width: 50px;
            height: auto;
            display: block;
          }
        }

        &--text {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    .telecons-advert {
      font-size: 16px;
      padding: 10px 0;
      border-radius: 4px;
      line-height: 1.5;

      .preconsult__cta-left {
        padding-top: 40px;
      }

      &--logo {
        display: block;
        margin: 0 auto;
        width: 200px;
        height: 40px;
      }

      .bigger {
        font-size: 16px;
        line-height: 1.5;
        font-weight: 700;
        display: block;
        text-align: left;
      }

      @media @mobile {
        font-size: 14px;

        .link-cta {
          /*display: block;*/
        }
      }

      strong {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }

  .preconsult__step--inner hr {
    margin-left: 40px;
  }

  &__big-question {
    font-size: 16px;
    font-weight: 700;
    padding: 15px 0 10px;
    text-align: left;
    line-height: 1.5;

    span {
      display: inline-block;
      vertical-align: middle;
      position: relative;

      &.with-line:after {
        position: absolute;
        content: '';
        height: 3px;
        background: @green;
        left: 0;
        width: 100%;
        bottom: 0;
      }
    }
  }

  &__cta-center {
    text-align: center;
    padding: 20px 0 40px;
    margin: 0 -20px;

    .blue-cta {
      margin: 0 auto;
    }

    .telecons-cta {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      min-width: 120px;
      margin: 5px;
    }

    &.more-margin {
      padding-top: 60px;
    }
  }

  &__cta-left {
    text-align: left;
    padding: 20px 0 40px;

    .telecons-cta {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-width: 100%;
      min-width: 120px;
      margin: 5px 10px 5px 0;
    }
  }

  &__search {
    width: 100%;
    padding: 20px 20px 30px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    overflow: hidden;
    border-radius: 20px;
    background: @white;

    @media @mobile {
      width: calc(~"100% - 20px");
      margin: 0 auto;
    }

    .preconsult__input-add--add-item.search .blue-cta {
      margin-right: 0;
    }

    .grid__title--text {
      padding-bottom: 20px;
    }

    .input-wrapper {
      padding: 0;
    }
  }

  &__chapo {

    padding-bottom: 30px;
    font-weight: 400;

    &--title {
      font-size: 16px;
      font-weight: 500;
      color: @blue;
      padding: 10px 0;
      transition: all 300ms linear;

      @media @mobile {
        text-decoration: underline;
      }
    }

    &--desc {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5;
      transition: all 300ms linear;
    }

    .link-cta {
      text-decoration: underline;
      color: @blue;
      cursor: pointer;
      font-weight: 500;
    }
  }

  .input-wrapper {

    input[type="datetime-local"] {
      max-width: 300px;
      text-align: center;
    }

    padding-left: 0;

    &.withIcon {
      .icon {
        left: 15px;
      }
    }
  }

  &__others-praticien {

    .spe-name, .country-name {
      text-transform: uppercase;
    }

    &--cta {
      padding: 30px 0 0;
      text-align: right;

      @media @mobile {
        text-align: center;
      }

      .input-wrapper {
        padding-bottom: 0;
      }

      .blue-cta {
        display: inline-block;
        vertical-align: middle;
        margin: 10px;
        width: auto;
        background: none;
        box-shadow: none;
        color: @blue;
        font-weight: 700;
        line-height: 1.5;

      }
    }

    &--confirm {
      text-align: center;
      font-weight: 500;
      padding: 30px 0;
    }
  }

  &__transmit {
    display: flex;
    align-items: center;
    padding: 10px 10px 40px 10px;
    justify-content: center;
    flex-wrap: wrap;

    @media @mobile {
      padding-bottom: 20px;
    }

    &--img {
      width: 150px;

      img {
        width: 100%;
        height: auto;
        display: block;
      }

      @media @mobile {
        width: 100%;

        img {
          width: 120px;
          margin: 0 auto;
        }
      }
    }

    &--text {
      font-weight: 500;
      font-size: 14px;
      line-height: 1.3;
      padding-right: 20px;
      max-width: calc(~"100% - 160px");

      span {
        display: none;

        &.active {
          display: inline;
        }
      }

      @media @mobile {
        text-align: center;
        padding: 0 0 20px 0;
        font-size: 12px;
        font-weight: 400;
        max-width: 100%;
      }
    }
  }

  &__no-fiche-infos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &--title {
      font-weight: 400;
      color: @black;
      text-align: left;
      opacity: 0;
      transition: all 150ms linear;
      margin: 0 auto;
      display: none;

      @media @mobile {
        padding-bottom: 10px;
      }

      > .icon {
        font-size: 26px;
        margin-right: 20px;
        vertical-align: middle;
        color: @blue;

        & + strong {
          font-size: 14px;
          font-weight: 500;
        }

        &.active {
          color: @blue;
        }
      }

      a {
        color: @blue;
      }

      > strong {
        font-weight: 700;
        display: inline-block;
        position: relative;
        vertical-align: middle;
        font-size: 16px;
        max-width: calc(~"100% - 62px");
        line-height: 1.5;

        .blue-text {
          color: @blue;
          text-align: center;
          line-height: 1;

          small {
            font-weight: 500;
            font-size: 70%;
          }

        }

        &.no-picto {
          max-width: 100%;
        }

        span {
          display: inline-block;
          vertical-align: middle;
          position: relative;

          &.with-line:after {
            position: absolute;
            content: '';
            height: 3px;
            background: @green;
            left: 0;
            width: 100%;
            bottom: 0;
          }
        }
      }

      &.active {
        opacity: 1;
        display: flex !important;
        align-items: center;
        flex-wrap: wrap;
      }

      .sub-line {
        display: block;
        padding-top: 20px;
        font-weight: 300;
        font-size: 14px;

        .link-cta {
          color: @blue;
          font-weight: 500;
          text-decoration: underline !important;
        }
      }
    }

    &--item {
      color: @mediumgrey2;
      text-align: center;
      width: 33.333%;
      transition: all 150ms linear;

      &.active {
        color: @green;
      }
    }

    &--picto {
      font-size: 35px;
    }

    &--text {
      font-size: 14px;
      font-weight: 500;

      @media @mobile {
        font-size: 12px;
      }
    }
  }

  &__text {
    font-size: 14px;
    line-height: 1.3;
    font-weight: 400;

    ul {
      list-style: none;

      li {
        padding: 0 0 20px 40px;
        position: relative;

        &:last-child {
          padding-bottom: 0;
        }

        .preconsult__number {
          top: 0;
        }
      }
    }

    &.note {
      padding: 20px 0 20px 10px;
    }

    &.label {
      padding: 10px 0 15px 10px;
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__img {
    padding-bottom: 20px;

    img {
      max-width: 100%;

      &.hdsasip {
        width: 200px;
      }
    }
  }

  &__emergency-label {
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    padding-top: 10px;
    display: block;
  }

  &__emergency-code {
    font-size: 27px;
    font-weight: 500;
    text-transform: uppercase;
    color: @green;
    display: block;
    text-align: center;
    letter-spacing: 0.15em;
    position: relative;

    @media @mobile {
      font-size: 20px;
    }
  }

  &__sep-title {
    width: 100%;
    position: relative;

    .blue-cta {
      margin: 0 10px 10px 0;
    }

    .preconsult__subtitle {
      font-size: 14px;
      padding: 0 20px 20px 40px;
      margin: 0 -30px 0;
      font-weight: 500;

      .title-with-picto {
        width: calc(~"100% - 60px");

        @media @mobile {
          font-size: 14px;
          width: calc(~"100% - 60px");
        }
      }

      &.no-borter {
        border: 0;
      }

      .icon-left {
        font-size: 20px;
        padding-right: 15px;

        &.bigger {
          font-size: 25px;
          padding-right: 8px;
        }
      }

      @media @mobile {

        font-size: 14px;
        flex-wrap: wrap;

        .blue-cta {
          white-space: nowrap;
        }

        .icon-left {
          font-size: 20px;
        }
      }

      &.apple-checkbox {

      }
    }

    .apple-checkbox__input-wrapper {
      right: 40px;
    }

    .apple-checkbox__text {
      width: calc(~"100% - 60px");

      @media @mobile {
        font-size: 14px;
        width: calc(~"100% - 60px");
      }
    }
  }

  &__data {
    padding: 20px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -20px;

    @media @mobile {
      padding-top: 10px;
    }

    .preconsult__half-wrapper {
      width: 50%;

      @media @mobile {
        width: 100%;
      }
    }

    .preconsult__mensurations {

      .input-wrapper {
        width: 50%;
        padding: 0 10px;
      }
    }

    .preconsult__tiers-wrapper {
      width: 33%;

      @media @smalldesktop {
        width: 50%;
      }

      @media @mobile {
        width: 100%;
      }
    }

    .preconsult__2tiers-wrapper {
      width: 67%;

      @media @smalldesktop {
        width: 50%;
      }

      @media @mobile {
        width: 100%;
      }
    }

    .imc {

      &__data {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
      }

      &__title {
        font-size: 20px;
        font-weight: 500;
        padding: 0 10px;
        white-space: nowrap;

        @media @mobile {
          font-size: 18px;
        }
      }

      &__details {
        font-size: 16px;
        font-weight: 400;
        padding: 0 5px;
        white-space: nowrap;
        display: block;
        width: 100%;
        text-align: center;

        @media @mobile {
          font-size: 14px;
        }
      }

      &__value {
        font-size: 25px;
        color: @blue;
        font-weight: 500;
        padding: 0 10px;

        @media @mobile {
          font-size: 23px;
        }

        &[data-imc="thin"] {
          color: @yellow;
        }

        &[data-imc="regular"] {
          color: @green;
        }

        &[data-imc="bold"] {
          color: #ffdf72;
        }

        &[data-imc="bolder"] {
          color: #ff4d2b;
        }

        &[data-imc="bolder-more"] {
          color: #ff1a11;
        }

        &[data-imc="bolder-max"] {
          color: #b60023;
        }
      }
    }

    .preconsult__desc {
      margin-left: 0;
      width: 100%;
    }

    .preconsult__title {
      padding-left: 10px;
      width: 100%;
    }

    .preconsult__step--inner {
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;

      hr {
        width: 100%;
        margin: 20px 0 40px;

      }

      .preconsult__desc {
        width: 33%;
        padding-right: 30px;
        padding-top: 20px;

        @media @smalldesktop {
          width: 50%;
        }

        @media @mobile {
          width: 100%;
          padding-right: 0;
          padding-top: 0;
        }
      }
    }
  }

  &__pj {
    min-height: 60px;
    position: relative;

    .modal-footer {
      display: none !important;
    }

    iframe {
      display: none;

      &#pjsleads_iframe {
        display: block;
      }
    }
  }

  &__list {
    padding: 0 10px 10px;
    text-align: left;

    @media @mobile {
      padding: 0 0 20px;
    }

    &:empty {
      display: none;
    }

    &--label {
      font-size: 12px;
      display: block;
      text-align: left;
      padding-bottom: 10px;
    }

    &--item {
      display: inline-block;
      position: relative;
      font-size: 14px;
      line-height: 1.3;
      padding: 5px 30px 5px 10px;
      color: @black;
      text-align: left;
      cursor: pointer;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      margin: 0 5px 5px 0;
      font-weight: 500;

      &:after {
        content: 'X';
        color: @white;
        background: @blue;
        font-size: 10px;
        width: 15px;
        text-align: center;
        height: 15px;
        line-height: 15px;
        border-radius: 100px;
        position: absolute;
        right: 4px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  &__step {
    display: block;
    position: absolute;
    padding: 40px 20px;
    top: 0;
    left: 50%;
    height: calc(~"100vh - 60px");
    transform: translate(-50%, 0);
    max-width: 960px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    transition: all 300ms @easeOut;
    overflow-y: auto;

    @media @mobile {
      padding-top: 20px;
      padding-bottom: 80px;
    }

    &.validated {
      opacity: 0;
      transform: translate(-160%, 0);
    }

    &.disabled {
      opacity: 0;
      transform: translate(60%, 0);
    }
  }

  &__title, &__subtitle {
    position: relative;
    z-index: 10;
    font-size: 0;
    font-weight: 700;
    padding-bottom: 30px;
    text-align: center;

    .title-with-picto {
      font-size: 16px;
      font-weight: 500;
    }

    &--text {
      font-size: 22px;
      color: @black;
      line-height: 1.3;

      small {
        font-size: 80%;
      }
    }
  }

  &__title {
    padding-bottom: 50px;
  }

  &__input-add {
    font-size: 0;
    overflow: hidden;
    transition: all 150ms linear;

    .input-wrapper {
      display: inline-block;
      vertical-align: middle;
      width: 100%;

      input {
        padding-right: 100px;
        text-overflow: ellipsis;
      }
    }

    &--add-item {
      padding-bottom: 20px;
      text-align: center;
      position: absolute;
      top: 0;
      right: 0;

      .blue-cta {
        font-size: 12px;
        padding: 0;
        width: 80px;
        height: 30px;
        line-height: 30px;
        margin: 9px 19px 0 0;

        @media @mobile {
          margin-right: 9px;
        }
      }

      &.search {
        .blue-cta {
          margin: 0 10px 0 0;
          height: 48px;
          width: 54px;

          @media @mobile {
            margin-right: 0;
          }

          .icon {
            margin: 0;
            font-size: 20px;
          }
        }
      }
    }

    &--cta {
      background: @green;
      font-size: 16px;
      width: 40px;
      height: 40px;
      border-radius: 100px;
      color: @white;
      font-weight: 500;
    }
  }

  &__input-addmore {
    .input-wrapper {
      padding-left: 0;
    }
  }

  &__subtitle {
    position: relative;
    z-index: 10;
    font-size: 14px;
    font-weight: 400;
    padding: 20px 0 40px;
    color: @black;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .icon-left {
      font-size: 24px;
      color: @blue;
      padding-right: 10px;

      &.red {
        color: @red;
      }
    }

    .title-with-picto {

    }
  }

  &__desc {
    font-weight: 300;
    line-height: 1.3;
    color: @black;
    transition: all 300ms linear;
    font-size: 0;
    position: relative;

    &.toHideTelecons {
      max-width: 800px;
      margin: 0 auto;
    }

    .header-right__bottom-text {
      margin: 30px auto 20px;
    }

    .header-right__title--center {
      margin-top: 80px;

      @media @mobile {
        margin-top: 20px;
      }
    }

    &.site-infos-desc {
      padding: 20px 40px;
      margin: 20px -40px -40px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      background: @white;

      @media @tablet {
        padding: 20px 20px 40px;
        margin: 20px -20px -40px;
      }

    }

    &.checkbox-wrapper {
      padding-left: 30px;
    }

    .emergency-code {
      font-size: 26px;
      padding: 5px 10px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      font-weight: 400;
      display: inline-block;
      margin-top: 10px;
    }

    .preconsult__step--inner {
      .preconsult__title {
        padding-left: 0;
      }

      .preconsult__desc {
        margin-left: 0;
      }
    }

    &--text {
      font-size: 14px;

      &.hiddenOpacity {
        opacity: 0;
      }
    }

    &--pname {
      text-align: center;
      padding-bottom: 20px;
      line-height: 1.5;
      font-size: 16px;

      @media @mobile {
        font-size: 14px;
      }
    }

    &--center-text {
      text-align: center;
      padding-bottom: 20px;
      line-height: 1.5;

      &.main-title {
        font-size: 16px;
        font-weight: 400;
        padding-top: 0;
      }
    }

    .input-wrapper {
      font-size: 0;

      .checkbox-choice {
        width: 100%;
        margin-bottom: 10px;
        white-space: normal;
        padding-left: 42px;

        span {
          display: inline-block;
          line-height: 1.3;
          text-transform: none;
          width: calc(~"100% - 20px");
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__cta {
    text-align: center;
    padding: 20px 0;
    transition: all 300ms linear;
    position: relative;

    .blue-cta {
      margin: 5px;
    }

    &.hiddenOpacity {
      opacity: 0;
    }

    /*.blue-cta {
      margin: 5px;
      height: auto;
      padding: 7px 10px;
      line-height: 1.3;
    }

    .blue-cta.bigger {
      min-width: 200px;
    }*/
  }

  &__number {
    background: @blue;
    font-size: 14px;
    font-weight: 400;
    width: 30px;
    height: 30px;
    line-height: 26px;
    border-radius: 100px;
    color: @white;
    display: inline-block;
    border: 2px solid @white;
    text-align: center;
    position: absolute;
    top: -5px;
    left: 0;
    z-index: 10;
    transition: all 300ms linear;

    &:after {
      content: '';
      position: absolute;
      top: 47%;
      left: 50%;
      transform: translate(-50%, -123%) rotate(-50deg) scale(2);
      height: 8px;
      width: 13px;
      border-left: 2px solid @white;
      border-bottom: 2px solid @white;
      transition: all 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
      opacity: 0;

    }
  }
}

.search-loader {
  position: relative;
  padding: 0;

  &__title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 20px;
    color: @antracite;

    .icon {
      color: @blue;
      margin-right: 10px;
      font-size: 20px;
    }

    .faq-icon {
      background: @antracite;
      color: @white;
      border-radius: 100px;
      height: 25px;
      width: 25px;
      line-height: 25px;
      font-weight: 500;
      margin-right: 20px;
      text-align: center;
    }
  }

  &__visual {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: auto;
    padding-top: 30px;

    @media @mobile {
      display: none;
    }

    img {
      width: 200px;
      height: auto;
      display: block;
      margin: 0 auto;

      @media @mobile {
        width: 100px;
      }
    }
  }

  &__progress {
    text-align: center;
    margin: -10px 0 10px;

    .bar {
      display: block;
      border-radius: 10px;
      margin: 0 auto;
      width: 150px;
      height: 5px;
      background: rgba(0, 0, 0, 0.1);
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        background: @blue;
        will-change: width;

      }

      &.started {
        &:after {
          width: 100%;
          transition: all 2.4s linear;
          background: @green;
        }
      }

      &.reset {
        &:after {
          width: 0%;
        }
      }
    }
  }

  &__text-small {
    font-size: 12px;
    max-width: 540px;
    margin: 20px auto 0;
    text-align: center;
    line-height: 1.5;
    font-weight: 500;

    &--infos {
      font-size: 16px;
      font-weight: 400;
      display: block;
      padding: 20px 0 0 20px;
      position: relative;
      text-align: left;
      margin: 0 auto;

      .link {
        display: inline-block;
        padding-top: 5px;
        color: @blue;
        text-decoration: underline;
        font-size: 16px;
        cursor: pointer;
        font-weight: 500;
      }

      &:before {
        content: '';
        background: @green;
        color: @white;
        height: 10px;
        line-height: 10px;
        width: 10px;
        font-style: normal;
        border-radius: 20px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        position: absolute;
        display: block;
        left: 0;
        top: 25px;
      }
    }

    &--hidden {
      display: none;

      &.active {
        display: inline;
      }
    }
  }

  &__wrapper {
    height: 100px;
    position: relative;
    z-index: 10;

    @media @mobile {

    }
  }

  &__loader,
  &__loader:before,
  &__loader:after {
    background: @green;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }

  &__loader {
    color: @green;
    text-indent: -9999em;
    margin: 48px auto;
    position: relative;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;

    &:before, &:after {
      position: absolute;
      top: 0;
      content: '';
    }

    &:before {
      left: -1.5em;
      -webkit-animation-delay: -0.32s;
      animation-delay: -0.32s;
    }

    &:after {
      left: 1.5em;
    }
  }
}

@keyframes around {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes along {
  0% {
    left: 0;
    top: 5px;
  }
  5% {
    top: 3px;
  }
  10% {
    top: 5px;
  }
  15% {
    top: 3px;
  }
  20% {
    top: 5px;
  }
  25% {
    top: 3px;
  }
  30% {
    top: 5px;
  }
  35% {
    top: 3px;
  }
  40% {
    top: 5px;
  }
  45% {
    top: 3px;
  }
  50% {
    left: 100%;
    top: 5px;
  }
  100% {
    left: 100%;
  }
}

@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}


.apple-checkbox {
  position: relative;

  .icon-left {
    font-size: 24px;
    color: @blue;
    padding-right: 10px;
  }

  &__text {
    display: inline-block;
    width: calc(~"100% - 70px");
    font-weight: 500;
    font-size: 16px;
    text-align: left;
  }

  &__input-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    width: 30px;
    padding: 0 !important;
    transform: translate(0, -50%);
    display: none;
  }

  &__label {
    font-size: 0;
    padding: 0 !important;

    input {
      opacity: 0;
      visibility: hidden;
      position: absolute;

      & + .apple-checkbox__check {
        background: @mediumgrey2;
        width: 30px;
        height: 15px;
        position: relative;
        border-radius: 100px;
        padding: 0;
        display: block;
        transition: all 150ms linear;
        cursor: pointer;

        &:after {
          content: '';
          position: absolute;
          width: 15px;
          height: 15px;
          border: 1px solid @mediumgrey;
          border-radius: 100px;
          background: @white;
          transition: all 150ms linear;
          top: 0;
          left: 0;
        }
      }

      &:checked + .apple-checkbox__check {
        background: @blue;

        &:after {
          top: 0;
          left: 15px;
        }
      }
    }
  }
}

.nb-result {
  color: @green;
  font-size: 30px;
}

.grid {

  &__min-height {

    .grid-item__inner {
      width: 100%;

      min-height: 420px;
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;

      @media @smalldesktop {
        min-height: auto;
      }

      .grid-item__wrapper {
        width: 100%;
      }
    }
  }

  .section__list {
    margin: -20px;

    &--item {
      height: 90px;
      line-height: 90px;

      @media @smalldesktop {
        height: 60px;
        line-height: 60px;
      }

      .picto {
        color: @blue;

        i {
          font-size: 27px;
        }
      }

      .label {
        font-size: 16px;
        font-weight: 400;

        @media @desktoponly {
          opacity: 1;
        }
      }
    }
  }

  &__title {
    text-align: center;
    position: relative;

    .nb-result {
      font-size: 27px;
      display: block;
    }

    &--picto {
      padding-bottom: 10px;
      display: block;

      .icon {
        font-size: 40px;
        color: @blue;
      }
    }

    &--text {
      display: block;
      font-size: 14px;
    }
  }

  .dossier-wrapper__list-param {
    margin: 37px auto 0;
    box-shadow: none;
    border: 0 !important;
    display: flex;

    .dossier-wrapper__item {
      cursor: pointer;
      width: auto;
      height: auto;
      border: 0 !important;
      position: relative;

      &:after {
        content: '';
        display: block !important;
        position: absolute;
        right: 0;
        top: 50%;
        height: 40px;
        transform: translate(0, -50%);
        width: 1px;
        background: rgba(0, 0, 0, 0.1);
      }

      &:nth-child(3) {
        &:after {
          display: none !important;
        }
      }

      &--inner {
        height: auto;

        i {
          font-size: 30px;
          margin-bottom: 15px;
        }
      }

      &:after {
        display: none;
      }
    }
  }

  &__cta {
    text-align: center;
    padding-top: 20px;

    .blue-cta {
      margin: 5px;
    }
  }

  &__cta-text {
    text-align: center;
    padding-top: 10px;
    height: 32px;

    button {
      font-weight: 500;
      color: @blue;
      font-size: 14px;
      background: none;
    }
  }

  &__hr {
    margin-left: -20px;
    margin-right: -20px;

    &.opacity {
      opacity: 0;
    }

    &.invisible {
      opacity: 0;
    }
  }

  .grid-item__inner {

    .checkbox-choice {
      padding-left: 0;
    }

    .preconsult__input-add.advanced {
      .preconsult__list {
        &--item {
          pointer-events: none;
          padding-right: 10px;

          &:after {
            display: none;
          }
        }
      }

      &.show-input {
        .preconsult__list {
          &--item {
            pointer-events: inherit;
            padding-right: 30px;

            &:after {
              display: block;
            }
          }
        }
      }
    }

    .add-padding-left {
      padding-left: 40px;
    }

    .item-list {
      display: none;
    }

    .preconsult__mensurations {
      padding-left: 30px;
      position: relative;

      .input-wrapper label {
        font-size: 14px;
      }

      .icon-measures {
        position: absolute;
        top: -5px;
        color: @blue;
        font-size: 25px;
        left: 7px;
      }
    }
  }
}

.medoc_wrapper {
  .medoc_name {
    font-weight: 400;
    line-height: 1.3;
    display: block;
    white-space: normal;
  }

  .medoc_indications {
    font-weight: 300;
    font-size: 13px;
    line-height: 1.3;
    display: block;
    padding-top: 10px;
    white-space: normal;

    ul {
      list-style: inside;
    }

    a {
      color: @blue;
      font-weight: 500;
    }
  }

  .medoc_inter {

    margin-top: 30px;

    &__title {
      font-weight: 500;
      line-height: 1.3;
      display: block;
      white-space: normal;
      color: @red;
      text-transform: uppercase;
    }

    &__desc {
      padding-top: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
    }
  }
}

.topic_super_wrapper {

  .topic_query {
    padding: 10px 10px;
    margin: -20px -20px 20px;
    white-space: normal;
    background: @blue;
    border-radius: 10px 10px 0 0;

    span {
      font-size: 14px;
      font-weight: 500;
      display: inline-block;
      padding: 2px 14px;
      color: @white;
      border-radius: 100px;
      text-transform: uppercase;
    }
  }

  .topic_wrapper {
    font-size: 13px;
    line-height: 1.3;
    white-space: normal;
    margin-bottom: 15px;
    font-weight: 400;
    position: relative;

    &.item-list {
      display: none;
      padding-bottom: 20px;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      background: #eee;
      width: calc(~"100% + 40px");
      height: 1px;
      margin: 0 -20px;
    }

    a {
      color: @black;
      font-weight: 400;
    }
  }
}

.counterArticlePop {
  position: fixed;
  top: 90px;
  right: 0;
  background: @green;
  color: @white;
  z-index: 10000;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, .1);
  text-align: right;
  width: 220px;
  border-radius: 10px 0 0 10px;
  padding: 10px;
  transition: all 500ms cubic-bezier(0.230, 1.000, 0.320, 1.000);
  transform: translate(110%, 0);

  &.visible {
    transform: translate(0, 0);
  }

  .nb-result {
    color: @white;
    font-size: 20px;
  }

  &__text {
    font-size: 12px;
  }
}

[data-role="hidden-search-input"] {
  .input-wrapper {
    transition: all 150ms linear;
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding-bottom: 0;
  }

  .show-search-input {
    display: block;
    position: absolute;
    top: -40px;
    right: 0;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: @green;
    color: @white;
    border-radius: 100px;
    z-index: 10;
    font-size: 0;

    .icon {
      transition: opacity 150ms linear;
      font-size: 14px;
      vertical-align: middle;
    }

    &:after, &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 2px;
      height: 15px;
      background: @white;
      transform: translate(-50%, -50%);
      transition: all 100ms linear;
      opacity: 0;
    }
  }

  &.active {
    .input-wrapper {
      opacity: 1;
      visibility: visible;
      height: auto;
      padding-bottom: 20px;
    }

    .show-search-input {
      .icon {
        opacity: 0;
      }

      &:after, &:before {
        opacity: 1;
      }

      &:after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }

      &:before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}

.consult-motif {
  .preconsult__text {
    display: none;

    &.active {
      display: block;
    }
  }
}

.scroll-down-btn {
  position: fixed;
  left: 50%;
  top: 50%;
  display: block;
  background: @green;
  height: 40px;
  width: 40px;
  z-index: 10000;
  border-radius: 100px;
  transform: translate(-50%, -50%);
  margin-left: 350px;

  @media @tablet {
    left: auto;
    top: auto;
    right: 10px;
    bottom: 10px;
    transform: translate(0, 0);
  }

  @media @mobile {

  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 15px;
    width: 15px;
    border-bottom: 3px solid @white;
    border-left: 3px solid @white;
    transform: translate(-50%, -50%) rotate(-45deg);
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-name: down;
    animation-timing-function: linear;
  }
}

@keyframes down {
  0% {
    margin-top: -10px;
    opacity: 1;
  }
  100% {
    margin-top: 10px;
    opacity: 0;
  }
}

.current-dispo {
  display: inline-block;
  vertical-align: middle;
  flex-wrap: wrap;
  margin-bottom: 10px;


  &__list {
    width: 250px;
    padding: 10px;
    border: 1px solid #eee;
    margin: 5px;
    border-radius: 4px;
    overflow: hidden;
  }

  &__date {
    background: @blue;
    padding: 5px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: @white;
    margin: -10px -10px 20px -10px;
  }

  &__wrapper {
    display: flex;
    margin-bottom: 15px;
    align-items: center;
  }

  &__period {
    font-family: sans-serif, Monospace !important;
    font-weight: 400;
    font-size: 16px;

    &:after {
      content: ':';
    }
  }

  &__item {
    background: @green;
    color: @white;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: 500;
    margin-left: 10px;
    font-size: 14px;
    line-height: 1;
  }
}

.found-address {
  padding: 0;

  &:empty {
    display: none;
  }

  &__also-address {
    padding: 0 0 10px 44px;
    font-weight: 500;
    text-align: left;
    line-height: 1.5;
    font-size: 12px;

    a {
      color: @blue;
    }
  }

  &__title {
    font-weight: 500;
    display: block;
    text-align: center;
    padding: 20px 0 30px;
  }

  &__content {
    background: #eee;
    margin-bottom: 40px;
    border-radius: 4px;
    text-align: center;
    font-size: 16px;
    letter-spacing: 0.1em;
    padding: 10px;
    font-weight: 500;
    margin-right: 10px;

    @media @mobile {
      margin-right: 0;
    }

    a {
      color: @black;
    }
  }

  &__also-title {
    font-size: 14px;
    text-align: left;
    padding: 10px 0 20px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .icon {
      color: @blue;
      font-size: 26px;
      vertical-align: middle;
      margin-right: 20px;
    }

    span {
      vertical-align: middle;
    }
  }

  &__others {
    font-size: 16px;
    padding: 10px 0 30px;
    font-weight: 500;
  }
}

.next-dispo {
  padding: 20px 0 30px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;

  &__label {
  }

  &__date {
    background: @green;
    color: @white;
    display: inline-block;
    vertical-align: middle;
    padding: 4px 15px;
    margin: 20px 10px;
    border-radius: 100px;
  }
}

.pjdispo {
  &__title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 10px 0 30px;
  }

  &__found-dispo {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
  }

  &__cta {
    text-align: center;
    padding: 0 0 10px;
    display: inline-block;
    vertical-align: middle;
    margin: 10px;

    @media @mobile {
      margin-left: 0;
    }
  }
}

.hidden-show-more {
  display: none !important;
}

div[data-role="show-more-with-cta"] {
  div[data-role="show-more-with-cta-wrapper"] {
    display: none;
  }

  div[data-role="show-more-with-cta-cta"] {
    text-align: center;
    padding: 20px 0;
  }
}

.others-dispo-list {
  padding: 10px;

  &:empty {
    display: none;
  }

  .label {
    display: inline-block;
    vertical-align: middle;
    margin: 10px 15px 10px 0;
  }

  .link {
    color: @blue;
    font-weight: 500;
    position: relative;

    &:after {
      content: '';
      right: -5px;
      position: absolute;
      top: 56%;
      width: 10px;
      height: 10px;
      border-top: 2px solid @green;
      border-right: 2px solid @green;
      transform: translate(0, -50%) rotate(45deg);
    }
  }

  .date {
    background: @green;
    display: inline-block;
    color: @white;
    padding: 5px 15px;
    margin: 5px;
    border-radius: 100px;
    vertical-align: middle;
  }

  &__title {
    font-size: 16px;
    text-align: center;
    padding-bottom: 20px;
  }
}

.confirm-popin {

  top: 60px;
  left: 0;
  transform: translate(0, 0);
  height: calc(~"100% - 60px");

  .confirm-rdv-content {
    max-width: 960px;
    margin: 0 auto;
    transform: translate(110%, 0);
    transition: all 300ms @easeOut;

    &__note {
      font-size: 12px;
      margin-top: 50px;
      line-height: 1.3;
      opacity: 0.7;

      @media @mobile {
        margin-top: 30px;
      }
    }

    &.active {
      transform: translate(0, 0);

      &.disabled {
        transform: translate(-110%, 0);
        opacity: 0;
        pointer-events: none;
      }
    }

    .add-account {
      font-size: 16px;
      font-weight: 500;
      position: relative;
      line-height: 1.5;
    }

    .input-wrapper {
      text-align: center;

      label {
        text-align: center;
        font-size: 22px;
        padding: 30px 0 30px;
        font-weight: 600;
        white-space: normal;
        line-height: 1.3;
        text-transform: none;
      }
    }

    select {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 30px 0 0;
      font-weight: 500;
      font-size: 16px;
      border: 0;
      border-bottom: 2px solid @green;
      border-radius: 0;
      background-position: 103% 50%;
    }

    &__cta {
      text-align: center;
      padding: 40px 0 20px;
      width: 100%;
    }
  }

  .popin-med__close {
    display: none;
  }

  .popin-med__wrapper {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .popin-med__title {
    background: @blue;
    color: @white;
    padding: 10px;
    margin-bottom: 40px;
    border-radius: 15px;

    strong {
      font-size: 13px;
      text-transform: none;
    }
  }

  .rdv-title {
    font-size: 16px;
  }

  .popin-med__subtitle {
    padding-bottom: 20px;
  }

  .rdv-date {
    text-align: center;
    font-size: 16px;
    padding-bottom: 20px;

    .rdv_date, .rdv_time {
      font-size: 22px;
      color: @blue;
      display: inline-block;
      vertical-align: middle;
      margin: 0 5px;
      font-weight: 600;
    }

    @media @mobile {

      .rdv_date, .rdv_time {
        font-size: 20px;
      }

    }
  }
}

.popin-demo {

  .popin-med__title {
    span {
      vertical-align: initial;
    }
  }

  .dossier-wrapper {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    transform: scale(0.85);

    @media @tablet {
      transform: scale(0.5);
      transform-origin: 50% 4%;
    }
  }

  &__avis {
    display: flex;
    align-items: flex-start;
    padding-top: 30px;

    @media @mobile {
      flex-wrap: wrap;
    }

    &--note {
      min-width: 160px;

      @media @mobile {
        width: 100%;
        display: flex;
        align-items: center;
      }
    }

    &--stars {
      font-size: 0;
      margin-left: -3px;

      .star {
        width: 30px;
        height: 30px;
        display: inline-block;
        vertical-align: middle;
      }
    }

    &--value {
      font-size: 30px;
      font-weight: 700;

      @media @mobile {
        font-size: 40px;
      }
    }

    &--wrapper {
      @media @mobile {
        padding-left: 20px;
      }
    }

    &--value-text {
      font-size: 12px;
      font-weight: 500;
      padding-bottom: 10px;

      @media @mobile {
        padding-bottom: 0;
      }

    }

    &--content-wrapper {
      padding-left: 20px;

      @media @mobile {
        padding: 30px 0 0 0;
      }
    }

    &--item {
      margin-bottom: 20px;
    }

    &--desc {
      font-size: 13px;
      padding-bottom: 10px;
      font-weight: 400;
    }

    &--auth {
      font-size: 14px;
      font-weight: 500;
    }
  }

  &__visual {
    margin: 0 -40px;

    img {
      display: block;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;
    }

    @media @tablet {
      margin: 0 -20px;
      position: relative;
      height: 200px;

      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  &__desc {
    padding-top: 30px;
    font-size: 14px;
    font-weight: 400;
  }

  &__cta-wrapper {
    margin: 0 -20px;
    text-align: center;
    padding-top: 20px;

    .blue-cta {
      margin: 5px;
    }
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    padding: 0 0 20px;
    line-height: 1.5;
    text-align: center;

    @media @mobile {
      font-size: 16px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      position: relative;

      &.with-line:after {
        position: absolute;
        content: '';
        height: 3px;
        background: @green;
        left: 0;
        width: 100%;
        bottom: 0;
      }
    }
  }
}

.hiddenOpacity {
  opacity: 0;
  overflow: hidden;
  height: 0;
}

.sas-ans {
  &__inner {
    max-width: 960px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    &.for-hp {
      max-width: 1120px;

      .sas-ans__text {
        max-width: unset;
      }
    }
  }
  &__logo {
    height: 100%;
    margin-right: 30px;
    background: @white;

    @media @mobile {
      margin-right: 10px;
    }

    img {
      height: 100%;
      width: auto;
      display: block;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 500;
    max-width: 690px;
    line-height: 1.5;
    color: @white;

    @media @tablet {
      font-size: 16px;
    }

    @media @mobile {
      font-size: 14px;
      line-height: 1.3;
    }

    @media @smallmobile {
      font-size: 12px;
    }

    a {
      color: @white;
      text-decoration: underline;
    }
  }
}

.alert-infos {
  margin: -20px -20px 30px;

  strong {
    font-weight: 600;

  }

  &.sas-ans {
      background: @antracite;
      color: @dark;
      font-weight: 400;
      padding: 10px 20px 10px 10px;
      height: 100px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin: 0 auto;

    &.for-p3, &.for-hp {
      .sas-ans__text, a {
        color: @black;
      }
    }
    &.for-p3 {
      background: @s_blue;
      margin: 90px 0 -70px 27px;
      width: calc(~"100%/3*2 - 44px");

      @media @tablet {
        margin-top: 40px;
        margin-left: 0;
        margin-bottom: -40px;
        width: 100%;
      }

      @media @mobile {
        background: @antracite;
      }

      .sas-ans__text, a {
        font-size: 16px;

        @media @tablet {
          font-size: 16px;
        }

        @media @mobile {
          font-size: 14px;
          color: @white;
        }
        @media @smallmobile {
          font-size: 12px;
        }
      }
    }
    &.for-hp {
      background: @white;

    }

    @media @mobile {
      padding: 1px 10px 1px 0;
      height: 70px;
    }

    @media @verysmallmobile {
      height: 60px;
    }
  }

  &.profil3 {
    top: 70px;
    z-index: 102;
    width: 100%;
    position: fixed;
    left: 50%;
    max-width: 1280px;
    transform: translate(-50%, 0);
    border-radius: 4px;
    margin: 0;

    &.homepage {
      position: relative;
      top: 0;
      transform: none;
      left: 0;
      margin: -20px auto 30px;
      border-radius: 4px;
      max-width: 1150px;
      overflow: hidden;

      @media @tablet {
        border-radius: 0;
        margin-top: -30px;
      }
    }

    @media @tablet {
      top: 60px;
      left: 0;
      transform: none;
    }

    .has-top-header & {
      position: absolute;
    }

    .has-fixed-elements.has-top-header & {
      position: fixed;
    }

    &.apps {
      position: absolute;
      padding: 0 27px;
      z-index: 0;

      @media @tablet {
        padding: 0;
      }

      .alert-infos__header {
        height: 80px;
        display: flex;
        align-items: center;
        background: @white;
        margin: 0;
        width: calc(~"100%/3*2 - 8px");
        justify-content: flex-start;
        background: @s_blue;
        position: relative;
        padding: 10px;

        @media @tablet {
          width: 100%;
        }
      }

      .apps {
        &__img {
          width: 60px;
          min-width: 60px;
          height: 60px;
          position: relative;
          padding: 5px;
          border-radius: 10px;
          background: @s_blue_darker;

          img {
            width: calc(~"100% - 10px");
            height: calc(~"100% - 10px");
            position: absolute;
            top: 5px;
            left: 5px;
            display: block;
          }
        }

        &__pushes {
          &--desc {
            min-width: calc(~"100% - 70px");
            padding-left: 20px;
            padding-right: 160px;

            @media @mobile {
              padding-left: 10px;
              padding-right: 0;
              min-width: calc(~"100% - 60px") ;
            }
          }

          &--title {
            display: block;
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 5px;

            @media @mobile {
              font-size: 15px;
              margin-bottom: 2px;
            }
            @media @verysmallmobile {
              font-size: 12px;
            }
          }

          &--content {
            display: block;
            font-size: 16px;
            opacity: 0.7;

            @media @mobile {
              font-size: 11px;
              margin-bottom: 5px;
            }
          }

          &--cta {
            display: block;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translate(0, -50%);

            @media @mobile {
              position: relative;
              top: auto;
              transform: none;
              text-align: right;
            }

            @media @verysmallmobile {
              right: 0;
            }

            .blue-cta {
              background: @white;
              color: @blue;
              border-radius: 100px;
              height: auto;
              line-height: 1;
              padding: 10px 20px;
              font-weight: 700;
              text-transform: none;

              @media @mobile {
                font-size: 11px;
                padding: 3px 10px 4px;
              }

              @media @verysmallmobile {
                font-size: 10px;

              }
            }
          }
        }
      }
    }

    &.vaccin {
      a {
        text-decoration: underline;
      }

      @media @mobile {
        text-align: center;

        .icon {
          font-size: 30px;
          margin-left: -25px;
        }

        a {
          display: block;
        }
      }
    }
  }

  &.before-faq {
    margin: 20px -20px -21px;

    @media @mobile {
      margin: 20px -20px -1px;
    }
  }

  &.innerpage {
    margin: 0 0 20px 0;


    .alert-infos__header {
      border-radius: 4px;
    }
  }

  &.fichepage {
    margin: 0 0 30px 0;


    .alert-infos__header {
      border-radius: 10px;

      @media @mobile {
        padding: 5px 10px;
        font-size: 12px;
        border-radius: 4px;
      }
    }
  }

  &__cta {
    padding: 40px 0 0;
    text-align: center;

    .blue-cta {

      @media @mobile {
        display: flex;
        line-height: 1.3;
        align-items: center;
      }

    }
  }

  &__header {
    background: @lightblue;
    color: @dark;
    font-weight: 400;
    padding: 10px 20px;
    height: 40px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    width: calc(~"100% - 54px");
    margin: 0 auto;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
    font-family: Verdana !important;

    &.likebis {
      width: 100%;
      background: none;
      box-shadow: none;
      padding-left: 0;
    }

    &.like {

      .picto-like {
        width: 30px;
        height: 30px;
        padding: 7px;
        margin-left: 10px;
        border-radius: 100px;
        background: @turquoise;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        @media @mobile {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translate(0, -50%);
        }

        .icon {
          color: @white;
          margin: 0;
          font-size: 18px;
        }
      }

      @media @mobile {
        justify-content: flex-start;
        padding-right: 30px;

        strong {
          display: block;
        }
      }
    }

    &.vaccin {
      background: @redpink;
      color: @white;
      display: flex;
      align-items: center;
      font-size: 16px;

      .icon {
        margin: 0 0 0 20px;
        width: 25px;
        min-width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        background: @white;
        color: @redpink;
        border-radius: 100px;
        font-size: 13px;
        justify-content: center;

        @media @mobile {
          margin: 0 0 0 7px;
          font-size: 10px;
          width: 20px;
          min-width: 20px;
          height: 20px;
        }
      }

      @media @mobile {
        font-size: 14px;
        padding: 5px;
      }
    }

    span, strong {
      font-family: Verdana !important;
    }

    .preconsult__result-cta & {
      font-size: 12px;
      width: calc(~"100% + 40px");
      border-radius: 0;
      margin: -20px -20px 20px -20px;

      @media @verysmallmobile {
        font-size: 10px;
      }
    }

    .picto-alert {
      border: 2px solid @blue;
      color: @white;
      background: @blue;
      width: 20px;
      min-width: 20px;
      height: 20px;
      font-size: 14px;
      line-height: 1;
      text-align: center;
      border-radius: 100px;
      font-weight: 700;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: Verdana !important;

    }

    strong {
      font-weight: 600;
    }

    .teleconsultation & {
      border-radius: 0;
      width: 100%;
    }

    @media @tablet {
      width: 100%;
      border-radius: 0;
    }

    &.white-bg {
      background: none;
      padding: 0;
      margin-bottom: 30px;
      color: @black;
      box-shadow: none;
      width: 100%;
    }

    @media @mobile {
      font-size: 10px;
      padding: 5px 15px;
      line-height: 1.3;
    }

    @media @verysmallmobile {
      font-size: 9px;
    }

    .icon {
      font-size: 25px;
      margin-right: 10px;
    }
  }

  &__content {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.3;
    padding: 15px 20px;
    border-bottom: 1px solid #efefef;
  }
}

.popin-delete-account {

  &__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
    color: @blue;
    margin: 0;
    padding-bottom: 10px;
  }

  &__desc {
    font-size: 14px;
    line-height: 1.4;
    font-weight: 400;
    margin: 0;
    padding-bottom: 30px;
  }

  &__cta {
    padding-top: 20px;

    .blue-cta {
      margin: 0 20px 10px 0;

      @media @mobile {
        width: 100%;
        margin: 0 0 20px 0;
      }
    }
  }
}

.sign-in {
  margin-top: 20px;

  &__link {
    text-align: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.3;
  }

  &__content {
    margin: 20px auto;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 100%;
    width: 500px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;

    &--pict {
      width: 100px;
      height: auto;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }

    &--text {
      font-size: 18px;
      font-weight: 500;
      padding-left: 20px;

      @media @mobile {
        font-size: 16px;
      }

      @media @verysmallmobile {
        font-size: 13px;
      }
    }
  }

  &__desc {
    list-style: none;
    padding: 20px;
    margin: 0;
    font-size: 16px;

    @media @mobile {
      padding: 20px 5px;
    }

    li {
      padding-left: 25px;
      position: relative;
      max-width: 450px;
      padding-bottom: 20px;
      line-height: 1.5;
      font-weight: 400;

      @media @mobile {
        font-size: 14px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        width: 8px;
        height: 8px;
        background: @green;
        border-radius: 100px;
        overflow: hidden;
      }
    }
  }

  &__cta {
    font-weight: 500;
    font-size: 16px;
    text-align: right;
  }
}