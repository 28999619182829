div.checker {
  input {
    opacity: 0;
    filter: alpha(opacity=0);
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 19px;
    height: 19px;
    background: 0 0;
    display: -moz-inline-box;
    display: inline-block;
    zoom: 1;
  }
  span {
    -webkit-font-smoothing: antialiased;
    width: 19px;
    height: 19px;
    display: -moz-inline-box;
    display: inline-block;
    zoom: 1;
    text-align: center;
    background-position: 0 -260px;
  }
  display: -moz-inline-box;
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  position: relative;
  width: 19px;
  height: 19px;
  margin-right: 5px;
  &:focus {
    outline: 0;
  }
  * {
    margin: 0;
    padding: 0;
  }
  span.checked {
    background-position: -76px -260px;
  }
}
div.radio {
  input {
    opacity: 0;
    filter: alpha(opacity=0);
    border: none;
    width: 18px;
    height: 18px;
    background: 0 0;
    display: -moz-inline-box;
    display: inline-block;
    zoom: 1;
    text-align: center;
  }
  span {
    -webkit-font-smoothing: antialiased;
    width: 18px;
    height: 18px;
    display: -moz-inline-box;
    display: inline-block;
    zoom: 1;
    text-align: center;
    background-position: 0 -279px;
  }
  display: -moz-inline-box;
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 3px;
  &:focus {
    outline: 0;
  }
  * {
    margin: 0;
    padding: 0;
  }
  span.checked {
    background-position: -72px -279px;
  }
}
div.selector {
  select {
    opacity: 0;
    filter: alpha(opacity=0);
    border: none;
    font-size: 12px;
    background: 0 0;
    position: absolute;
    height: 42px;
    top: 2px;
    left: 0;
    width: 100%;
  }
  -webkit-font-smoothing: antialiased;
  display: -moz-inline-box;
  display: block;
  zoom: 1;
  vertical-align: middle;
  margin: 0;
  overflow: hidden;
  height: 42px;
  line-height: 42px;
  width: 100% !important;
  position: relative;
  border: 1px solid rgba(35,41,57,0.3);
  border-radius: 4px;

  &:after{
    content: '';
    position: absolute;
    height: 8px;
    width: 8px;
    top: 44%;
    right: 20px;
    border-right: 1px solid @black;
    border-bottom: 1px solid @black;
    transform: rotate(45deg) translate(0, -50%);

  }

  span {
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    box-sizing: border-box;
    height: 42px;
    line-height: 42px;
    padding: 0 40px 0 20px;
    text-overflow: ellipsis;
    display: block;
    white-space: nowrap;
    background-position: right 0;
    cursor: pointer;
    color: @greyText;
    font-size: 13px;
    width: 100% !important;
    font-weight: 400;
    text-shadow: 0 1px 0 #fff;

    @media @mobile{
      font-size: 16px;
    }
  }
  &:focus {
    outline: 0;
  }
  * {
    margin: 0;
    padding: 0;
  }
}
div.uploader {
  input {
    opacity: 0;
    filter: alpha(opacity=0);
    border: none;
    background: 0 0;
    position: absolute;
    top: 0;
    right: 0;
    float: right;
    cursor: default;
    width: 100%;
    height: 100%;
    z-index: 100;
    cursor: pointer;
  }
  -webkit-font-smoothing: antialiased;
  display: -moz-inline-box;
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  position: relative;
  height: 28px;
  cursor: pointer;
  overflow: hidden;
  background-position: 0 -297px;
  span.action {
    -webkit-font-smoothing: antialiased;
    height: 28px;
    cursor: pointer;
    overflow: hidden;
    background-position: right -409px;
    white-space: nowrap;
    text-align: center;
    display: inline-block;
    font-size: 11px;
    background: @blue;
    color: @white;
    cursor: pointer;
    border-radius: 100px;
    padding: 10px 15px;
    font-weight: 400;
    line-height: 1;
    text-transform: uppercase;
    margin-left: 14px;
  }
  &:focus {
    outline: 0;
  }
  * {
    margin: 0;
    padding: 0;
  }
  span.filename {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    cursor: default;
    height: 24px;
    margin: 2px 0 2px 2px;
    line-height: 24px;
    width: 85px;
    padding: 0 10px;
    border-right: solid 1px #bbb;
    font-size: 11px;
  }
}
div.button {
  span {
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    display: -moz-inline-box;
    display: inline-block;
    zoom: 1;
    line-height: 22px;
    text-align: center;
    background-position: right -521px;
    height: 22px;
    margin-left: 13px;
    padding: 8px 15px 0 2px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  -webkit-font-smoothing: antialiased;
  display: -moz-inline-box;
  display: inline-block;
  zoom: 1;
  vertical-align: middle;
  margin: 0;
  padding: 0;
  background-position: 0 -641px;
  height: 30px;
  cursor: pointer;
  position: relative;
  &:focus {
    outline: 0;
  }
  * {
    margin: 0;
    padding: 0;
  }
  a {
    opacity: .01;
    filter: alpha(opacity=1);
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }
  button {
    opacity: .01;
    filter: alpha(opacity=1);
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }
  input {
    opacity: .01;
    filter: alpha(opacity=1);
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
  }
}
input.uniform-input {
  font-size: 12px;
  padding: 3px;
  outline: 0;
  font-weight: 400;
  border: 1px solid #aaa;
}
select.uniform-multiselect {
  font-size: 12px;
  padding: 3px;
  outline: 0;
  font-weight: 400;
  border: 1px solid #aaa;
  border-radius: 3px;
}
textarea.uniform {
  font-size: 12px;
  padding: 3px;
  outline: 0;
  font-weight: 400;
  border: 1px solid #aaa;
  border-radius: 3px;
}
.highContrastDetect {
  width: 0;
  height: 0;
}
input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:active {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}
div.selector.fixedWidth {
  width: 190px;
  span {
    width: 155px;
  }
}
div.selector.active {
  background-position: 0 -156px;
  span {
    background-position: right -26px;
  }
}
div.selector.focus {
  background-position: 0 -182px;
  span {
    background-position: right -52px;
  }
}
div.selector.hover {
  background-position: 0 -182px;
  span {
    background-position: right -52px;
  }
}
div.selector.focus.active {
  background-position: 0 -208px;
  span {
    background-position: right -78px;
  }
}
div.selector.hover.active {
  background-position: 0 -208px;
  span {
    background-position: right -78px;
  }
}
div.selector.disabled {
  background-position: 0 -234px;
  span {
    background-position: right -104px;
  }
}
div.selector.disabled.active {
  background-position: 0 -234px;
  span {
    background-position: right -104px;
  }
}
div.checker.active {
  span {
    background-position: -19px -260px;
  }
  span.checked {
    background-position: -95px -260px;
  }
}
div.checker.focus {
  span {
    background-position: -38px -260px;
  }
  span.checked {
    background-position: -114px -260px;
  }
}
div.checker.hover {
  span {
    background-position: -38px -260px;
  }
  span.checked {
    background-position: -114px -260px;
  }
}
div.checker.focus.active {
  span {
    background-position: -57px -260px;
  }
  span.checked {
    background-position: -133px -260px;
  }
}
div.checker.hover.active {
  span {
    background-position: -57px -260px;
  }
  span.checked {
    background-position: -133px -260px;
  }
}
div.checker.disabled {
  span {
    background-position: -152px -260px;
  }
  span.checked {
    background-position: -171px -260px;
  }
}
div.checker.disabled.active {
  span {
    background-position: -152px -260px;
  }
  span.checked {
    background-position: -171px -260px;
  }
}
div.radio.active {
  span {
    background-position: -18px -279px;
  }
  span.checked {
    background-position: -90px -279px;
  }
}
div.radio.focus {
  span {
    background-position: -36px -279px;
  }
  span.checked {
    background-position: -108px -279px;
  }
}
div.radio.hover {
  span {
    background-position: -36px -279px;
  }
  span.checked {
    background-position: -108px -279px;
  }
}
div.radio.focus.active {
  span {
    background-position: -54px -279px;
  }
  span.checked {
    background-position: -126px -279px;
  }
}
div.radio.hover.active {
  span {
    background-position: -54px -279px;
  }
  span.checked {
    background-position: -126px -279px;
  }
}
div.radio.disabled {
  span {
    background-position: -144px -279px;
  }
  span.checked {
    background-position: -162px -279px;
  }
}
div.radio.disabled.active {
  span {
    background-position: -144px -279px;
  }
  span.checked {
    background-position: -162px -279px;
  }
}
div.uploader.active {
  span.action {
    background-position: right -465px;
  }
}
div.uploader.focus {
  background-position: 0 -353px;
  span.action {
    background-position: right -437px;
  }
}
div.uploader.hover {
  background-position: 0 -353px;
  span.action {
    background-position: right -437px;
  }
}
div.uploader.focus.active {
  span.action {
    background-position: right -493px;
  }
}
div.uploader.hover.active {
  span.action {
    background-position: right -493px;
  }
}
div.uploader.disabled {
  background-position: 0 -325px;
  span.action {
    background-position: right -381px;
  }
  span.filename {
  }
}
div.uploader.disabled.active {
  background-position: 0 -325px;
  span.action {
    background-position: right -381px;
  }
  span.filename {
  }
}
div.button.active {
  background-position: 0 -671px;
  span {
    background-position: right -551px;
    cursor: default;
  }
}
div.button.focus {
  background-position: 0 -701px;
  span {
    background-position: right -581px;
  }
}
div.button.hover {
  background-position: 0 -701px;
  span {
    background-position: right -581px;
  }
}
div.button.disabled {
  background-position: 0 -731px;
  span {
    background-position: right -611px;
    cursor: default;
  }
}
div.button.disabled.active {
  background-position: 0 -731px;
  span {
    background-position: right -611px;
    cursor: default;
  }
}
input.uniform-input.focus {
  box-shadow: 0 0 4px rgba(0, 0, 0, .3);
}
input.uniform-input.hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, .3);
}
select.uniform-multiselect.focus {
  box-shadow: 0 0 4px rgba(0, 0, 0, .3);
}
select.uniform-multiselect.hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, .3);
}
textarea.uniform.focus {
  box-shadow: 0 0 4px rgba(0, 0, 0, .3);
}
textarea.uniform.hover {
  box-shadow: 0 0 4px rgba(0, 0, 0, .3);
}
