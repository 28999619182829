.admin{
  main{
    padding: 50px;

    @media @mobile {
      padding: 10px;
    }
  }
  .table-admin{
    width: 100%;
    margin-bottom: 20px;

    td{
      padding: 10px;
      font-size: 14px;
      word-break: break-word;

      &.name{
        background: #000;
        color: #fff;
      }
    }
  }

  button{
    display: inline-block;
    padding: 10px;
    font-weight: 700;
    font-size: 16px;
    cursor: pointer;
    margin: 0 40px 10px 0;

    &.valid-btn{
      background: green;
      color: #fff;
    }

    &.reject-btn{
      background: red;
      color: #fff;
    }
  }
}