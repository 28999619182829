.els-input {
  position: relative;
  margin: 10px;

  &.loader {
    pointer-events: none;

    &:after {
      left: auto;
      right: 20px;
    }
  }

  input[type="text"], input[type="tel"], input[type="email"], input[type="date"], input[type="number"], input[type="password"], input[type="datetime-local"] {
    height: 80px;
    line-height: 80px;
    padding: 0 20px;
    width: 100%;
    display: block;
    color: @greyText;
    border: 1px solid rgba(35, 41, 57, 0.1);
    border-radius: 4px;
    font-size: 26px;
    -webkit-appearance: none;
    -moz-appearance: none;
    transition: all 150ms linear;
    font-weight: 700;

    &:focus {
      border: 1px solid rgba(35, 41, 57, 1);
    }

    @media @mobile {
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }
  }
}