@font-face {
  font-family: 'icomoon';
  src: url('../../../assets/fonts/icomoon.woff2?e1koz2?version=1') format('woff2'), url('../../../assets/fonts/icomoon.woff?e1koz2?version=1') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.icon:before {
    font-family: 'icomoon' !important;
  }
}


.icon-arrow-up:before {
  content: "\e977";
}
.icon-activity:before {
  content: "\e970";
}
.icon-clock:before {
  content: "\e972";
}
.icon-thumbs-down:before {
  content: "\e975";
}
.icon-thumbs-up1:before {
  content: "\e976";
}
.icon-picto-like:before {
  content: "\e96f";
}
.icon-quote:before {
  content: "\e96e";
}
.icon-locked:before {
  content: "\e969";
}
.icon-circle-hole:before {
  content: "\e96a";
}
.icon-seat:before {
  content: "\e96b";
}
.icon-clock-speed:before {
  content: "\e96c";
}
.icon-feed:before {
  content: "\e967";
}
.icon-urgence:before {
  content: "\e968";
}
.icon-picto-bones-inter:before {
  content: "\e957";
}
.icon-picto-crane-douleur:before {
  content: "\e95a";
}
.icon-picto-crane-fever:before {
  content: "\e95b";
}
.icon-picto-crane-nause:before {
  content: "\e95c";
}
.icon-picto-crane-nez:before {
  content: "\e95d";
}
.icon-picto-crane-toux:before {
  content: "\e95e";
}
.icon-picto-crane-trouble:before {
  content: "\e95f";
}
.icon-picto-crane-zzz:before {
  content: "\e960";
}
.icon-picto-gorge-douleur:before {
  content: "\e961";
}
.icon-picto-hand-blood:before {
  content: "\e962";
}
.icon-picto-heart-low:before {
  content: "\e963";
}
.icon-picto-heart-rythm:before {
  content: "\e964";
}
.icon-picto-peacemaker:before {
  content: "\e965";
}
.icon-picto-poumons:before {
  content: "\e966";
}
.icon-partager:before {
  content: "\e94c";
}
.icon-iconepaiement:before {
  content: "\e94d";
}
.icon-accederfacilement:before {
  content: "\e94e";
}
.icon-armchair:before {
  content: "\e94f";
}
.icon-chronometer:before {
  content: "\e951";
}
.icon-icon:before {
  content: "\e955";
}
.icon-picto-praticien:before {
  content: "\e949";
}
.icon-picto-calendar-clock:before {
  content: "\e941";
}
.icon-picto-locked-doc:before {
  content: "\e942";
}
.icon-picto-post-consult:before {
  content: "\e948";
}
.icon-sitemap:before {
  content: "\e925";
}
.icon-archive:before {
  content: "\e926";
}
.icon-patch:before {
  content: "\e927";
}
.icon-pil:before {
  content: "\e928";
}
.icon-injection:before {
  content: "\e929";
}
.icon-paint:before {
  content: "\e92a";
}
.icon-profile:before {
  content: "\e92b";
}
.icon-addressbook:before {
  content: "\e92c";
}
.icon-addressbook1:before {
  content: "\e92d";
}
.icon-video-camera:before {
  content: "\e96d";
}
.icon-rdvligne:before {
  content: "\e93c";
}
.icon-teleconsultation:before {
  content: "\e93d";
}
.icon-telephone_circle:before {
  content: "\e93e";
}
.icon-measures:before {
  content: "\e93b";
}
.icon-recherchez:before {
  content: "\e931";
}
.icon-upload:before {
  content: "\e92e";
}
.icon-transmettre:before {
  content: "\e92f";
}
.icon-rendezvous:before {
  content: "\e930";
}
.icon-mestraitements:before {
  content: "\e932";
}
.icon-mesmotifs:before {
  content: "\e933";
}
.icon-accedez:before {
  content: "\e934";
}
.icon-espace-patient:before {
  content: "\e935";
}
.icon-espace-praticien:before {
  content: "\e936";
}
.icon-faq:before {
  content: "\e937";
}
.icon-mesantecedents:before {
  content: "\e938";
}
.icon-camera:before {
  content: "\e939";
}
.icon-headphones:before {
  content: "\e93a";
}
.icon-calculator:before {
  content: "\e940";
}
.icon-phone-hang-up:before {
  content: "\e943";
}
.icon-address-book:before {
  content: "\e944";
}
.icon-pushpin:before {
  content: "\e946";
}
.icon-location:before {
  content: "\e947";
}
.icon-compass2:before {
  content: "\e94a";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-cog:before {
  content: "\e994";
}
.icon-office:before {
  content: "\e91e";
}
.icon-newspaper:before {
  content: "\e91d";
}
.icon-book:before {
  content: "\e91f";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-user:before {
  content: "\e971";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-table2:before {
  content: "\ea71";
}
.icon-file-excel:before {
  content: "\eae2";
}
.icon-libreoffice:before {
  content: "\eae3";
}
.icon-loop2:before {
  content: "\ea2e";
}
.icon-credit-card:before {
  content: "\e93f";
}
.icon-pencil:before {
  content: "\e923";
}
.icon-video-camera1:before {
  content: "\e922";
}
.icon-envelop:before {
  content: "\e945";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-stopwatch:before {
  content: "\e952";
}
.icon-mobile2:before {
  content: "\e959";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-volume-high:before {
  content: "\ea26";
}
.icon-volume-medium:before {
  content: "\ea27";
}
.icon-volume-low:before {
  content: "\ea28";
}
.icon-volume-mute:before {
  content: "\ea29";
}
.icon-eye:before {
  content: "\e920";
}
.icon-no-eye:before {
  content: "\e921";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-file-word:before {
  content: "\eae1";
}
.icon-file-openoffice:before {
  content: "\eae0";
}
.icon-file-pdf:before {
  content: "\eadf";
}
.icon-printer:before {
  content: "\e954";
}
.icon-display:before {
  content: "\e956";
}
.icon-mobile:before {
  content: "\e958";
}
.icon-volume-mute2:before {
  content: "\ea2a";
}
.icon-volume-increase:before {
  content: "\ea2b";
}
.icon-volume-decrease:before {
  content: "\ea2c";
}
.icon-icon-mail:before {
  content: "\e913";
}
.icon-icon-vaccins:before {
  content: "\e914";
}
.icon-icon-medical:before {
  content: "\e915";
}
.icon-icon-infos:before {
  content: "\e916";
}
.icon-icon-position:before {
  content: "\e905";
}
.icon-icon-loupe:before {
  content: "\e900";
}
.icon-icon-medoc:before {
  content: "\e901";
}
.icon-icon-patient:before {
  content: "\e902";
}
.icon-icon-phone:before {
  content: "\e903";
}
.icon-icon-poi:before {
  content: "\e904";
}
.icon-mesures:before {
  content: "\e917";
}
.icon-icon-exams:before {
  content: "\e918";
}
.icon-thumbs-up:before {
  content: "\e924";
}
.icon-icon-link:before {
  content: "\e91c";
}
.icon-exit:before {
  content: "\e91b";
}
.icon-icon-feutre:before {
  content: "\e91a";
}
.icon-icon-cross:before {
  content: "\e919";
}
.icon-icon-medecin:before {
  content: "\e906";
}
.icon-icon-allergie:before {
  content: "\e907";
}
.icon-icon-board:before {
  content: "\e908";
}
.icon-icon-comment:before {
  content: "\e909";
}
.icon-icon-facebook:before {
  content: "\e90a";
}
.icon-icon-instagram:before {
  content: "\e90b";
}
.icon-icon-radio:before {
  content: "\e90c";
}
.icon-icon-rdz:before {
  content: "\e90d";
}
.icon-icon-shopping:before {
  content: "\e90e";
}
.icon-icon-star:before {
  content: "\e90f";
}
.icon-icon-twitter:before {
  content: "\e910";
}
.icon-icon-vaccin:before {
  content: "\e911";
}
.icon-icon-youtube:before {
  content: "\e912";
}
.icon-mic:before {
  content: "\e94b";
}
