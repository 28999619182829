.medecin-profil .footer {
  display: block;
}

.footer {
  padding-bottom: 20px;
  background: @lightgrey;
  clear: both;
  font-family: Verdana !important;

  &__contact-us {
    text-align: center;
    padding-bottom: 60px;

    &--title {
      font-size: 24px;
      font-weight: 500;
      padding: 40px 10px;
      font-family: Verdana !important;
    }
  }

  &__socials {
    text-align: center;
    background: @white;
    padding: 20px 35px;
    display: block !important;
    visibility: visible!important;

    @media @mobile {
      padding: 20px;
    }

    &--title {
      font-size: 14px;
      font-weight: 300;
      text-transform: uppercase;
      line-height: 1.5;
      font-family: Verdana !important;

      @media @mobile {
        font-size: 12px;
      }
    }

    &--list {
      text-align: center;
      padding: 20px 0 0;

      a {
        display: inline-block;
        vertical-align: middle;
        width: 30px;
        text-align: center;
        height: 30px;
        line-height: 30px;
        background: @dark;
        border-radius: 100px;
        margin: 0 5px;

        &.blue {
          background: @blue;
        }

        i {
          font-size: 14px;
          color: @white;
          vertical-align: middle;

          &.smaller {
            font-size: 10px;
          }
        }
      }
    }
  }

  &__sitemap {
    text-align: left;
    max-width: 1150px;
    margin: 0 auto;
    padding: 50px 10px;
    display: flex !important;
    visibility: visible!important;
    flex-wrap: wrap;

    @media @mobile {
      padding: 20px 10px;
    }

    .connectedPage & {
      padding-left: 90px;

      @media @tablet {
        padding-left: 0;
      }
    }

    &--list {
      width: calc(~"100%/3");
      vertical-align: top;
      padding: 10px;
      max-width: 100%;
      display: block !important;

      @media @mobile {
        width: 100%;
      }

      &.double {
        width: calc(~"100%/3*2");

        @media @mobile {
          width: 100%;
        }

        .footer__sitemap--urls li {
          width: 50%;

          @media @mobile {
            width: 100%;
          }
        }
      }

      &.fullwidth {
        width: 100%;
      }
    }

    &--title {
      font-size: 18px;
      font-weight: 500;
      padding-bottom: 20px;
      font-family: Verdana !important;

      @media @mobile {
        font-size: 18px;
      }
    }

    &--urls {
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      &.half {
        width: 50%;

        li {
          width: 100% !important;

          @media @mobile {
            width: 100%;
          }
        }

        @media @mobile {
          width: 100%;
        }
      }

      li {
        display: block;
        padding-bottom: 10px;
        width: 100%;
        padding-right: 10px;
        font-family: Verdana !important;

        &.footer__sitemap--letter {
          display: inline-block;
          vertical-align: middle;
          margin: 5px;
          padding: 0;
          width: auto;

          a {
            padding: 0 40px 35px 0;
            display: inline-block;
          }
        }

        a {
          color: @blue;
          font-size: 12px;
          line-height: 1.3;
          font-family: Verdana !important;
        }
      }
    }
  }

  &__links {
    max-width: 1210px;
    margin: 0 auto;
    text-align: center;
    font-size: 12px;
    color: @dark;
    font-weight: 300;
    line-height: 2;
    padding: 30px;
    border-top: 1px solid @lightgrey2;
    font-family: Verdana !important;

    @media @mobile {
      padding: 30px 10px;
    }

    a {
      color: @dark;
      font-family: Verdana !important;

      @media @desktoponly {
        &:hover {
          color: @grey;
        }

      }

    }
  }
}