* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  outline: none;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent !important;
  font-family: @main-font;
}

html {
  color: @black;
  background: @white;
}

input[type="text"], input[type="tel"], input[type="email"], input[type="submit"], input[type="text"], input[type="number"], textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  box-shadow: none;

  &.datepicker, &.datepickerfilter {
    background: url('/assets/images/datepicker.svg') 97% 50% no-repeat;
    background-size: auto 25px;

    &.smaller {
      background-size: auto 15px;
    }
  }

  &.unselectable {
    background: none !important;
    pointer-events: none;
    border: 0 !important;
  }
}

select, input {
  color: @black;
  background: @white;
}

input[type="submit"] {
  border: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  border: 0
}

select::-ms-expand {
  display: none;
}

body,
html {
  margin: 0;
  overflow-x: hidden;
}

.desktop-only {

  @media @mobile {
    display: none !important

  }

  @media @mobilelandscape {
    display: none !important
  }
}

.hiddenEmpty {
  &:empty {
    display: none;
  }
}

hr {
  margin-top: 18px;
  margin-bottom: 18px;
  border: 0;
  border-top: 1px solid @lightgrey5;
  box-sizing: content-box;
  height: 0;
}

body {
  font-family: @main-font;
  font-size: 13px;

  &.has-popin {
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: absolute;

    .header {
      z-index: 1000;
    }
  }

  &.hasBookingProcess {
    .section__content, .section__aside {
      display: none;
    }
  }

  #content.no-header {
    margin-top: 110px;

    @media @mobile {
      margin-top: 95px;
    }
  }

  .hidden-label {
    font-size: 0;
    padding: 0;
    margin: 0;
    height: 0;
    display: block;
  }

  .error {
    color: @red !important;
    border-color: @red !important;
  }

  .info-loader {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 20000;
    width: 100%;
    margin-top: 60px;
    display: none;

    &__img {
      text-align: center;

      img {
        display: block;
        margin: 0 auto;
        width: 150px;
        height: auto;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 1.5;
      padding: 20px;
      color: @blue;
      text-align: center;
      margin: 0 auto;
      max-width: 770px;
      font-weight: 500;
    }
  }

  &.loader {
    overflow: hidden;
    pointer-events: none;

    .info-loader {
      display: block;
    }
  }

  &.loader, &.loaderPayment {
    height: 100%;
    width: 100%;
    position: fixed;
    transition: all 100ms linear;
    pointer-events: none;

    &:before {
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 1);
      top: 0;
      left: 0;
      z-index: 20000;
    }

    &:after {
      z-index: 20000;
      width: 60px;
      height: 60px;
      border: 6px solid #f6f6f6;
      border-top: 6px solid @green;
      margin: -30px 0 0 -30px;
    }
  }

  .loading {
    transition: all 100ms linear;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      top: 0;
      left: 0;
      z-index: 100;
    }

    span {
      opacity: 1;
    }

    &:after {
      z-index: 101;
    }
  }

  .loadingForStripe {
    transition: all 100ms linear;
    pointer-events: none;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.7);
      top: 0;
      left: 0;
      z-index: 1000;
    }

    &:after {
      content: '';
      position: absolute;
      border: 6px solid rgba(0, 0, 0, 0.1);
      border-top: 6px solid @green;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      box-sizing: border-box;
      top: 50%;
      left: 50%;
      animation: spin 2s linear infinite;
      margin: -15px 0 0 -15px;
      z-index: 1001;
    }
  }

  &.loaderPayment {
    &:before {
      content: 'Paiement en cours de traitement';
      text-transform: uppercase;
      text-align: center;
      line-height: 80vh;
      background: rgba(255, 255, 255, 0.8);
      color: @black;
      font-size: 14px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

button {
  border: 0;
  background: none;
  cursor: pointer;
}

p {
  margin: 0;
}

b {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: @blue;

  @media @desktoponly {

    &:hover {
      text-decoration: none;
    }
  }

}

.inline-green-line {
  background-image: linear-gradient(to right, @green 100%, transparent 100%);
  background-position: 0 1.1em;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  display: inline;
  padding: 0 !important;
  vertical-align: initial !important;
}

main {
  background: @lightgrey;
  padding-top: 60px;
  min-height: 100vh;

  @media @tablet {
    .mobile-header-visible & {
      display: none;
    }
  }

  &.no-padding {
    padding: 0;
  }
}

.soft-hidden {
  display: none;
}

.hidden {
  display: none !important;
}

/* -- NEW AUTOCOMPLETE / START -- */

.multiple-col-autocomplete {

  .ui-autocomplete {

    &.ui-widget-content {
      border: 0 !important;
      border-top: 1px solid @mediumgrey !important;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
      background: @white;
      z-index: 100000;
      max-height: 60vh;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      list-style: none;
      padding: 20px !important;
      display: flex;
      flex-wrap: wrap;
      max-width: 100%;

      @media @mobile {
        max-width: calc(~"100% - 21px");
        padding: 20px 10px !important;
      }
    }

    & > li {
      padding: 10px 20px 10px 57px !important;
      list-style: none;

      a {
        color: @black !important;
      }
    }

    & > li.ui-state-focus {
      background-color: @blue !important;
      color: @white !important;

      strong {
        color: @white !important;
      }
    }

    & a.ui-state-focus {
      background: none !important;
      color: @black !important;

      strong {
        color: @white !important;
      }
    }

    &__wrapper {
      width: 50%;
      padding: 0 10px;

      &.pathos {
        width: 100%;
      }

      @media @mobile {
        width: 100%;
        margin-bottom: 30px;
      }
    }

    .ui-autocomplete-header {
      font-size: 14px;
      margin-top: 10px !important;
      margin-bottom: 20px !important;
      font-weight: 400;
      border-bottom: 1px solid @lightgrey2;
      padding-bottom: 10px;
      border-top: 1px solid @lightgrey2;
      color: @black;

      > div {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 10px;
          color: @green;
          font-size: 20px;
        }
      }

      &:first-child {
        margin-top: 0 !important;
        border-top: 0;
      }
    }
  }

  .ui-helper-hidden-accessible {
    display: none;
  }

  .ui-menu-item {
    font-size: 12px;
    padding-bottom: 10px;
    position: relative;
    cursor: pointer;
    padding-left: 30px;

    &:before {
      content: '';
      position: absolute;
      top: 7px;
      left: 8px;
      transform: rotate(45deg);
      width: 6px;
      height: 6px;
      border-top: 1px solid @green;
      border-right: 1px solid @green;
    }

    strong {
      color: @blue;
    }
  }
}

/* -- NEW AUTOCOMPLETE / END -- */

.ui-autocomplete.ui-widget-content {
  border: 0 !important;
  border-top: 1px solid @mediumgrey !important;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1) !important;
  background: @white;
  z-index: 100000;
  max-width: calc(~"50% - 30px");
  max-height: 60vh;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media @mobile {
    max-width: calc(~"100% - 40px");
  }
}

.ui-autocomplete > li {
  padding: 10px 20px 10px 57px !important;
  list-style: none;

  a {
    color: @black !important;
  }
}

.ui-autocomplete > li.ui-state-focus {
  background-color: @blue !important;
  color: @white !important;

  strong {
    color: @white !important;
  }
}

.ui-autocomplete a.ui-state-focus {
  background: none !important;
  color: @black !important;

  strong {
    color: @white !important;
  }
}

.ui-helper-hidden-accessible {
  display: none;
}

.ui-menu-item {
  font-size: 14px;

  strong {
    color: @blue;
  }
}

.ui-autocomplete .ui-autocomplete-header {
  font-size: 17px;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-transform: uppercase;
  padding-top: 10px !important;
  font-weight: 700;
  border-bottom: 1px solid @lightgrey2;
  border-top: 1px solid @lightgrey2;
  color: @blue;

  &:first-child {
    margin-top: 0 !important;
    border-top: 0;
  }
}

.bullet-picture {
  width: 74px;
  position: relative;

  &.blue {
    width: 88px;
  }

  &__img {
    display: block;
    width: 74px;
    height: 74px;
    border-radius: 500px;
    overflow: hidden;
    background: @white;

    .blue & {
      width: 88px;
      height: 88px;
    }

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    .blue & {
      border: 7px solid @blue;
    }
  }

  &__stars {
    background: @green;
    color: @white;
    font-size: 12px;
    display: block;
    position: absolute;
    bottom: -16px;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, 0);
    line-height: 20px;
    padding: 0 10px;
    border-radius: 100px;
    border: 2px solid @white;
    white-space: nowrap;

    .blue & {
      background: @blue;
      border-color: @blue;
      bottom: -6px;
    }

    > * {
      display: inline-block;
      vertical-align: baseline;
    }
  }
}

.btn-chevron {
  display: inline-block;
  position: relative;
  height: 30px;
  width: 40px;
  line-height: 30px;
  border: 0;
  color: transparent;
  background: none;

  &.disabled {
    pointer-events: none;
    opacity: 0.1;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    height: 10px;
    width: 10px;
    border-top: 2px solid @black;
    border-right: 2px solid @black;
  }

  &.left {
    &:after {
      transform: translate(-50%, -50%) rotate(-135deg);
    }
  }

  &.right {
    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.roundborder-cta {
  background: @white;
  color: @blue;
  display: inline-block;
  border-radius: 40px;
  height: 22px;
  line-height: 20px;
  padding: 0 10px;
  font-size: 0;
  font-weight: 500;
  position: relative;
  text-align: center;
  vertical-align: middle;
  border: 1px solid @blue;

  span {
    font-size: 12px;
  }

  .icon {
    font-size: 10px;
    line-height: 1;
    vertical-align: middle;
    margin-right: 4px;
    display: inline-block;
  }

}

.big-picto-cta {

  text-align: center;
  background: none;
  margin: 10px;

  &.cta-avis {
    display: none;

    @media @mobile {
      display: flex;
      margin: 5px 0 0;
      font-size: 12px;

      span {
        text-align: left;
        padding-left: 10px;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }

  @media @tablet {
    display: flex;
    align-items: center;
    margin: 0;
  }

  .icon {
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 38px;
    border-radius: 100px;
    border: 1px solid @blue;
    color: @blue;
    font-size: 18px;

    @media @tablet {
      border: 0;
      width: auto;
      font-size: 12px;
    }

    @media @mobile {
      height: auto;
      line-height: 1;
    }
  }

  span {
    display: inline-block;
    font-size: 12px;
    padding-top: 10px;
    width: 100%;
    color: @blue;
    font-weight: 400;
    line-height: 1.3;

    @media @tablet {
      padding: 0 0 0 5px;
      font-size: 11px;
    }
  }
}

.cta-flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.blue-cta {
  background: @blue;
  color: @white;
  display: inline-block;
  border-radius: 4px;
  height: 42px;
  line-height: 42px;
  padding: 0 20px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  text-align: center;
  vertical-align: middle;

  &.with-icon {
    display: flex;
    align-items: center;
    padding: 0 10px;
  }

  &.multiple-line-inline {
    padding: 13px 20px;
    line-height: 1.3;
    height: auto;
  }

  &.multiple-line {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    text-transform: none;
    line-height: 48px;
    height: 48px;
    min-width: 250px;

    &.width-smaller {
      min-width: 140px;
    }

    > span {
      line-height: 1.3;
      font-weight: 500;
      font-size: 14px;
      color: @white;

      .inverted& {
        color: @blue;
      }

      small {
        font-weight: 400;
        font-size: 13px;
        text-transform: none;
        letter-spacing: -0.02em;

        @media @mobile {
          font-size: 12px;
        }
      }
    }

    &.with-picto {
      text-align: left;
      display: flex;
      justify-content: left;
      position: relative;
      padding-right: 20px;

      &.centered {
        justify-content: center;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        right: 15px;
        width: 10px;
        height: 10px;
        border-top: 2px solid @white;
        border-right: 2px solid @white;
        transform: translate(0, -50%) rotate(45deg);

        .inverted& {
          border-top: 2px solid @blue;
          border-right: 2px solid @blue;
        }

        .yellow& {
          border-top: 2px solid @black;
          border-right: 2px solid @black;
        }
      }

      .icon {
        font-size: 20px;
        margin: 0 10px 0 5px;
      }

      > span {

      }
    }
  }

  &.with-chevron {
    padding-right: 40px;
    text-align: left;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 15px;
      width: 10px;
      height: 10px;
      border-top: 2px solid @white;
      border-right: 2px solid @white;
      transform: translate(0, -50%) rotate(45deg);
    }
  }

  &.loader {
    pointer-events: none;
    color: transparent;
    pointer-events: none;
  }

  .icon {
    margin-right: 10px;

    &.icon-location {
      font-size: 18px;
    }

    & + span {
      max-width: calc(~"100% - 40px");
    }
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  &.bigger {
    font-size: 16px;
  }

  &.bit-bigger {
    font-size: 14px;
    font-weight: 500;
  }

  &.more-bigger {
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    padding: 0 30px;
    font-weight: 700;
    text-transform: none;
  }

  &.lower {
    font-size: 14px;
    font-weight: 500;
    text-transform: none;
  }

  &.inverted {
    color: @blue;
    background: @white;
    border: 1px solid @blue;
  }

  &.inverted-grey {
    color: @dargrey;
    background: @white;
    border: 1px solid @mediumgrey;
  }

  &.inverted-no-border {
    border: 0;
    color: @blue;
    background: none;
  }

  &.inverted-lower {
    color: @blue;
    text-transform: none;
    background: @white;
    border: 1px solid @blue;
    padding: 0 10px;
    font-size: 14px !important;

    &.with-margin {
      margin: 0 20px 10px 0;

      @media @mobile {
        margin-bottom: 20px;
        width: 100%;
      }
    }
  }

  &.green {
    background: @green;
    font-weight: 500;
  }

  &.orange3 {
    background: @porange3;
    font-weight: 500;
  }

  &.inverted-green {
    background: @white;
    font-weight: 500;
    color: @green;
  }

  &.inverted-antracite {
    background: @white;
    font-weight: 500;
    color: @antracite;
  }

  &.inverted-green-border {
    color: @green;
    background: @white;
    border: 1px solid @green;
  }

  &.inverted-grey-border {
    color: rgba(0, 0, 0, 0.5);
    background: rgba(0, 0, 0, 0.05);
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  &.red {
    background: @red;
    font-weight: 500;
  }

  &.yellow {
    background: @yellow;
    font-weight: 500;
    color: @black;

    span, i {
      color: @black;
    }

    &:before {
      border-color: @black;
    }
  }

  &.yellow-pj {
    background: #ffeb00;
    font-weight: 500;
    //box-shadow: 0 2px 0 #ffeb00;
    color: #000;
  }

  &.purple {
    background: @purple;
    font-weight: 500;
    //box-shadow: 0 2px 0 @purple;
  }

  &.dark {
    background: @antracite;
    font-weight: 500;
    //box-shadow: 0 2px 0 @black;
    color: @white;
  }

  &.grey {
    background: @lightgrey;
    font-weight: 500;
    //box-shadow: 0 2px 0 @grey;
    color: @black;
  }

  &.small {
    font-size: 11px;
    height: 30px;
    line-height: 30px;

    i + span {
      max-width: calc(~"100% - 20px");
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.addMargin {
    margin: 20px 0;
  }

  &.wide {
    min-width: 300px;

    @media @mobile {
      min-width: 200px;
    }
  }

  &.larger {
    min-width: 150px;

    @media @mobile {
      min-width: 150px;
    }
  }

  &.full-width {
    width: 100%;
  }

  .separator {
    display: inline-block;
    height: 16px;
    width: 1px;
    background: @white;
    opacity: 0.2;
    margin: 0 10px;
  }

  &.arrow-top:after {
    content: '';
    position: absolute;
    top: -7px;
    border-bottom: 8px solid @blue;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    left: 50%;
    transform: translate(-50%, 0);
  }

  > * {
    display: inline-block;
    vertical-align: middle;
  }
}

.search-form-wrapper {
  .mobile-header-visible & {
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 190px;
    width: 100%;
    display: block !important;

    .section__search-wrapper {
      display: block !important;
    }
  }

  .mobile-header-visible.medecin-profil & {
    top: 130px;

    @media @mobile {
      top: 190px;
    }
  }
}

.mobile-header-visible .popin-med__super-wrapper {
  z-index: 20000 !important;
}

.section {
  &__title {
    font-size: 28px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: normal;

    @media @mobile {
      font-size: 25px;
    }

    small {
      font-size: 65%;
      font-weight: 400;
      opacity: 0.5;
    }

    &--number {
      display: block;
      font-weight: 700;
      font-size: 30px;
      opacity: 0.2;
      padding-bottom: 3px;

      &.title {
        opacity: 0.5;
        font-size: 38px;
        padding-bottom: 5px;

        @media @mobile {
          font-size: 30px;
        }
      }
    }
  }

  &__desc {
    font-size: 16px;
    line-height: 1.3;
    font-weight: 400;
    text-align: center;
    width: 100%;
    @media @mobile {
      font-size: 14px;
    }
  }

  &__search-wrapper {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
    height: 60px;
    border: 1px solid rgba(0, 0, 0, 0.1);

    @media @mobile {
      box-shadow: none;
    }

    .medecin-profil & {
      position: fixed;
      top: 60px;
      z-index: 200;

      @media @mobile {
        position: relative;
        top: 0;
      }

      .icon-input:before {
        font-family: icomoon !important;
      }
    }

    &.fixed {
      position: fixed;
      width: 100%;
      z-index: 200;
      bottom: auto;
    }
  }

  &__medecin, &__place {
    width: calc(~"50% - 30px");
    float: left;
    position: relative;
    overflow: hidden;

    @media @mobile {
      width: calc(~"100% - 20px");
      margin-left: 10px;
    }

    .icon-input {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translate(0, -50%);
      font-size: 20px;
      color: @blue;
    }

    input {
      font-size: 14px;
      width: 100%;
      display: block;
      height: 60px;
      padding: 0 20px 0 60px;
      border: 0;
      text-overflow: ellipsis;

      @media @mobile {
        font-size: 16px;
      }

      @media @verysmallmobile {
        font-size: 11px;
      }
    }
  }

  &__medecin {
    width: calc(~"100% - 120px");

    &.no-submit {
      width: calc(~"100% - 60px");

      .icon-input {
        top: 0;
        left: 0;
        transform: none;
        width: 58px;
        text-align: center;
        line-height: 58px;
        height: 58px;
        background: @antracite;
        color: @white;
      }

      input[type="text"] {
        padding-left: 80px;
      }

      @media @mobile {
        border: 0;
        border-radius: 0;
        margin: 0;
        width: calc(~"100% - 60px");
      }
    }

    @media @mobile {
      border: 0;
      border-radius: 0;

      width: calc(~"100% - 20px");
    }
  }

  &__place {

    display: none;

    @media @mobile {
      border-radius: 0 0 10px 10px;
    }
  }

  &__submit {
    float: left;
    width: 120px;
    height: 60px;
    line-height: 60px;
    color: @white;
    text-align: center;
    font-size: 0;

    &.no-submit {
      width: 60px;

      /*@media @mobile {
        width: calc(~"100% - 20px");
        margin: 12px 10px 0;

      }*/
    }

    /*@media @mobile {
      width: calc(~"100% - 20px");
      margin: 12px 10px 0;

    }*/

    button {
      width: 60px;
      height: 60px;
      background: none;
      cursor: pointer;

      &.geoloc {
        background: @white;

        .icon {
          color: rgba(0, 0, 0, 0.2);
          font-size: 26px;
        }
      }

      &.search {
        background: @green;
      }

      span {
        height: 0;
        display: block;
        overflow: hidden;

        /*@media @mobile {
          height: auto;

        }*/
      }

      /*@media @mobile {
        width: 100%;
        text-align: center;

        span {
          display: inline-block;
          color: @white;
          vertical-align: middle;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 12px;
          padding-left: 10px;
        }

      }*/

      i {
        color: @white;
        font-size: 20px;
        vertical-align: middle;
      }
    }
  }

  &__medecin-push {
    background: @white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
    border-radius: 20px;
    padding: 30px 50px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    width: 770px;
    max-width: 90%;
    font-size: 0;

    @media @mobile {
      padding: 30px 20px;
      position: relative;
      transform: translate(0, 0);
      left: 0;
      max-width: 100%;
      bottom: -100px;

    }

    &--pict {
      width: 90px;
      height: 90px;
      border-radius: 200px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;

      @media @mobile {
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: auto;

        @media @mobile {
          width: 90px;
          margin: 0 auto;
          border-radius: 999px;
        }
      }
    }

    &--infos-wrapper {
      display: inline-block;
      vertical-align: middle;
      padding-left: 50px;
      width: calc(~"100% - 100px");

      @media @mobile {
        width: 100%;
        padding: 30px 0 0 0;
      }
    }

    &--name {
      font-size: 20px;
    }

    &--spe {
      font-size: 14px;
      display: inline-block;
      vertical-align: middle;
      font-weight: 400;
    }

    &--line2, &--line3 {
      display: block;
      padding-top: 5px;
    }

    &--stars {
      background: @antracite;
      color: @white;
      font-size: 12px;
      line-height: 20px;
      padding: 0 10px;
      border-radius: 100px;
      border: 2px solid @white;
      white-space: nowrap;
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      margin-left: 10px;

      .note {
        color: @white;
        font-weight: 500;
      }

      .icon-icon-star {
        font-size: 8px;
        display: inline-block;
        color: rgba(0, 0, 0, 0.2);
        margin: 0 2px;
        color: @white;
      }
    }

    &--line3 {
      color: @blue;
      font-weight: 400;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0 0 10px;
    font-size: 16px;

    &.no-cab {
      margin-bottom: 20px;

      @media @mobile {
        margin: 0 0 25px;
      }

      li {
        font-weight: 700;

        &.warning {
          font-weight: 400;
          &:after {
            background: @red;
          }
        }
      }
    }

    li {
      padding-left: 25px;
      position: relative;
      max-width: 450px;
      padding-bottom: 15px;
      line-height: 1.5;
      font-weight: 400;

      @media @mobile {
        font-size: 14px;
        padding-bottom: 10px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 7px;
        width: 8px;
        height: 8px;
        background: @green;
        border-radius: 100px;
        overflow: hidden;
      }
    }
  }
}

.dossier-wrapper {
  width: 330px;
  padding: 30px;
  background: @white;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);

  @media @mobile {
    padding: 30px 10px;
  }

  &__guy {
    width: 103px;
    position: absolute;
    left: -80px;
    bottom: -10px;
    z-index: 10;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__pict {
    width: 70px;
    height: 70px;
    position: absolute;
    left: 50%;
    top: -35px;
    transform: translate(-50%, 0);
    overflow: hidden;
    border-radius: 100px;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__title {
    padding: 25px 0 0 0;
    font-size: 12px;
    text-align: center;
    font-weight: 500;

    strong {
      font-weight: 500;
      color: @blue;
    }
  }

  &__list-param {
    background: #FFFFFF;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.10);
    border-radius: 10px;
    margin-top: 36px;
    overflow: hidden;
    font-size: 0;
  }

  &__item {
    width: 90px;
    height: 80px;
    display: inline-block;
    position: relative;
    vertical-align: top;
    border-right: 1px solid @lightgrey2;
    border-bottom: 1px solid @lightgrey2;

    @media @mobile {
      width: calc(100% / 3);
    }

    &:nth-child(3) {
      border-right: 0;
      border-bottom: 1px solid @lightgrey2;
    }

    &:nth-child(4) {
      border-right: 1px solid @lightgrey2;
      border-bottom: 0;
    }

    &:nth-child(5) {
      border-right: 1px solid @lightgrey2;
      border-bottom: 0;
    }

    &:nth-child(6) {
      border-right: 0;
      border-bottom: 0;
    }

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 100px;
      background: @lightgrey2;
      top: 6px;
      right: 6px;
    }

    &.selected {
      &:after {
        background: @blue;
        border: 2px solid @white;
        width: 6px;
        height: 6px;
      }

      .dossier-wrapper__item--inner {
        background: @dark;

        .dossier-wrapper__label {
          color: @white;
        }
      }
    }

    &--inner {
      width: 100%;
      height: 80px;
      text-align: center;

      i {
        color: @blue;
        font-size: 16px;
        margin: 19px 0 10px;
        display: inline-block;
      }
    }
  }

  &__label {
    font-weight: 500;
    font-size: 12px;
    padding: 0 10px;
    text-align: center;
    line-height: 1.3;

    @media @mobile {
      font-size: 10px;
    }
  }

  &__cta {
    text-align: center;
    margin-top: 30px;
    white-space: nowrap;

    .blue-cta {
      padding: 0 10px;
      width: 100%;
      vertical-align: middle;
    }
  }
}

.back-btn {
  position: absolute;
  color: @blue;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 300;
  line-height: 1;
  padding: 4px 0 0 25px;
  top: 85px;

  &.top {
    top: 80px;
    left: 20px;
    z-index: 100;
    font-weight: 500;
  }

  &.vaccin {
    @media @tablet {
      position: relative;
      top: 0;
      margin: 10px 0;
    }
  }

  &.telecons {
    font-weight: 500;
    text-transform: none;
    padding-right: 20px;

    @media @mobile {
      position: relative;
      top: 0;
      margin-bottom: 10px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: rotate(45deg) translate(0, -50%);
    height: 7px;
    width: 7px;
    border-left: 2px solid @blue;
    border-bottom: 2px solid @blue;
  }
}

.bullet-select {
  opacity: 1;
  background: @blue;
}

.popin-med {

  &__samu {
    font-size: 12px;
    margin-top: 30px;

    @media @mobile {
      font-size: 10px;

    }

    @media @verysmallmobile {
      margin-bottom: 20px;
    }
  }

  &__flex {
    display: flex;
    align-items: center;

    .icon {
      margin: 0 10px 2px 0;
      color: @blue;
      font-size: 20px;
    }
  }

  &__mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.4);
    display: none;
  }

  &__reverse {
    @media @mobile {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;

      .section__cta {
        margin-bottom: 40px;
      }
    }
  }

  &__step-bullet {
    width: 14px;
    height: 14px;
    background: @white;
    border: 2px solid @blue;
    border-radius: 20px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.4;
    position: relative;
    margin: 0 13px;

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 2px;
      border-radius: 2px;
      left: -20px;
      top: 50%;
      background: @blue;
      display: block;
      transform: translate(0, -50%);
    }

    &:first-child {
      &:after {
        display: none;
      }
    }
  }

  &__step-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #dddddd;
    background: @white;
    text-align: center;
    font-size: 0;
    line-height: 60px;
    display: none;

    &[data-step="1"] {
      .popin-med {
        &__step-bullet:nth-child(1) {
          .bullet-select;
        }
      }
    }

    &[data-step="2"] {
      .popin-med {
        &__step-bullet:nth-child(n+1):nth-child(-n+2) {
          .bullet-select;
        }
      }
    }

    &[data-step="3"] {
      .popin-med {
        &__step-bullet:nth-child(n+1):nth-child(-n+3) {
          .bullet-select;
        }
      }
    }

    &[data-step="4"] {
      .popin-med {
        &__step-bullet:nth-child(n+1):nth-child(-n+4) {
          .bullet-select;
        }
      }
    }

    &[data-step="5"] {
      .popin-med {
        &__step-bullet:nth-child(n+1):nth-child(-n+5) {
          .bullet-select;
        }
      }
    }

    &[data-step="6"] {
      .popin-med {
        &__step-bullet:nth-child(n+1):nth-child(-n+6) {
          .bullet-select;
        }
      }
    }
  }

  &__super-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    visibility: hidden;
    transition: opacity linear 150ms;
    display: flex;
    justify-content: center;
    align-items: center;

    &#telecons-remb {
      z-index: 20001;
    }

    &.popin-patient-dmp {
      .popin-med__cta-wrapper {
        padding: 20px 0 30px;
      }
    }

    &.booking-agenda {
      .popin-med {
        &__center-content {
          font-size: 0;

          .step-wrapper {
            transition: opacity 500ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
            transform: translate(180%, 0);
            visibility: hidden;
            position: fixed;
            width: 100%;
            opacity: 0;
            overflow: hidden;
            height: 0;

            &.visible {
              transform: translate(0, 0);
              visibility: visible;
              position: relative;
              width: auto;
              opacity: 1;
              height: auto;
              overflow: initial;
              text-align: left;
            }

            &.validated {
              transform: translate(-180%, 0);
            }
          }

          .note {
            font-size: 10px;
            color: @black;
            opacity: 0.8;
            font-style: italic;
            text-align: right;
            margin-top: -10px;

            &.link {
              font-size: 14px;
              font-style: normal;
            }
          }

          label a {
            color: @black;
            text-decoration: underline;
          }

          .cta-wrapper {
            text-align: center;

            &.add-pad {
              padding-top: 20px;
            }

            @media @mobile {
              direction: ltr;
              display: flex;
              justify-content: center;
              flex-wrap: wrap;
            }

            a {
              direction: ltr;
              margin: 5px;
              padding: 0 15px;
            }

            &--hasaccount {
              margin-bottom: 10px;
              font-size: 16px;
              margin-top: 20px;

              .simple_link {
                padding-left: 0;
                font-size: 16px;
                font-weight: 500;
              }

            }
          }

          .password-visibility {
            .icon {
              position: absolute;
              color: @black;
              font-size: 20px;
              z-index: 100;
              top: 5px;
              width: 45px;
              background: @white;
              padding: 10px 0;
              right: 16px;
              display: none;
            }

            input[type="text"] ~ .icon-no-eye {
              display: block;
            }

            input[type="password"] ~ .icon-eye {
              display: block;
            }
          }
        }

        &__title {
          font-size: 16px;

          small {
            font-weight: 400;
            text-transform: none;
            font-size: 16px;
            padding-top: 5px;
            position: relative;

            @media @mobile {
              font-size: 14px;
              border-radius: 0;
            }
          }

          &.blue-header {
            padding: 10px 30px;
            margin-bottom: 30px;

            @media @mobile {
              font-size: 14px;
              border-radius: 0;
            }
          }

          span {
            vertical-align: initial;
          }
        }
      }
    }

    * {
      visibility: hidden;
    }

    &.popin-visible {
      visibility: visible;
      opacity: 1;
      z-index: 20000;

      &.popin-pharma {
        z-index: 300000 !important;
      }

      @media @tablet {
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }

      &#popin-rdv-comment {
        z-index: 30000 !important;
      }

      &.popin-ordo, &.popin-cerfa {
        z-index: 30000 !important;
      }


      &.popin-override {
        z-index: 20002;
      }

      &.confirm-popin {
        z-index: 19000;
      }

      &#popin-target-blank {
        z-index: 20001;
      }

      * {
        visibility: visible;
      }

      &.booking-agenda .popin-med__center-content .step-wrapper.visible {
        display: block;
      }
    }

    @media @tablet {
      transition: opacity 200ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
      opacity: 1;
      transform: translate(100%, 0);
      background: none;

      &.popin-visible {
        transform: translate(0, 0);
      }
    }
  }

  &__wrapper {
    position: relative;
    width: 90%;
    max-height: calc(~"100% - 100px");
    max-width: 800px;
    z-index: 2001;
    background: @white;
    border-radius: 10px;
    padding: 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    transition: width 150ms linear;

    &.no-bg {
      background: none;
    }

    &.with-sticky-cta {
      height: calc(~"100% - 100px");
      min-width: 700px;
    }

    @media @tablet {
      padding-left: 20px;
      padding-right: 20px;
      left: 0;
      top: 0;
      max-height: calc(~"100% - 0px");
      height: calc(~"100% - 0px");
      border-radius: 0;
      width: 100%;
      max-width: 100%;

      &.with-sticky-cta {
        height: 100%;
        width: 100%;
        min-width: auto;
        max-width: 100%;
      }
    }

    @media @mobile {
      padding-bottom: 80px;
    }

    @media @mobilelandscape {
      top: 0;
      max-height: 100%;
      height: 100%;
    }

    &--inner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
      padding: 40px 40px 120px;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      height: calc(~"100% - 100px");

      @media @tablet {
        padding-left: 20px;
        padding-right: 20px;
        height: 100%;
      }

      @media @mobile {
        padding-bottom: 120px;
      }
    }

    &.static {
      position: relative;
      margin: 0 auto;
      top: 0;
      left: 0;
      transform: none;
      max-height: none;
    }

    .dmp-visible & {
      padding-left: 20px;
      padding-right: 20px;
    }

    &.grey-bg {
      background: @lightgrey;
    }

    &.fullpage-popin {
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      transform: translate(0, 0);
      border-radius: 0;
    }

    &#popin-select-profil {
      max-width: 1024px;

      .step2 {
        display: none;

        .popin-med__title {
          margin-bottom: 40px;
        }
      }
    }

    .input-wrapper {
      label {
        text-align: left;
        margin: 0;

        &.checkbox {
          margin-right: 20px;

          @media @mobile {
            margin-right: 0;
          }
        }
      }

      strong {
        font-weight: 500;
      }
    }

    &.has-inner-popin {
      z-index: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity 300ms linear;
      border: 3px solid @green;

      &.active {
        z-index: 2002;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &__inner-popin {
    background: @white;
    font-size: 16px;
    font-weight: 400;

    &--title {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      background: @blue;
      color: @white;
      margin: -40px -40px 30px;
      padding: 10px;

      @media @mobile {
        margin: -40px -20px 30px;
      }
    }

    &--text {
      padding: 0 0 20px 0;

      .text {
        padding: 0 0 20px 20px;
        position: relative;
        font-weight: 500;

        &:before {
          content: '';
          position: absolute;
          top: 6px;
          left: 0;
          height: 8px;
          width: 8px;
          background: @green;
          border-radius: 100px;
        }
      }
    }

    &--text-infos {
      display: flex;
      padding: 0 10px 30px;

      @media @mobile {
        flex-wrap: wrap;
      }

      .text {
        padding: 0 10px 0 0;
        font-weight: 500;
        font-size: 14px;
        text-align: left;

        @media @mobile {
          padding: 0;
          text-align: center;
        }
      }

      .picto-certif {
        text-align: right;
        width: 110px;

        @media @mobile {
          text-align: center;
          width: 100%;
          padding-top: 10px;

          img {
            width: 100px;
          }
        }

        img {
          width: 110px;
          height: auto;
        }
      }

    }

    .picto-text {
      text-align: center;
      font-style: italic;
      font-weight: 400;
      padding: 10px;
      font-size: 14px;
    }

    &--loader {
      text-align: center;
      padding-bottom: 40px;
      margin: -30px -40px 0;
      pointer-events: none;

      @media @mobile {
        margin: -30px -20px 0;
      }
    }

    &--bar {
      display: block;
      overflow: hidden;
      height: 4px;
      width: 100%;
      position: relative;
      background: rgba(0, 0, 0, 0.1);

      &:after {
        content: '';
        position: absolute;
        height: 4px;
        background: @green;
        top: 0;
        left: 0;
        width: 0;
        transition: width 11.9s linear;
      }

      &.active {
        &:after {
          width: 100%;
        }
      }
    }

    &--cta {
      text-align: right;
      pointer-events: none;
      opacity: 0;
      transition: opacity 300ms linear;

      &.active {
        opacity: 1;
        pointer-events: all;
      }
    }

    strong {
      font-weight: 500;
      color: @blue;
    }
  }

  &__close {
    position: fixed;
    font-size: 20px;
    color: @antracite !important;
    right: 50%;
    transform: translate(50%, 0);
    padding: 6px;
    background: @white;
    border-radius: 100px;
    z-index: 100;
    margin-right: -375px;
    margin-top: -30px;

    &.no-bg {
      color: @white !important;
      background: @antracite !important;
      margin-top: -35px;

      @media @tablet {
        top: 4px;
        margin-top: 0;
      }

      @media @mobile {
        right: 4px;
      }
    }

    @media @tablet {
      padding: 6px;
      top: 10px;
      right: 10px;
      margin-right: 0;
      margin-top: 0;
      transform: none;
    }
  }

  &__checkbox {
    max-width: 600px;
    margin: 0 auto;
    position: relative;
  }

  &__showmore {
    max-width: 600px;
    margin: 0 auto;
    padding-bottom: 30px;
    text-align: right;

    @media @verysmallmobile {
      padding-bottom: 20px;
    }
  }

  &__color-header {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    background: @antracite;
    color: @white;
    padding: 15px 30px;
    margin: -40px -40px 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media @mobile {
      margin: -40px -20px 15px;
    }

    &.fixed-mobile {
      @media @mobile {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
      }
    }

  }

  &__alert-list {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;

    &--text {
      line-height: 1.5;

      .simple_link {
        font-weight: 500;
        font-size: 14px;
        margin: 20px 0;
      }
    }

    strong {
      font-weight: 500;
    }

    > li {
      margin-bottom: 20px;
      font-size: 14px;
      font-weight: 400;
      position: relative;
    }

    ul {
      list-style: none;
      margin: 10px 20px;
      padding: 10px 0 0 0;

      @media @mobile {
        margin: 10px 0 20px;
      }

      li {
        margin-bottom: 15px;
        position: relative;
        padding-left: 30px;

        .simple_link {
          font-size: 14px;
        }

        &:before {
          position: absolute;
          content: '';
          width: 14px;
          height: 8px;
          border-left: 3px solid @green;
          border-bottom: 3px solid @green;
          left: 0;
          top: 5px;
          transform: rotate(-45deg);
        }
      }
    }

    &--list-title {
      font-weight: 700;
      display: block;
      margin-bottom: 8px;
    }
  }

  &__list-picto {
    &--item {
      display: flex;
      align-items: center;
      max-width: 600px;
      margin: 0 auto 20px;

      @media @verysmallmobile {
        margin: 0 auto 10px;
      }
    }

    &--picto {
      font-size: 25px;
      padding-right: 20px;
      color: @blue;

      @media @verysmallmobile {
        font-size: 20px;
      }
    }

    &--text {
      font-size: 16px;

      @media @verysmallmobile {
        font-size: 14px;
      }
    }

    &--title {
      font-weight: 500;
      line-height: 1.3;
    }

    &--desc {
      font-weight: 300;
      font-size: 12px;
      line-height: 1;
    }

  }

  &__title {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 30px;
    line-height: 1.5;

    .blue-cta {
      margin: 5px;
    }

    &.lowerleft {
      text-transform: none;
      text-align: left;
    }

    &.smaller-mobile {
      @media @mobile {
        margin-top: -20px;
        padding-right: 10px;
      }

      @media @verysmallmobile {
        padding-bottom: 20px;
        font-size: 12px;
      }
    }

    &.blue {
      color: @blue;
    }

    &.lowercase {
      text-transform: none;
    }

    hr {
      margin-bottom: 0;
    }

    .address, .name, .mobile {
      display: inline-block;
      vertical-align: middle;
      background: @blue;
      color: @white;
      border-radius: 4px;
      padding: 2px 7px;
      margin: 4px 8px;
      font-size: 14px;
    }
  }

  &__subtitle {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    padding-bottom: 40px;

    &.small-desc {
      padding-bottom: 0;
      max-width: 400px;
      margin: 0 auto;
    }

    .delete-account {
      color: @red;
      font-size: 16px;
    }

    .blue-bg {
      display: inline-block;
      vertical-align: middle;
      background: @blue;
      color: @white;
      border-radius: 4px;
      padding: 2px 5px;
      font-weight: 500;
      margin: 4px;
      font-size: 12px;
    }
  }

  &__smalldesc {
    text-align: left;
    font-weight: 400;
    font-size: 12px;
    padding-bottom: 30px;

    span {
      vertical-align: initial;
    }

    strong {
      font-weight: 500;
      font-size: 14px;
    }
  }

  &__file {
    padding: 20px 0;
    text-align: center;

    label {
      font-size: 13px;
      vertical-align: middle;
      margin-bottom: 0;

      @media @mobile {
        margin-bottom: 15px;
      }

    }
  }

  &__section-title {
    padding: 0 0 30px;
    text-align: left;
    font-size: 16px;
  }

  &__center-content {
    text-align: center;
  }

  &__center-content-file {
    text-align: center;

    &--label {
      font-size: 16px;
      padding-bottom: 30px;

      @media @mobile {
        font-size: 14px;
      }
    }

    &--picto {
      font-size: 60px;
      padding-bottom: 30px;
      color: @mediumgrey2;
    }

    iframe {
      display: block;
      width: 100%;
      height: 50vh;
      border: 0;
    }
  }

  &__cta-wrapper {
    text-align: center;
    padding-top: 40px;
    width: 100%;

    &.sticky-cta {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      z-index: 2;
      padding: 20px 20px 40px;
      background: rgba(255, 255, 255, 0.8);
      border-top: 1px solid @lightgrey2;
    }

    &--warning {
      font-size: 14px;
      line-height: 1.3;
      font-weight: 400;
      text-align: left;
      margin-bottom: 20px;
      padding: 10px;
      background: @yellowpj;
      border-radius: 4px;
    }

    &.no-pad {
      padding: 0;
    }

    .blue-cta {
      margin: 10px;
      max-width: calc(~"100% - 20px");
    }

    &.less-pad {
      @media @mobile {
        padding-top: 20px;
      }

      @media @verysmallmobile {
        padding-top: 0;
      }
    }
  }

  &__block-text {
    text-align: left;

    &--title {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 500;
      padding-bottom: 5px;
    }

    &--desc {
      padding-bottom: 10px;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 400;
    }
  }
}

.loader, .loaderPayment {
  color: transparent;
  pointer-events: none;
}

.loader, .loaderPayment, .loading, .mini-loader, .small-loader {
  pointer-events: none;

  &:after {
    content: '' !important;
    position: absolute;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top: 6px solid @green;
    border-radius: 50%;
    width: 30px !important;
    height: 30px !important;
    box-sizing: border-box;
    opacity: 1 !important;
    top: 50%;
    left: 50%;
    animation: spin 2s linear infinite;
    margin: -15px 0 0 -15px;
  }

  &.reset {
    &:after {
      animation: none;
    }
  }

  span {
    opacity: 0;
  }
}

.dot-loader {
  pointer-events: none;

  &:after {
    content: '...';
    position: absolute;
    animation: dotdotdot 0.8s linear infinite;
  }
}

.mini-loader {
  pointer-events: none;

  &:after {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid @green;
    width: 14px;
    height: 14px;
    margin: -7px 0 0 -7px;
  }

  color: transparent;
}

.small-loader {
  pointer-events: none;

  &:after {
    border: 3px solid rgba(0, 0, 0, 0.1);
    border-top: 3px solid @green;
    width: 14px;
    height: 14px;
    margin: -7px 0 0 -7px;
  }

  color: rgba(0, 0, 0, 0.2) !important;

  * {
    color: rgba(0, 0, 0, 0.2) !important;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dotdotdot {
  0% {
    content: '';
  }
  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

.section {
  &__list-item {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    font-size: 12px;
    position: relative;
    transition: opacity 150ms linear;

    a {
      display: block;
      color: @black;
    }

    .icon {
      width: 50px;
      text-align: center;
      display: inline-block;
      position: relative;

      + a {
        display: inline-block;
      }

    }

    &--label {
      display: inline-block;
      vertical-align: middle;
      padding: 0 50px 0 20px;
      line-height: 1;
      width: calc(~"100% - 60px");

      &.autoWidth {
        width: auto;
        padding: 0 10px 0 20px;
      }
    }

    &--spe {
      display: inline;
      font-weight: 300;
    }

    li {
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      height: 42px;
      line-height: 42px;
      position: relative;

      @media @desktoponly {
        &:hover {
          background: @blue;
          color: @white;

          &:after {
            border-color: @white;
          }

          a, strong {
            color: @white;
          }
        }
      }

      &:after {
        content: '';
        position: absolute;
        right: 30px;
        width: 6px;
        height: 6px;
        top: 50%;
        border-top: 1px solid @black;
        border-right: 1px solid @black;
        transform: rotate(45deg) translate(0, -50%);
      }
    }
  }
}

.align-center {
  text-align: center !important;
}

.flex-align-center {
  justify-content: center !important;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.faq-list {
  margin: 40px -40px -20px;
  color: @black;
  font-weight: 500;

  @media @mobile {
    margin: 40px -20px -20px;
  }

  &__wrapper {
    background: @white;
    margin: 20px 0 30px;
  }

  &__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &.active {
      .faq-list__title {
        &:after {
          transform: translate(0, -50%) rotate(130deg);
        }
      }

      .faq-list__desc {
        padding: 10px 20px 20px;
        opacity: 1;
        height: auto;
        visibility: visible;
        line-height: 1.5;
      }
    }
  }

  &__title {
    position: relative;
    padding: 10px 30px 10px 20px;
    cursor: pointer;
    color: @black;
    font-size: 14px;
    text-align: left;
    font-weight: 500;

    .icon {
      vertical-align: middle;
      margin-right: 5px;
      font-size: 16px;
      color: @antracite;
      opacity: 0.5;
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translate(0, -50%) rotate(45deg);
      border-top: 2px solid @green;
      border-right: 2px solid @green;
      height: 10px;
      width: 10px;
    }
  }

  &__desc {
    padding: 0 20px;
    opacity: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    font-weight: 400;
    text-align: left;
    font-size: 14px;
  }
}

.preview-list, .preview-list-fake {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;

  &[data-role="no-account-agenda"] {
    .date-list-wrapper {
      .date-item {
        background: @green;

        &[data-day="dim"] {
          background: #d8d8d8;
          cursor: not-allowed;
          pointer-events: none;

        }
      }
    }
  }

  &__cta {
    text-align: center;
    padding: 20px 10px 0;

    &.with-chevron {
      @media @mobile {
        margin-top: -32px;
        width: 100%;
        padding: 20px 0 5px;

        .left, .right {
          position: absolute;
          top: 0;
        }

        .left {
          left: 0;
        }

        .right {
          right: 0;
        }
      }
    }
  }

  &#preview-list {

    > br {
      display: none;
    }

    button {
      margin-top: 30px;
      margin-left: 20px;

      .icon-printer {
        margin-right: 5px;
      }
    }

    .date-list-item {
      display: block;

      .date-wrapper {
        width: auto;
        display: inline-block;
        background: @black;
        color: @white;
        text-align: left;
        padding: 5px 15px;
        margin: 20px 0 10px 20px;
        border-radius: 100px;

        strong {
          font-weight: 500;
        }

        .day-name, .date-formated {
          display: inline-block;
          margin: 0 5px;
          font-size: 20px;
        }
      }

      .date-list-wrapper {
        .rdv-details-wrapper {
          line-height: 2;
        }

        .rdv_time {
          color: @blue;
          border: 1px solid @blue;
          border-radius: 100px;
          font-size: 14px;
          padding: 5px 10px;
          line-height: 1;
        }
      }
    }
  }

  &.full-width {
    margin: 0 -40px;
    padding: 0;

    @media @mobile {

      margin: 0 -20px;

      .date-list-item {
        padding: 10px 0;
      }
    }
  }

  .date-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    font-size: 0;
    padding: 10px 0;

    &.no-border {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: -20px;
    }

    &.to_show_more {
      display: none;
    }
  }

  .date-wrapper {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 70px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      height: 20px;
      background: rgba(0, 0, 0, .1);
      width: 1px;
      top: 50%;
      transform: translate(0, -50%);
      right: 0;
    }
  }

  .day-name {
    display: block;
    font-size: 14px;
    font-weight: 500;

    &.nothing {
      opacity: 0.5;
    }
  }

  .period-name {
    display: block;
    font-size: 12px;
    padding: 0 5px;
    font-weight: 500;
  }

  .date-formated {
    display: block;
    font-size: 14px;
  }

  .date-list-wrapper {
    display: inline-block;
    vertical-align: middle;
    width: calc(~"100% - 70px");
    padding-left: 20px;
    padding-right: 30px;
    position: relative;

    &.center-items {
      text-align: center;
    }

    &.simple-date {
      padding-right: 10px;
      padding-left: 10px;

      @media @mobile {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media @mobile {
      padding-left: 10px;
      padding-right: 30px;
    }

    button[data-action="show-more-agenda"] {
      font-size: 20px;
      font-weight: 500;
      border-radius: 99px;
      background: @green;
      color: @white;
      display: block;
      line-height: 20px;
      width: 20px;
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translate(0, -50%);
      text-align: center;
      z-index: 10;

      @media @mobile {
        right: 6px;
      }
    }

    .date-item {
      font-weight: 400;
      background: @blue;
      color: @white;
      display: inline-block;
      vertical-align: middle;
      margin: 5px 10px 5px 0;
      font-size: 13px;
      border-radius: 4px;
      padding: 5px 8px;
      line-height: 1;
      font-family: Sans-Serif, Monospace !important;
      transition: opacity, background-color, color 200ms linear;
      cursor: pointer;
      position: relative;

      @media @verysmallmobile {
        letter-spacing: -0.04em;
        padding: 5px 6px;
      }

      &.hasReplace {
        &:after {
          content: 'R';
          position: absolute;
          top: -4px;
          right: -5px;
          padding: 2px 4px;
          font-size: 10px;
          color: @black;
          background: @yellowpj;
          z-index: 2;
          font-weight: 600;
          border-radius: 100px;
        }
      }

      @media @mobile {
        margin-right: 5px;
      }

      &.has-rdv {
        background: @dark;
      }

      &.has-rdv[data-type="absence"] {
        background: #e4e4e4;
      }

      &.disabled {
        opacity: 0.2;
        cursor: not-allowed;
      }

      &[data-action="delete-agenda-slot"] {
        cursor: pointer;

        &:after {
          content: '  x';
        }
      }

    }
  }
}

.publi-img-wrapper {
  img {
    display: inline-block;
    margin: 5px 5px 5px 0 !important;
    border: 1px solid rgba(0, 0, 0, .1);
    padding: 1px;
  }
}

.infossup-btn, .params-btn {
  position: fixed;
  right: 20px;
  bottom: 20px;
  height: 50px;
  background: @green;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  padding: 10px 20px;
  color: @white;
  border-radius: 999px;
  z-index: 1001;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;

  animation-name: bounce;
  animation-duration: 600ms;
  animation-iteration-count: infinite;
  animation-delay: 0s;

  &.to-left {
    right: auto;
    left: 20px;
  }

  &.agenda-btn {
    bottom: 20px;
    right: 10px;
    width: 50px;
    padding: 10px;

    @media @tablet {
      bottom: 120px;
    }
  }

  .infossup-open & {
    color: @green;
    background: @white;
    border: 3px solid @green;
    z-index: 10001;
  }

  .mobile-header-visible & {
    display: none;
  }

  @media @mobile {
    width: 60px;
    height: 60px;
    padding: 10px;
    right: 5px;
    bottom: 10%;

    &.agenda-btn {
      width: 50px;
      height: 50px;
      bottom: 120px;
      right: 5px;
    }
  }

  &__add {
    font-size: 25px;
    vertical-align: middle;

    &:after {
      content: '+';

      .infossup-open & {
        content: '-';
      }
    }
  }

  &__text {
    vertical-align: middle;

    @media @mobile {
      display: none;
    }
  }
}

.params-btn {
  bottom: 90px;
  right: 10px;
  width: 50px;
  height: 50px;
  padding: 0;
  animation-name: none;
  background: @blue;

  @media @tablet {
    bottom: 180px;
  }

  @media @mobile {
    right: 5px;
  }

  .icon-cog {
    font-size: 25px;
  }
}

.infossup-content {
  opacity: 0;
  visibility: hidden;
  position: fixed;
  padding: 20px 40px;
  left: 0;
  top: 60px;
  z-index: 10000;
  height: calc(~"100% - 100px");
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  color: @white;

  &__select {
    display: none;

    a.blue-cta {
      margin-top: 20px;
    }

    &.selected {
      display: block;
    }
  }

  &__list {

    .blue-cta {
      margin: 0 10px 10px 0;
      line-height: 1.3;
      vertical-align: middle;
      height: auto;
      padding: 10px;
      background: none;
      color: @white;
      font-weight: 700;
      box-shadow: none;
      text-align: left;

      @media @desktoponly {

        .infossup-content &:hover {
          color: @white;
        }
      }

      &.selected {
        opacity: 1;
        color: @white;
        border: 1px solid @white;

        @media @desktoponly {

          .infossup-content &:hover {
            color: @white;
          }
        }

      }
    }
  }

  .input-wrapper {
    padding: 10px 0 20px;

    &.no-padding-bottom {
      padding-bottom: 0;
    }

    &.no-padding {
      padding: 0;
    }

    select {
      color: @black;
      font-size: 16px;
      padding: 0 10px;

      @media @mobile {
        height: 40px;
      }
    }
  }

  label, .title {
    font-size: 20px;
    font-weight: 500;

    @media @mobile {
      font-size: 16px;
    }
  }

  @media @mobile {
    padding: 20px 10px;
    top: 40px;
    height: calc(~"100% - 60px");
  }

  .infossup-open & {
    opacity: 1;
    visibility: visible;
  }
}

.infossup-open {
  .header {
    &:before {
      opacity: 1;
      visibility: visible;
    }

    &__burger {
      display: none;
    }
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.04);
  }
  100% {
    transform: scale(1);
  }
}

.help-link {
  display: inline-block;
  vertical-align: top;
  border-radius: 99px;
  background: @green;
  color: @white;
  font-weight: 500;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 14px;
  position: relative;
  z-index: 100;
}

.bottom-note {
  font-size: 11px;
  font-weight: 300;
  text-align: left;
  padding: 10px;
  color: @black;
}

.simple_link {
  font-size: 12px;
  color: @blue;
  background: none;
  border: 0;
  cursor: pointer;

  &.medium {
    font-size: 14px;

    @media @mobile {
      font-size: 12px;
    }
  }

  &.bigger {
    font-size: 16px;
    font-weight: 500;
  }

  &.regular {
    font-weight: 400 !important;
  }
}

@media print {
  .hidden-print {
    display: none !important
  }
}

.tablet-only-btn {
  display: none;

  @media @tablet {
    display: inline-block;
  }
}

.geoloc-popin {
  &__search-engine {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .input-wrapper {
      padding-right: 0;

      select {
        border-radius: 4px 0 0 4px;
        border-right: 0;
      }
    }

    .submit-geoloc {
      background: @yellow;
      color: @white;
      width: 50px;
      height: 42px;
      line-height: 42px;
      border-radius: 0 4px 4px 0;
      position: relative;

      &.loading {
        pointer-events: none;
      }
    }
  }
}

.round-cta {
  display: block;
  width: 30px;
  height: 30px;
  border: 0;
  line-height: 30px;
  text-align: center;
  overflow: hidden;
  color: @blue;
  font-size: 0 !important;
  background: none;

  .icon {
    font-size: 26px;
    color: @blue;
    display: inline-block;
    vertical-align: middle;
  }
}

span, small, p {
  display: inline-block;

  &.with-green-underline:after {
    position: absolute;
    content: '';
    height: 3px;
    background: @green;
    left: 0;
    width: 100%;
    bottom: 0;
  }
}

.limited-fiche {
  .profil-block-wrapper.fixed-block, .services-lm__title {
    display: none !important;
  }

  .profil-aside {
    display: none !important;
  }

  .content__header-wrapper {
    width: calc(~"100% - 54px") !important;
    padding-top: 10px !important;

    @media @tablet {
      width: 100% !important;
    }

    @media @mobile {
      width: 100% !important;
    }
  }

  .content__header {
    width: 100% !important;
    max-width: 100% !important;
  }

  .footer, .breadcrumbs, .profil-content {
    width: 100% !important;
    max-width: 100%;
  }
}

.custom-checkbox {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;

  &__picto {
    width: 15px;
    min-width: 15px;
    height: 15px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    position: relative;
    margin-right: 15px;
    margin-top: 7px;
  }

  &__text {
    font-size: 12px;
    line-height: 1.5;
    padding-top: 5px;

    strong {
      font-weight: 500;
    }

    @media @verysmallmobile {
      font-size: 10px;
      line-height: 1.3;
    }
  }

  input[type="checkbox"] {
    opacity: 0;
    visibility: hidden;
    width: 0;

    &:checked {
      & + .custom-checkbox__picto {

        &:after {
          position: absolute;
          content: '';
          width: 18px;
          height: 10px;
          border-left: 3px solid @green;
          border-bottom: 3px solid @green;
          left: 2px;
          top: -5px;
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.error {
    input[type="checkbox"]:checked + .custom-checkbox__picto + .custom-checkbox__text {
      color: @black !important;
    }
  }
}

.tlc-form {
  font-size: 0;
  border: 1px solid @blue;
  padding: 10px;
  border-radius: 4px;

  &__content {
    display: none;

    &.active {
      display: block;
    }

    .input-wrapper {
      margin-bottom: 10px;

      label {
        text-transform: none;
      }
    }
  }

  &__ordo {
    display: none;

    &.active {
      display: block;
    }
  }
}

.urg-mask {
  position: fixed;
  z-index: 10000;
  background: rgba(255, 255, 255, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    font-size: 18px;
    padding: 0 20px 50px;
    text-align: center;
    max-width: 440px;
  }

  &__inner {
    text-align: center;
  }

}

body[data-urg-state="open"] {
  .section.teleconsultation-header, .footer {
    display: none !important;
  }
}

.urg-popin {
  position: fixed;
  z-index: 2000000;
  background: @white;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  transition: opacity 150ms linear;
  align-items: center;

  @media @mobile {
    align-items: flex-start;
  }

  &.not-visible {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  &__text {
    font-weight: 400;
    font-size: 16px;
  }

  &__loader {
    pointer-events: none;
    height: 70px;
    margin: 20px 0;
    position: relative;
  }

  &__content {
    font-size: 18px;
    padding: 20px;
    text-align: center;
    max-width: 440px;

    @media @mobile {
      padding-top: 50px;
    }
  }

  &__logo-label {
    font-size: 16px;
    font-weight: 500;
    padding: 30px 0 20px;
  }

  &__logo {
    img {
      width: 200px;
      max-width: 90%;
      height: auto;
      margin: 0 auto;
      display: block;
    }
  }

  &__inner {
    text-align: center;
  }

}

.bullet-list-title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 20px;
}

.bullet-list-subtitle {
  font-weight: 700;
  font-size: 18px;
  margin-bottom: 20px;
}

.bullet-list {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
  font-size: 16px;

  li {
    padding-left: 25px;
    position: relative;
    line-height: 1.5;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 10px;

    @media @mobile {
      font-size: 12px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 8px;
      top: 7px;
      width: 8px;
      height: 8px;
      background: @green;
      border-radius: 100px;
      overflow: hidden;
    }
  }
}

.red-text {
  color: @red;
}

.fullsize-iframe {
  display: block;
  border: 0;
  width: 100%;
  position: absolute;
  top: 60px;
  height: calc(~"100% - 60px");
}

/* STRIPE CONFIRM PAGE */

.confirm-stripe {
  main {
    padding: 0;
    background: @white;
  }

  &__title {
    font-size: 22px;
    font-weight: 600;
    padding-bottom: 40px;
    text-align: center;

  }

  &__picto-success {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 100px;
    border: 2px solid @white;
    background: @green;
    margin: 0 auto 50px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

    &:after {
      content: '';
      position: absolute;
      height: 25px;
      width: 40px;
      border-left: 6px solid @white;
      border-bottom: 6px solid @white;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -70%) rotate(-45deg);
    }

    @media @mobile {
      width: 50px;
      height: 50px;
      margin: 0 auto 30px;

      &:after {
        height: 15px;
        width: 30px;
      }
    }
  }

  &__picto-failed-alter {
    text-align: center;
    font-size: 50px;
    margin-bottom: 30px;
    color: @grey;
  }

  &__picto-failed {
    position: relative;
    width: 64px;
    height: 64px;
    border-radius: 100px;
    border: 2px solid @white;
    background: @red;
    margin: 0 auto 50px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);

    &:after, &:before {
      content: '';
      position: absolute;
      height: 6px;
      width: 40px;
      background: @white;
      top: 50%;
      left: 50%;
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    @media @mobile {
      width: 50px;
      height: 50px;
      margin: 0 auto 30px;

      &:after, &:before {
        content: '';
        position: absolute;
        height: 6px;
        width: 30px;
        background: @white;
        top: 50%;
        left: 50%;
      }
    }
  }

  &__pay-message {
    position: relative;
    margin-bottom: 20px;
    list-style: none;

    li {
      margin-bottom: 20px;
      position: relative;
      line-height: 1.8;

      &.picto-check {
        padding-left: 30px;

        &:before {
          content: '';
          width: 20px;
          height: 12px;
          border-left: 4px solid @green;
          border-bottom: 4px solid @green;
          transform: rotate(-45deg);
          position: absolute;
          left: 0;
          top: 4px;
        }
      }

      &.picto-alert {
        padding-left: 30px;

        &:before {
          content: '!';
          width: 20px;
          height: 20px;
          position: absolute;
          border-radius: 100px;
          text-align: center;
          line-height: 20px;
          color: @white;
          background: @blue;
          left: 0;
          top: 2px;
          z-index: 10;
          font-weight: 600;
        }
      }

      &.confirm-stripe__cancel {
        padding-left: 0;
        text-align: center;
        font-size: 16px;

        &:before {
          display: none;
        }
      }
    }
  }

  .stripe-error {
    padding: 40px 20px 20px;
    font-size: 16px;
    line-height: 1.5;
    text-align: center;

    &__error {
      font-weight: 700;
      padding: 20px 0;
      display: block;
    }
  }

  &__wrapper {
    padding: 100px 20px 20px;
    font-size: 16px;
    margin: 0 auto;
    max-width: 960px;
    line-height: 1.5;

    @media @mobile {
      font-size: 14px;
    }
  }

  &__cta {
    text-align: center;
    padding-top: 40px;

    @media @mobile {
      padding-top: 20px;
    }
  }
}

.pharmaciePage {
  .ordo-files {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    height: auto;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &__count {
      font-size: 25px;
      padding-bottom: 20px;
    }
  }

  .popin-med__wrapper {
    &.files {
      max-width: 1280px;
    }
  }

  main {
    padding: 80px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .patient-name {
    text-transform: none;
  }

  #ordo-details {
    max-width: 100%;
    width: 100%;
  }

  &__form {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    background: @white;
    border: 1px solid @lightgrey;

    .input-wrapper label {
      font-size: 16px;
    }
  }

  &__error {
    padding: 20px 0;
    font-size: 18px;
    text-align: center;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5;
    width: 100%;
    position: relative;

    .icon {
      color: @red;
      margin-right: 10px;

      @media @mobile {
        position: absolute;
        top: -20px;
      }
    }
  }
}

.popin-mobile-searchengine {
  background: @lightgrey;
  transform: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .popin-med__close {
    font-size: 30px;
    margin-right: 0;
    right: 10px;
    transform: none;

    @media @mobile {
      font-size: 20px;
      top: 5px;
      right: 5px;
    }
  }

  .popin-med__wrapper {
    padding-left: 20px;
    padding-right: 20px;
    left: 0;
    top: 0;
    max-height: 100%;
    height: 100%;
    width: 100%;
    transform: none;
    max-width: 100%;
    border-radius: 0;

    .ui-autocomplete__wrapper {
      width: 100%;

      &.pathos {
        width: 100%;
        white-space: nowrap;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        margin: 0;
      }
    }
  }

  &__header {
    background: @antracite;
    color: @white;
    font-size: 20px;
    font-weight: 900;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media @mobile {
      height: 40px;
      font-size: 14px;
    }
  }

  &__input {
    border: 0;
    width: calc(~"100% - 190px");
    position: absolute;
    background: @white;
    top: 0;
    left: 60px;
    padding: 0 30px 0 0;
    font-size: 16px;
    height: 60px;

    @media @mobile {
      height: 40px;
      left: 40px;
      width: calc(~"100% - 80px");
    }
  }

  &__submit {
    position: absolute;
    top: 250px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    cursor: pointer;
    color: @blue;
    font-size: 30px;
    padding: 0 20px;
    z-index: 100;
    text-align: center;
    line-height: 1.3;
    max-width: 1280px;


    @media @mobile {
      padding: 0 10px;
      max-width: 100%;
    }

    .icon {
      margin-right: 5px;
      font-size: 20px;
    }

    .content_submit {
      font-size: 40px;
      color: @antracite;
      width: 100%;
      display: inline;
      word-break: break-word;

      @media @mobile {
        font-size: 30px;
      }
    }

    &--cta-wrapper {
      width: 100%;
      text-align: right;
      padding: 50px 20px 0;

      @media @mobile {
        padding-top: 30px;
        position: absolute;
        bottom: -50px;
        right: 0;
      }

      .blue-cta .icon + span {
        max-width: 100%;
      }
    }

    @media @mobile {
      top: 100px;
      font-size: 20px;
      height: 80px;
    }
  }

  &__engine {
    position: absolute;
    width: 100%;
    height: 60px;
    top: 60px;
    left: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    background: @white;
    z-index: 2;

    @media @mobile {
      top: 40px;
      height: 40px;
    }

    &.alter {
      left: 50%;
      width: 1220px;
      height: 40px;
      margin-top: 20px;
      border-radius: 100px;
      max-width: calc(~"100% - 60px");
      transform: translate(-50%, 0);
      overflow: hidden;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

      @media @mobile {
        max-width: calc(~"100% - 40px");
      }

      .searchpathos__clear {
        width: 25px;
        height: 25px;
        left: 10px !important;
        font-size: 10px;
      }

      .popin-mobile-searchengine__input {
        height: 40px;
        left: 45px;

        @media @mobile {
          max-width: calc(~"100% - 45px");
        }
      }

      &.loader {

        &:after {
          left: 22px !important;
        }
      }

      .icon-input {
        width: 45px;
        height: 39px;
        color: @turquoise;
        font-size: 16px;
        position: absolute;
        left: 0;
        top: 1px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .icon-input {
      width: 60px;
      height: 60px;
      color: @blue;
      font-size: 26px;
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media @mobile {
        width: 40px;
        height: 40px;
        font-size: 16px;
      }
    }

    &.loader {
      &:after {
        left: auto;
        right: 85px;

        @media @mobile {
          right: 50px;
        }

        .medoc & {
          right: 15px;

          @media @mobile {
            right: 5px;
          }
        }
      }
    }
  }

  &__clear {
    position: absolute;
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    color: @white;
    background: @black;
    border-radius: 100px;
    right: 90px;
    top: 20px;
    z-index: 100;
    transition: opacity 100ms linear;
    opacity: 0;
    visibility: hidden;
    cursor: pointer;

    @media @mobile {
      top: 10px;
      right: 55px;
    }

    .medoc & {
      right: 20px;

      @media @mobile {
        right: 10px;
      }
    }

    &.visible {
      opacity: 1;
      visibility: visible;
    }
  }

  &__geoloc {
    width: 60px;
    height: 60px;
    background: @yellow;
    color: @black;
    position: absolute;
    z-index: 100;
    top: 0;
    right: 0;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media @mobile {
      width: 40px;
      height: 40px;
      font-size: 20px;
    }
  }

  &__content {
    position: absolute;
    top: 120px;
    background: @lightgrey;
    left: 0;
    width: 100%;
    height: calc(~"100% - 120px");
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    @media @mobile {
      top: 80px;
    }

    &.pathos-result {
      height: 120px;
      z-index: 10;
      background: none;
      left: 50%;
      max-width: 1230px;
      transform: translate(-50%, 0);

      .ui-autocomplete.ui-widget-content {
        border-top: 0 !important;
      }

      @media @mobile {
        top: 250px;

        .ui-menu.ui-widget.ui-widget-content.ui-autocomplete {
          padding-top: 10px !important;
        }
      }
    }

    .ui-autocomplete.ui-widget-content {
      display: block !important;
      position: absolute !important;
      top: 0 !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
      padding: 20px 0 50px !important;
      margin: 0 !important;
      max-height: 100% !important;
      max-width: 100% !important;
      box-shadow: none !important;
      background: none !important;
    }

    .ui-autocomplete__wrapper {
      margin-bottom: 10px;
      padding: 0;

      .ui-autocomplete-header {
        padding: 10px;
        background: @white;
        border: 0 !important;
        margin-bottom: 0 !important;
        font-weight: 500;
      }
    }

    .ui-menu-item {
      padding-right: 10px !important;
      padding-left: 40px !important;
      border-bottom: 1px solid @lightgrey2;
      padding-top: 12px !important;
      padding-bottom: 12px !important;
      cursor: pointer;

      &.medoc {
        font-size: 20px;
        display: flex;
        align-items: center;
        padding-left: 20px !important;

        .icon {
          font-size: 30px;
          opacity: 0.4;
          margin-right: 15px;
        }

        @media @mobile {
          font-size: 16px;
        }
      }

      &.pathos {
        display: inline-block;
        list-style: none;
        padding: 5px 0 5px 10px !important;
        border: 0;
      }

      &__medoc {
        width: calc(~"100% - 50px");

        .icon {
          margin-right: 5px;
          color: @dargrey;
        }

        &--gal {
          display: block;
          font-size: 13px;
          opacity: 0.5;
          width: 100%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-top: 2px;
        }
      }

      &__pathos {
        display: inline-block;
        vertical-align: middle;
        color: @black;
        background: @s_blue;
        font-size: 14px;
        border-radius: 4px;
        font-weight: 500;
        line-height: 1;
        padding: 10px;
      }

      &:last-child {
        border: 0;
      }

      &:before {
        left: 15px !important;
        top: 19px !important;
      }
    }

    .speville {
      .ui-autocomplete-header {
        display: none;
      }

      .ui-menu-item {
        padding-left: 20px !important;

        strong {
          text-decoration: underline;
        }

        &:before {
          display: none !important;
        }
      }
    }
  }
}

.hidden-mobile {
  @media @mobile {
    display: none !important
  }
}

.hidden-desktop {
  display: none !important;

  @media @mobile {
    display: inherit !important;
  }
}

.blue-header {
  background: @antracite;
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  padding: 20px 20px;
  margin: -40px -40px 0 -40px;
  border-radius: 10px 10px 0 0;
  overflow: hidden;

  @media @mobile {
    font-size: 20px;
    font-weight: 500;
    padding: 10px;
    margin: -40px -20px 0 -20px;
    border-radius: 10px 10px 0 0;
  }

  .logo-no-text {
    display: inline-block;
    vertical-align: middle;
    height: 25px;
    width: auto;
    margin-right: 5px;

  }

  &.smaller {
    font-size: 18px;
  }

  &.more-smaller {
    font-size: 16px;
  }
}

.pharma-city-poi {
  display: inline-block;
  background: @yellow;
  color: @black;
  margin: 0 10px 10px 0;
  padding: 5px 12px 5px 8px;
  line-height: 1;
  border-radius: 100px;
  font-weight: 500;

  .icon {
    margin-right: 8px;
    font-size: 14px;
    vertical-align: middle;
  }

  span {
    vertical-align: middle;
    display: inline;
    text-transform: capitalize;
  }

  &.small {
    .icon {
      min-width: 12px !important;
      width: 12px !important;
      font-size: 12px !important;
    }

    span {
      font-size: 12px;
    }
  }
}

.inline-bg-text {

  &.green {
    background: fade(@green, 30%);
  }

  &.orange {
    background: fade(@orange, 30%);
  }

  &.red {
    background: fade(@red, 30%);
  }

  &.blue {
    background: fade(@blue, 30%);
  }

  &.yellow {
    background: fade(@yellow, 30%);
  }

  padding: 2px 5px;
  display: inline;
}

.services-lm {
  &__title {
    color: @black;
    position: relative;
    font-size: 18px;
    font-weight: 700;
    width: 550px;
    max-width: 100%;
    margin: 30px auto 0;
    padding-left: 22px;
    text-align: left;

    @media @tablet {
      width: 100%;
    }

    @media @mobile {
      font-size: 16px;
      margin: 20px 10px 10px 0;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0;
      height: 100%;
      background: @green;
      width: 4px;
      left: 0;
    }

    &.no-bar {
      width: 100%;
      padding: 0;
      margin: 0 0 5px 0;

      &:after {
        display: none;
      }
    }
  }

  &__pushes {
    padding-top: 30px;

    &--push {
      display: flex;
      align-items: flex-start;
      margin-bottom: 30px;

      span {
        display: block;
      }
    }

    &--desc {
      display: block;
      flex-wrap: wrap;
      padding-left: 20px;
      width: calc(~"100% - 100px");
      max-width: 350px;

      @media @mobile {
        width: calc(~"100% - 80px");
        padding-left: 15px;
      }

      @media @verysmallmobile {
        width: calc(~"100% - 70px");
      }
    }

    &--title {
      font-weight: 700;
      color: @black;
      margin-bottom: 5px;
      font-size: 14px;
    }

    &--content {
      color: @black;
      margin-bottom: 14px;
      line-height: 1.4;
      font-size: 12px;
    }

    &--cta {
      .blue-cta {
        display: inline-block;
        white-space: nowrap;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &--img {
      width: 100px;
      min-width: 100px;
      border-radius: 10px;
      padding: 5px;

      @media @verysmallmobile {
        width: 70px;
        min-width: 70px;
      }

      &.orange {
        background: @s_orange;
      }

      &.blue {
        background: @s_blue;
      }

      &.pink {
        background: @s_pink;
      }

      &.purple {
        background: @s_purple;
      }

      &.orange {
        background: @s_orange;
      }

      &.green {
        background: @s_green;
      }

      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
  }
}

.pagination__paginate {
  list-style: none;
  margin: 0;
  padding: 20px 0;
  font-size: 0;
  text-align: center;

  &--arrows {
    display: flex;
    justify-content: space-evenly;
  }

  &--next, &--prev {
    width: calc(~"50% - 30px");

    .std-btn {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &--next {
    text-align: right;
  }

  &--prev {
    text-align: left;
  }

  &--item {
    font-size: 16px;
    display: inline-block;
    vertical-align: middle;
    margin: 15px 10px;
    font-weight: 400;
    color: #000;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, .1);
    background: #fff;
    padding: 5px 10px;

    @media @mobile {
      margin: 10px 5px;
    }

    &.selected {
      background: @antracite;
      color: @white;
      font-weight: 500;
      pointer-events: none;

      a, button {
        color: @white;
      }
    }

    a, button {
      color: @black;
      background: none;
    }
  }
}

.simple-list {
  list-style: none;
  padding: 0;
  margin: 20px 0;

  li {
    position: relative;
    padding: 0 0 18px 20px;
    line-height: 1.3;

    &:after {
      content: '';
      position: absolute;
      left: 5px;
      top: 6px;
      width: 5px;
      height: 5px;
      background: @turquoise;
      border-radius: 100px;
      overflow: hidden;
    }
  }
}

.anim_dots {
  &:after {
    content: '';
    animation: dots-animation 1.5s linear infinite;
    position: absolute;
  }
}

@keyframes dots-animation {
  0% {
    content: '';
  }

  33% {
    content: '.';
  }
  66% {
    content: '..';
  }
  100% {
    content: '...';
  }
}

.popin-cgu {
  &.popin-visible {
    z-index: 20001;
  }

  &__checkbox {
    display: flex !important;
    align-items: center;

    span {
      display: block;
      max-width: 100%;
      line-height: 1.3;
      font-weight: 500;
      white-space: initial;
      font-size: 14px;
    }
  }
  &__content {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    max-height: calc(~"100vh - 460px");
    background: @lightgrey;
    padding: 20px;
    font-size: 16px;
    line-height: 1.3;
    text-align: left;

    @media @mobile {
      max-height: calc(~"100vh - 320px");
      font-size: 14px;
    }
  }
}