@easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);

@keyframes show-bottom-top {
  0% {
    transform: translate(0, 100%);
    opacity: 0;
  }

  40% {
    transform: translate(0, 100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.anim-show-bottom-top {
  animation-duration: 1500ms;
  animation-iteration-count: 1;
  animation-name: show-bottom-top;
  animation-timing-function: @easeOutQuart;
}

@keyframes show-bottom-top-delay-1 {
  0% {
    transform: translate(0, 100%);
    opacity: 0;
  }

  60% {
    transform: translate(0, 100%);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.anim-show-bottom-top-delay-1 {
  animation-duration: 1500ms;
  animation-iteration-count: 1;
  animation-name: show-bottom-top-delay-1;
  animation-timing-function: @easeOutQuart;
}


@keyframes show-nav {
  0% {
    transform: translate(0, -100%);
  }

  50% {
    transform: translate(0, -100%);
  }

  100% {
    transform: translate(0, 0);
  }
}

.anim-show-nav {
  animation-duration: 1500ms;
  animation-iteration-count: 1;
  animation-name: show-nav;
  animation-timing-function: @easeOutQuart;
}


