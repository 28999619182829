.connectedPage {
  main {
    &:before {
      content: '';
      transition: all 150ms linear;
      opacity: 0;
      visibility: hidden;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
      position: fixed;
      z-index: 1000;
    }

    .first-login-intro& {

      &:before {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .form-cta-wrapper {
    padding: 20px 0;

    &.flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .search_patient_wrapper {
    padding: 40px 0 0;
    
    label {
      text-transform: none;
    }
    .input-wrapper__inner {
      margin: 10px 0;
      input[type="text"] {
        border-radius: 4px 0 0 4px;

        @media @mobile {
          width: 100%;
        }
      }
      .blue-cta  {
        margin: 0;
        font-size: 18px;
        border-radius: 0 4px 4px 0;
        width: 42px;
        justify-content: center;
        padding: 0;
        min-width: 42px;
        .icon {
          margin: 0;
        }
      }
    }
  }

  .ui-datepicker {
    z-index: 1000000 !important;
  }

  .preview-list {

    [data-action="show-more-date"] {
      margin: 30px 0 20px;
    }

    .date-list-item {
      display: none;

      &.visible {
        display: block;
      }
    }

    @media @desktoponly {
      padding: 0 30px 0 20px;
    }

    .date-list-wrapper {
      padding-right: 0;

      .rdv-details-wrapper {
        font-size: 14px;
        padding: 20px 0;
        border-top: 1px solid @lightgrey2;

        hr {
          display: none;
        }

        &:first-child {
          border-top: 0;
        }

        .rdv_time, .rdv_type, .rdv_name, .rdv_gender {
          display: inline-block;
          vertical-align: middle;
          font-weight: 500;
          font-size: 16px;
          margin-right: 5px;
        }

        .rdv_mobile, .rdv_fixe, .rdv_notes {
          display: inline-block;
          vertical-align: middle;
          font-size: 14px;
          margin-right: 5px;
        }
      }

      @media @mobile {
        padding-right: 0;
      }
    }
  }

  .invoices {
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 14px;

    &__item {
      display: block;
      padding: 5px 10px;
    }
  }

  .block-text {
    font-size: 14px;
  }

  #popin-rdv .submit,
  #popin-editrdv .submit {
    display: none;
  }

  .section__col.fixed-btn-agenda,
  .section__col.fixed-btn-params {
    position: fixed;
    z-index: 2000;
    width: 250px;
    margin: 0;
    right: 5px;
    bottom: 15px;
    visibility: hidden;
    opacity: 0;
    transition: all 100ms linear;

    .close-list {
      position: absolute;
      z-index: 200;
      top: 10px;
      right: 10px;
      color: @black;
      font-size: 10px;
      width: 25px;
      height: 25px;
      line-height: 25px;
      text-align: center;
      border-radius: 100px;
      background: @white;
    }

    @media @tablet {
      bottom: 115px;
    }

    &.visible {
      opacity: 1;
      visibility: visible;
      border-radius: 10px;
    }

    .section__list--item {
      width: 100%;
      text-transform: uppercase;
      font-weight: 500;
      text-align: left;
      padding: 0 40px 0 15px;
      background: none;
      font-size: 12px;

      &:empty {
        display: none;
      }

      .span {
        vertical-align: middle;
      }

      .bigger-picto {
        margin-right: 8px;
        vertical-align: middle;
        font-size: 16px;
        color: @blue;
      }

      .add-picto {
        display: inline-block;
        background: @black;
        color: @white;
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 16px;
        text-align: center;
        font-weight: 500;
        vertical-align: middle;
        border-radius: 99px;
        margin-right: 10px;

        &.blue {
          background: @blue;
        }

        &.yellow {
          background: @yellow;
        }

        &.purple {
          background: @purple;
        }

      }

      @media @desktoponly {

        &:hover {
          background: none;
          color: @black;
        }
      }

      &:after {
        display: none;
      }
    }
  }

  .section__col.fixed-btn-params {
    bottom: 88px;

    @media @tablet {
      bottom: 178px;
    }

    .icon-cog, .icon-list2, .icon-table2, .icon-icon-infos, .icon-calendar {
      margin-right: 5px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }

    .section__list--item {
      font-size: 11px;

      &.selected {
        background: @blue;
        color: @white;
      }
    }
  }

  button {
    cursor: pointer;
  }

  .mobile-burger {
    position: fixed;
    right: 20px;
    bottom: 60px;
    width: 50px;
    height: 50px;
    display: none;
    background: @green;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 999px;
    z-index: 100;

    animation-name: bounce;
    animation-duration: 600ms;
    animation-iteration-count: infinite;
    animation-delay: 1s;

    @media @tablet {
      display: block;
      right: 10px;
    }

    @media @mobile {
      right: 5px;
    }

    span {
      display: block;
      height: 2px;
      width: 100%;
      margin: 5px 0;
      background: @white;
      transition: all 150ms linear;

      .mobile-header-visible & {
        position: absolute;
        top: 7px;

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  .picto-burger {
    position: relative;
    right: 0;
    bottom: 0;
    width: 60px;
    height: 50px;
    padding: 15px;
    z-index: 100;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    .icon {
      color: @white;
      vertical-align: middle;
      display: inline-block;
      font-size: 30px;
    }

    @media @tablet {
      display: none;
    }

    span {
      display: block;
      height: 2px;
      width: 100%;
      margin: 0;
      background: @antracite;

      .mobile-header-visible & {
        position: absolute;
        top: 7px;

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(1) {
          transform: rotate(45deg);
        }

        &:nth-child(3) {
          transform: rotate(-45deg);
        }
      }
    }
  }

  &.first-login-intro {
    .mobile-burger {
      z-index: 10000;
    }

    .infossup-btn, .params-btn {
      display: none;
    }
  }

  &.show-menu, &.first-login-intro {
    .mobile-burger {

      span {
        position: absolute;
        top: 17px;
        left: 0;

        &:nth-child(2) {
          opacity: 0;
        }

        &:nth-child(1) {
          transform: rotate(45deg) scale(0.5);
        }

        &:nth-child(3) {
          transform: rotate(-45deg) scale(0.5);
        }
      }
    }

    .section__aside {
      transform: translate(0, 0);
    }
  }

  .section {
    &__profil {
      margin: 0 auto;
      padding: 0 20px 40px;
      font-size: 0;
      text-align: left;

      @media @tablet {
        padding: 0;
        margin-top: 0;
      }
    }

    &__aside {
      width: 30%;
      display: inline-block;
      vertical-align: top;

      .visible-datepicker {
        font-size: 16px;
        padding: 0;

        .ui-widget-content {
          border-top: 0;
        }

        .ui-datepicker-current {
          font-size: 12px;
          font-family: Montserrat, Sans-Serif;
        }

        .ui-datepicker {
          max-width: 100%;
          padding: 0;
          border-bottom: 1px solid #f2f2f2;
        }

        &__select-view {
          display: flex;
          justify-content: center;
          padding: 10px 0;
          border-bottom: 1px solid #f2f2f2;

          button {
            &:nth-child(1) {
              border-radius: 4px 0 0 4px;
            }

            &:nth-child(2) {
              border-radius: 0;
              margin-left: -1px;
            }

            &:nth-child(3) {
              border-radius: 0 4px 4px 0;
              margin-left: -1px;
            }

            &.selected {
              background: @blue;
              color: @white;
            }
          }
        }
      }

      @media @desktoponly {
        position: fixed;
        left: 0;
        width: 60px;
        background: @white;
        z-index: 500;
        top: 60px;
        height: calc(~"100% - 60px");
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
        overflow-y: auto;

        .visible-datepicker-wrapper {
          opacity: 0;
          transition: opacity 100ms linear;
          visibility: hidden;
        }

        .section__title {
          opacity: 0;
        }

        .section__pict-infos {
          opacity: 0;
          height: 50px;
        }

        .first-login-intro& {
          z-index: 1000;
        }

        &:hover, .first-login-intro& {
          width: 300px;

          .section__picto-burger {
            opacity: 0;
          }

          .visible-datepicker-wrapper {
            opacity: 1;
            visibility: visible;
            height: auto;
          }

          .section__title {
            opacity: 1;
          }

          .section__pict {
            height: 74px;
            width: 74px;
            margin: 40px 40px 22px 40px;
            border-radius: 100px;
            overflow: hidden;
          }

          .section__pict-infos {
            opacity: 1;
            white-space: normal;
          }

          .section__list--item .label {
            opacity: 1;
          }

          .section__list--item:after {
            right: 40px;
          }
        }
      }

      @media @tablet {
        transition: all 300ms cubic-bezier(0.165, 0.840, 0.440, 1.000);
        position: fixed;
        left: 0;
        width: calc(~"100% - 300px");
        background: @white;
        z-index: 2000;
        top: 60px;
        height: calc(~"100% - 60px");
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.2);
        transform: translate(-110%, 0);
        overflow-y: auto;
      }
      @media @mobile {
        width: calc(~"100% - 60px");
      }

      @media @mobilelandscape {
        top: 0;
        height: 100%;
      }
    }

    &__content {
      display: inline-block;
      width: 100%;
      vertical-align: top;

      @media @desktoponly {
        width: calc(~"100% - 20px");
        margin-left: 40px;
      }

      @media @tablet {
        width: 100%;
        margin: 0;
      }

      .section {
        &__wrapper {
          padding: 40px;
          transition: opacity 300ms linear;

          &.no-title {
            padding-top: 0;
          }

          @media @mobile {
            padding: 20px;
          }
        }

        &__content-title {
          font-size: 24px;
          line-height: 1.3;
          color: @antracite;
          font-weight: 500;
          padding: 10px 0;
          display: flex;
          align-items: center;

          .icon {
            width: 40px;
            min-width: 40px;
            text-align: center;
            line-height: 40px;
            font-size: 20px;
            background: @turquoise;
            color: @white;
            border-radius: 100px;
            margin-right: 15px;
          }

          @media @mobile {
            font-size: 20px;
          }
        }

        &__back-btn {
          background: none;
          font-size: 14px;
          padding: 0 0 0 20px;
          margin-bottom: 15px;
          position: relative;
          font-weight: 400;

          &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            height: 10px;
            width: 10px;
            border-top: 3px solid @green;
            border-left: 3px solid @green;
            transform: translate(0, -50%) rotate(-45deg);
          }
        }

        &__content-subtitle {
          font-size: 18px;
          line-height: 1.3;
          padding: 20px 0 0;

          .icon-edit {
            display: inline-block;
            font-size: 14px;
            margin-left: 10px;
            color: @white;
            background: @green;
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            vertical-align: middle;
            border-radius: 100px;
            cursor: pointer;
          }
        }

        &__form-desc {
          font-size: 13px;
          padding: 14px 0 0;

          .light {
            color: @grey;
          }
        }

        &__form {
          padding: 40px 0;

          &.agenda-form {

            @media @tabletonly {
              margin: 0 -30px 0 -30px;
              padding-top: 0;
            }

            @media @mobile {
              padding-top: 0;
              padding-bottom: 200px;
            }

          }

          &--pict-wrapper {
            width: 20%;
            display: inline-block;
            vertical-align: top;

            @media @mobile {
              width: 100%;
              padding-bottom: 20px;
            }

            .section__pict {
              margin: 0 40px 22px 0;
            }

            .section__pict-infos {
              padding: 0;

              .change_pict {
                color: @blue;
                font-size: 13px;
                font-weight: 500;
                padding-bottom: 10px;
                display: block;
              }

              .change-pict-infos {
                font-size: 13px;
                line-height: 1.5;
                font-weight: 300;
                padding-right: 20px;

                strong {
                  font-weight: 500;
                }
              }
            }
          }

          &--content {
            width: calc(~"80% - 30px");
            margin-left: 30px;
            display: inline-block;

            @media @mobile {
              width: 100%;
              margin-left: 0;
            }

            &.full {
              width: 100%;
              margin-left: 0;

              .cta-add {
                padding-top: 0;
              }

            }

            .title-wrapper {
              text-transform: uppercase;
              font-weight: 600;
              font-size: 16px;
              text-align: center;
              padding: 10px 0 30px;
            }
          }
        }
      }
    }

    &__col {
      width: 100%;
      display: block;
      margin-bottom: 30px;
      background: white;
      overflow: hidden;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
      position: relative;

      &.no-background {
        background: none;
        box-shadow: none;
        border-radius: 0;
      }

      &[data-agenda-type="preview-list"],
      &[data-agenda-type="month-agenda"] {
        .medecin-agenda__nav {
          display: none;
        }
      }

      &[data-agenda-type="preview-list"],
      &[data-agenda-type="medecin-agenda"] {
        .medecin-agenda__navmonth {
          display: none;
        }
      }

      @media @mobilelandscape {
        &[data-type="agenda"] {
          .section__wrapper {
            padding-top: 0;
          }
        }
      }

      @media @mobile {
        border-radius: 0;
      }

      &.disabled {
        opacity: 0.4;
        pointer-events: none;
      }

      &[data-role="infos-form"] {
        display: none;

        &.selected {
          display: block;
        }

        &.has-search-loader {
          pointer-events: none;

          .search-loader {
            position: absolute;
            width: 100%;
            top: 150px;
            left: 0;
            padding: 20px;

            &__text {
              font-size: 20px;

              @media @mobile {
                font-size: 16px;
              }
            }
          }

          .section__wrapper {
            opacity: 0.1;
          }
        }
      }
    }

    &__picto-burger {
      background: @white;

      @media @tablet {
        display: none;
      }
    }

    &__pict, &__picto-burger {
      height: 74px;
      width: 74px;
      margin: 40px 40px 22px 40px;
      border-radius: 100px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media @desktoponly {
        height: 50px;
        width: 60px;
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &.withAgenda {
          position: absolute;
          top: 0;
          background: @antracite;

          .picto-burger {
            padding: 0;
          }
        }
      }
    }

    &__picto-burger {
      border-radius: 0;
      pointer-events: none;
    }

    &__pict-infos {
      padding: 0 40px;
      line-height: 1.3;

      &--hello {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: 5px;
        display: inline-block;
      }

      &--name {
        font-size: 20px;
        font-weight: 700;
        display: inline-block;
      }
    }

    &__title {
      color: @blue;
      font-size: 14px;
      line-height: 1;
      padding: 18px 40px 12px;
      white-space: nowrap;

    }

    &__list {
      &--badge {
        position: absolute;
        background: @yellowpj;
        color: @black;
        top: 4px;
        font-weight: 500;
        right: 4px;
        z-index: 10;
        padding: 2px 5px;
        border-radius: 100px;
        font-size: 8px;
        line-height: 1;
      }

      &--item {
        height: 50px;
        line-height: 50px;
        position: relative;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        transition: all 150ms linear;
        cursor: pointer;
        display: block;
        color: @black;
        font-size: 0;

        &:first-child {
          border-top: 0;
        }

        &.disabled {
          opacity: 0.4;
          pointer-events: none;
        }

        &:after {
          content: '';
          position: absolute;
          right: 40px;
          width: 8px;
          height: 8px;
          top: 50%;
          border-top: 2px solid @black;
          border-right: 2px solid @black;
          transform: rotate(45deg) translate(0, -50%);
          transition: all 150ms linear;

          @media @desktoponly {
            right: 13px;
            transition: all 0ms linear;
          }
        }

        &.opened {
          &:after {
            transform: rotate(135deg) translate(0, -50%);
            top: 32%;
          }
        }

        &.subnav {
          display: none;
          background: #f9f9f9;

          &.subnav-active {
            display: block;
          }
        }

        &.no-subnav {
          &:after {
            display: none;
          }
        }

        .picto {
          width: 60px;
          padding: 0 20px;
          display: inline-block;

          @media @desktoponly {
            padding: 0 15px;
          }

          i {
            display: inline-block;
            vertical-align: middle;
            font-size: 16px;
            transition: all 150ms linear;
          }
        }

        .label {
          display: inline-block;
          width: calc(~"100% - 60px");
          font-size: 12px;
          padding: 0 50px 0 20px;
          vertical-align: middle;
          position: relative;
          transition: all 150ms linear;
          line-height: 1.3;
          color: @black;
          font-weight: 500;

          @media @desktoponly {
            opacity: 0;
            transition: all 0ms linear;
          }

          &:before {
            position: absolute;
            content: '';
            left: 0;
            height: 16px;
            top: 50%;
            margin-top: -8px;
            width: 1px;
            background: rgba(0, 0, 0, 0.1);
          }
        }

        &.active, &:hover {
          background: @antracite;

          &:after {
            border-color: @white;
          }

          .picto, .label {
            color: @white;
          }
        }
      }
    }
  }

  &.medecinPage {
    @media @mobilelandscape {

      .infossup-btn.agenda-btn, .params-btn.agenda-btn {
        bottom: 26px;
        right: 70px;
      }

      .params-btn {
        bottom: 26px;
        right: 130px;
      }

      .mobile-burger {
        bottom: 26px;
      }

      .section__col.fixed-btn-params {
        bottom: 18px;
      }

      .medecin-agenda__nav, .medecin-agenda__navmonth {
        position: fixed;
        padding: 0 5px 11px;
        border-radius: 100px;
        background: @white;
        bottom: 23px;
        right: 230px;
        z-index: 1999;
        box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.1);
      }

      main {
        padding: 0;
        overflow: hidden;

        .section__profil {
          margin: 0;
          padding: 0;
        }

        .section__col {
          border-radius: 0;
        }
      }
    }
  }
}

.publications-list {

  margin: 0 -40px;

  @media @mobile {
    margin: 0 -20px;
  }

  &__item {
    display: block;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    padding: 0 40px 20px;
    text-transform: uppercase;

    @media @mobile {
      padding: 0 20px 20px;
    }
  }

  &__content {
    font-weight: 300;
    font-size: 13px;
    line-height: 1.5;
    padding: 0 40px;

    @media @mobile {
      padding: 0 20px;
    }

    a {
      color: @blue;
    }
  }

  &__date {
    display: inline-block;
    vertical-align: middle;
    text-align: left;
    width: 60%;
    font-size: 13px;
    padding: 20px 40px 0;

    @media @mobile {
      padding: 20px 20px 0;
    }

    @media @mobile {
      width: 100%;
    }

    input[type="date"] {
      border: 0;
      font-size: 13px;
      font-weight: 500;
      color: @blue;
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
    }

    span {
      display: inline-block;
      font-weight: 300;
      vertical-align: middle;
    }
  }

  &__options {
    text-align: right;
    width: 40%;
    display: inline-block;
    vertical-align: middle;

    padding: 20px 40px 0;

    @media @mobile {
      padding: 20px 20px 0;
    }

    @media @mobile {
      width: 100%;
    }

    button, .sep {
      font-weight: 400;
      font-size: 14px;
      color: @blue;
      display: inline-block;
      vertical-align: middle;
      background: none;
    }

    .sep {
      margin: 0 10px;
    }
  }
}

.result-search {
  display: none;
  border: 1px dashed rgba(35, 41, 57, .3);
  border-radius: 4px;
  padding-top: 20px;
  position: relative;
}

.result-search-list {
  padding: 0 10px;
  opacity: 0.6;
  margin-top: 10px;

}

.result-label {
  padding: 0 20px;
  text-align: center;

}

.result-search, .my-medecin-list {

  ul {
    list-style: none;
    margin: 0;
    padding: 10px;

    &:empty {
      display: none;
    }

    li {
      font-size: 12px;
      line-height: 1.3;
      padding: 10px;
      border-top: 1px solid #f6f6f6;
      text-align: left;

      &:first-child {
        border-top: 0;
      }

      a {
        color: @black;
        font-weight: 300;
        line-height: 1.3;
        transition: all 150ms linear;

        strong {
          font-weight: 500;
        }

        @media @desktoponly {

          &:hover {
            color: @blue;
          }
        }

      }
    }
  }
}

.my-medecin-list {
  border: 2px solid @green;
  margin: 0 10px 30px;
  border-radius: 4px;

  &:empty {
    display: none;
  }

  li {
    padding-left: 40px !important;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 10px;
      transform: translate(0, -50%);
      width: 20px;
      height: 20px;
      border-radius: 100px;
      background: @green;
    }

    &:after {
      content: '';
      position: absolute;
      top: 48%;
      left: 15px;
      transform: translate(0, -50%) rotate(-45deg);
      width: 10px;
      height: 7px;
      border-left: 2px solid @white;
      border-bottom: 2px solid @white;
    }

  }
}

.addInfos-list {
  ul {
    padding: 40px 0;

    li {
      display: block;
      margin-bottom: 10px;
      padding: 10px;
      line-height: 1.3;
      transition: all 150ms linear;
      border-radius: 10px;
      position: relative;

      .delete-file {
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
        font-size: 12px;
        display: none;
        z-index: 10;
        cursor: pointer;
        padding: 5px;
        background: none;
      }

      @media @desktoponly {

        &:hover {
          background: #f9f9f9;

          .delete-file {
            display: block;
          }
        }
      }

      .add-desc {
        font-size: 14px;
        font-weight: 400;
        padding-right: 30px;
        padding-left: 30px;
        display: inline-block;
        vertical-align: middle;
        width: calc(~"100% - 150px");
      }

      .blue-cta {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
}

.folder {
  &__wrapper {
    text-align: left;
    font-size: 0;
    padding-bottom: 20px;

    &.folder-ordos, &.folder-cerfa {

      .folder__item {
        width: 100%;
        height: auto;
        padding: 20px;
      }
    }

    &.mesures {
      .file-wrapper {
        display: none !important;

        + .input-wrapper {
          display: none !important;
        }
      }
    }
  }

  &__delete {
    position: absolute;
    top: 5px;
    right: 5px;
    padding: 10px;
    font-size: 12px;
    transition: all 150ms linear;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &__item {
    border: 1px solid rgba(0, 0, 0, 0.10);
    border-radius: 4px;
    padding: 40px 20px 20px;
    height: 420px;
    width: 325px;
    max-width: 100%;
    margin: 0 20px 20px 0;
    display: inline-block;
    font-size: 13px;
    vertical-align: top;
    text-align: center;
    position: relative;
    background: @white;

    @media @tablet {
      height: auto;
    }
  }

  &__inner {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    .input-wrapper {
      padding-bottom: 10px;
    }
  }

  &__title {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 5px;
    line-height: 1.3;

    &.main {
      text-align: left;
      padding: 0 10px;
    }
  }

  &__date {
    color: @blue;
    font-size: 13px;
    line-height: 1;
    margin-bottom: 30px;
    font-weight: 300;
  }

  &__note-wrapper {
    text-align: left;

    &--item {
      display: block;
      line-height: 1.3;
      font-size: 12px;
      padding: 10px;
      transition: all 150ms linear;
      border-radius: 4px;

      @media @desktoponly {

        &:hover {
          background: #f9f9f9;
        }
      }

    }

    &--text {
      padding-bottom: 10px;
      color: @black;
      display: inline-block;

      .icon {
        margin-right: 10px;
        color: @blue;
        font-size: 15px;
      }
    }

    &--options {
      text-align: right;
      color: @blue;
      font-size: 12px;
      font-weight: 300;

      a {
        color: @blue;
        text-decoration: underline;
      }
    }
  }
}

#popin-select-profil {
  padding-bottom: 80px;
  overflow: visible;
  height: 100%;

  .ongoing-message, .rejected-message {
    position: relative;
    text-align: center;
    width: 100%;
    display: none;
  }

  .popin-med__inner {
    height: auto;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }

  .result-search {
    max-height: 200px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 40px;

    .section__form-desc {
      font-size: 12px;
    }
  }

  .popin-med__cta-wrapper {
    position: relative;
    left: 0;
    width: 100%;
  }

  .my-medecin-list {
    border: 2px solid @green;
    margin: 0 10px 30px;
    border-radius: 4px;

    &:empty {
      display: none;
    }

    li {
      padding-left: 40px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);
        width: 20px;
        height: 20px;
        border-radius: 100px;
        background: @green;
      }

      &:after {
        content: '';
        position: absolute;
        top: 48%;
        left: 15px;
        transform: translate(0, -50%) rotate(-45deg);
        width: 10px;
        height: 7px;
        border-left: 2px solid @white;
        border-bottom: 2px solid @white;
      }

    }
  }

  .result-search {
    border: 1px dashed rgba(35, 41, 57, .3);
    border-radius: 4px;
    margin: 0 10px 30px;
    padding-top: 20px;
  }

  .result-search-list {
    padding: 0;
  }

  .step1 {
    margin-bottom: 20px;
    position: relative;
  }
}

.toggle-wrapper {
  border-bottom: 1px solid #eee;

  .input-wrapper {
    &.half {
      width: calc(~"50% - 10px");
      margin: 5px;

      @media @mobile {
        width: 100%;
        margin: 0;
      }
    }
  }

  .consult-list {
    border-bottom: 1px solid #eee;
    margin-bottom: 30px;
    overflow: hidden;
    padding-bottom: 10px;

    .delete-btn {
      position: absolute;
      height: 16px;
      width: 16px;
      top: 5px;
      right: 5px;
      font-size: 10px;
      color: @white;
      background: @blue;
      border-radius: 100px;
      z-index: 10;
      font-weight: 400;

      @media @mobile {
        width: 20px;
        height: 20px;
        font-size: 14px;
      }
    }

    &:empty {
      display: none;
    }

    .consult-type-wrapper {
      border-radius: 4px;
      padding: 10px 10px 0 10px;
      margin: 5px 0.5% 5px;
      background: rgba(0, 0, 0, 0.03);
      position: relative;
      border: 1px solid rgba(0, 0, 0, 0.1);
      width: 49%;
      float: left;

      label {
        color: @black;
        font-weight: 400;
      }

      .update-consult {
        display: none;
      }

      &.has-update {
        .update-consult {
          display: inline-block;
          margin-bottom: 10px;
        }
      }

      @media @mobile {
        width: 100%;
        margin: 10px 0 10px;
      }

      .input-wrapper {
        padding-bottom: 10px;

        input[type=text], textarea {
          border-radius: 4px;
          font-size: 12px;
          padding: 0 10px;
          height: 30px;
          border-style: dashed;
        }

        textarea {
          height: auto;
          padding: 10px;
        }
      }
    }
  }

  &__title {
    position: relative;
    height: 50px;
    line-height: 50px;
    font-size: 0;
    padding: 0 10px 0 40px;
    text-align: left;
    cursor: pointer;

    &:after, &:before {
      content: '';
      position: absolute;
      background: @blue;
      transition: all 150ms linear;
    }

    &:after {
      width: 15px;
      height: 3px;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
    }

    &:before {
      height: 15px;
      width: 3px;
      top: 50%;
      left: 6px;
      transform: translate(0, -50%);
    }
  }

  &__inner {
    font-size: 14px;
    vertical-align: middle;
    line-height: 1.3;
    display: inline-block;
  }

  &__content {
    transition: all 150ms linear;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    height: 0;
    padding: 0;
  }

  &.visible {

    .toggle-wrapper {

      &__title {
        &:before {
          opacity: 0;
        }
      }

      &__content {
        opacity: 1;
        visibility: visible;
        height: auto;
        padding: 20px 0;
      }
    }
  }
}

.consigne-wrapper {
  font-weight: 500;
  font-size: 12px;
  color: @darkblue;

}

.collabo-wrapper {
  font-size: 16px;
  padding: 20px 0;

  &__item {
    display: flex;
    justify-content: left;
    align-items: center;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: rgba(0, 0, 0, 0.03);
    position: relative;
    padding: 10px;
    margin-bottom: 10px;

    @media @mobile {
      padding-bottom: 50px;
      justify-content: center;
    }

    span {
      padding-right: 10px;

      @media @mobile {
        padding: 0 5px;
      }
    }

    button {
      position: absolute;
      right: 4px;

      @media @mobile {
        right: auto;
        left: 50%;
        transform: translate(-50%, 0);
        bottom: 8px;
      }
    }
  }
}

.add-account {
  [data-role="child-optin"] label {
    text-transform: none;
    line-height: 1.3;
    white-space: normal;

    span {
      display: inline-block;
      vertical-align: top;
      width: calc(~"100% - 40px");
    }
  }
}

.profil-nav {
  background: @white;
  padding: 0;
  border-radius: 0;
  margin: 0 auto;
  width: calc(~"100% + 80px");

  @media @tablet {
    margin: 0 auto 10px;
    width: 100%;
  }

  &__sub-nav {
    display: flex;
    justify-content: center;
    padding: 10px 20px;

    &--item {
      margin: 0 30px;
      position: relative;
      cursor: pointer;
      opacity: 0.5;
      transition: all 150ms linear;

      @media @mobile {
        margin: 0 10px;
      }

      &:after {
        content: '';
        position: absolute;
        left: -30px;
        top: 50%;
        height: 8px;
        width: 8px;
        background: @blue;
        border-radius: 100px;
        transform: translate(0, -50%);
        transition: all 150ms linear;
        opacity: 0;

        @media @mobile {
        }
      }

      &.selected {
        opacity: 1;
        pointer-events: none;
        cursor: default;

        &:after {
          opacity: 1;
          left: -15px;

          @media @mobile {
            left: -12px;
          }
        }
      }

      @media @desktoponly {
        &:hover {
          opacity: 1;

          &:after {
            opacity: 1;
            left: -15px;
          }
        }
      }
    }

    &--title {
      display: flex;
      align-items: center;
    }

    &--text {
      padding: 0;
      margin: 0;
      font-size: 14px;
      font-weight: 400;

      @media @mobile {
        font-size: 10px;
      }
    }
  }

  &__list {
    color: @black;
    display: flex;
    justify-content: space-around;
    padding: 12px 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    @media @mobile {
      padding: 6px 0;
    }

    &--item {
      position: relative;
      cursor: pointer;
      opacity: 0.5;
      transition: all 150ms linear;

      &.selected {
        opacity: 1;
        pointer-events: none;
        cursor: default;

        .icon {
          color: @turquoise;
        }

        .profil-nav__list--text {
          font-weight: 500;
        }

      }

      @media @desktoponly {
        &:hover {
          opacity: 1;

          &:after {
            opacity: 1;
            left: -21px;
          }
        }
      }
    }

    &--title {
      display: flex;
      align-items: center;

      @media @mobile {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
      }
    }

    &--text {
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 400;

      @media @mobile {
        font-size: 10px;
        line-height: 1.3;
        padding: 5px 0 0 0;
        width: 100%;
      }
    }

    &--picto {
      padding-right: 15px;

      .icon {
        font-size: 25px;
        color: @grey;

        @media @mobile {
          font-size: 20px;
        }
      }

      @media @mobile {
        width: 100%;
        min-width: 100%;
        padding: 0;
      }
    }
  }
}

.ordos__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 30px 0;
  position: relative;

  &--hidden {
    display: block;
    width: 100%;

    &.visible {
      .ordos__wrapper--hidden-content {
        display: flex;
      }

      .ordos__wrapper--showmore-cta {
        display: none;
      }

      .ordos__wrapper--showmore-cta.less {
        display: block;
      }
    }
  }

  &--hidden-content {
    display: none;
    flex-wrap: wrap;
  }

  &--showmore {
    width: 100%;
    text-align: left;
    padding: 20px;

    @media @mobile {
      padding: 20px 0 20px 10px;
    }
  }

  &--showmore-cta {
    font-size: 16px;
    position: relative;
    padding-left: 20px;
    display: block;
    text-align: left;

    &:before {
      content: '+';
      color: @blue;
      font-size: 30px;
      line-height: 16px;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      position: absolute;
    }

    &.less {
      display: none;
      &:before {
        content: '-';
      }
    }
  }

  &:empty {
    &:after {
      content: 'Aucun document';
      width: 100%;
      text-align: center;
      font-size: 14px;
      opacity: 0.2;
    }
  }

  &--none {
    width: 100%;
    text-align: center;
    font-size: 14px;
    opacity: 0.2;
  }
}

.ordo-item {
  background: none;
  display: flex;
  margin: 10px;
  padding: 5px 10px 5px 5px;
  border-radius: 4px;
  background: @blue;
  color: @white;
  max-width: 300px;
  align-items: center;

  &.praticien_doc {
    background: @antracite;
  }

  &.cerfa {
    background: @porange2;
  }

  &__inner {
    padding-left: 10px;
    text-align: left;
  }

  &__name {
    width: 100%;
    font-weight: 500;
    font-size: 16px;
    display: block;
  }

  &__date {
    font-size: 12px;
    width: 100%;
    display: block;
    font-weight: 400;
  }

  .icon {
    font-size: 30px;
  }
}

.filter-rdv {
  font-size: 16px;
  margin: 30px 0;

  &__nav {
    display: flex;
    margin-bottom: -1px;

    &--item {
      padding: 10px 20px;
      display: flex;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      border-bottom: 1px solid transparent;
      background: rgba(0, 0, 0, 0.04);
      line-height: 1;
      cursor: pointer;
      font-weight: 400;
      transition: all 150ms linear;

      &.selected {
        background: @white;
        border-bottom: 1px solid @white;
      }

      &:first-child {
        border-radius: 4px 0 0 0;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
      }

      &:last-child {
        border-radius: 0 4px 0 0;
      }

      .icon {
        margin-right: 10px;
      }
    }
  }

  &__search {
    align-items: center;
    display: none;

    &.selected {
      display: flex;
    }

    .cab-medecins__select-inline {
      margin-right: 30px;
    }

    .blue-cta {
      margin-left: 20px;
      display: flex;
      align-items: center;

      @media @mobile {
        margin-left: 5px;
        padding: 0 10px;
      }
    }

    .input-wrapper {
      padding: 0;
    }

    #search-rdv-by-name {
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
    }
  }

  &__filter {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0 4px 4px 4px;
    padding: 20px;
  }

  &__no-result {
    text-align: center;
    padding: 50px 10px;
    font-size: 16px;
    font-weight: 400;
  }

  &__nb-result {
    text-align: left;
    padding: 0 40px 20px;
    font-size: 14px;
    font-weight: 400;

    strong {
      color: @blue;
    }

    @media @mobile {
      padding: 0 20px 20px;
    }
  }
}

.popin-cerfa {
  .popin-med__center-content {
    text-align: left;
  }

  input[type="text"] {
    height: 30px;
    line-height: 30px;
  }

  select {
    height: 30px;
  }

  &__checkbox {
    display: flex !important;
    align-items: flex-start;
    white-space: normal !important;
    text-transform: none !important;
    line-height: 1.3 !important;
    padding: 0 !important;

    span {
      padding-right: 0 !important;
      font-weight: 400;
    }

    input:checked + span {
      font-weight: 700;
    }
  }

  &__img {
    max-width: 500px;
    margin: -20px auto 30px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  hr {
    margin-top: 0;
  }

  .blue-text {
    font-weight: 700 !important;
  }

  .popin-med__title {
    padding-bottom: 10px;
  }

  &__section-title {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    padding: 10px 20px;
    text-align: left;
    background: @blue;
    color: @white;
    margin: 10px -40px 30px;

    .dmp-visible & {
      margin: 10px -20px 30px;
    }

    small {
      text-transform: none;
      font-size: 12px;
      font-weight: 400;
    }

    @media @tablet {
      margin: 10px -20px 30px;
    }

    @media @mobile {
      margin: 10px -20px 20px;
    }
  }

  &__label {
    white-space: normal !important;
    text-transform: none !important;
    line-height: 1.3 !important;
  }
}

#label-upload-temp {
  font-size: 12px;
}

.header-cta {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  @media @mobile {
    margin: 10px -20px 0;
  }

  .check-ordos {
    display: flex;
    margin: 0 5px 10px;
    padding: 0 10px;
    align-items: center;

    @media @verysmallmobile {
      font-size: 9px;
    }

    .icon {
      margin-right: 5px;
    }
  }
}

.next-telecons {
  font-size: 16px;
  background: @antracite;
  color: @white;
  padding: 5px 10px;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 50px;

  @media @mobile {
    font-size: 14px;
    padding: 10px;
  }

  &__cta {
    width: 10px;
    height: 10px;
    border-top: 3px solid @yellowpj;
    border-right: 3px solid @yellowpj;
    transform: rotate(45deg);
    margin-left: 10px;
  }

  &__date, &__time, strong {
    font-weight: 700;
  }
}

.alert-support {
  font-size: 14px;
  margin-bottom: 30px;
  line-height: 1.5;

  @media @mobile {
    margin-bottom: 0;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
  }

  &__block {
    width: 50%;
    border-radius: 10px;
    padding: 20px;
    max-width: calc(~"50% - 10px");

    &.green {
      background: @s_green;
    }

    &.blue {
      background: @s_blue;
    }

    @media @mobile {
      width: 100%;
      max-width: 100%;
      margin-bottom: 15px;
      padding: 10px 15px;
      border-radius: 4px;
    }
  }
}

.message-patient {
  padding: 10px;
  background: @white;
  border: 1px solid rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;

  &__title {
    padding: 30px 10px 0;
    font-size: 16px;
  }

  &__date {
    font-size: 13px;
    padding-bottom: 10px;
  }

  &__object {
    font-size: 12px;
    padding-bottom: 10px;
    font-weight: 400;
  }

  &__content {
    pre {
      border: 0;
      background: none;
      padding: 0;
      line-height: 1.5;
      font-weight: 300;
      font-size: 12px;
    }
  }
}

.notif-account {
  position: fixed;
  z-index: 200000;
  background: @purple;
  font-size: 12px;
  line-height: 1.3;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 5px;
  color: @white;
  top: 14px;
  left: 14px;

  &__status {
    padding: 30px 0;
    font-size: 14px;
    font-weight: 400;
  }

  &__bank {
    padding: 10px 0 30px;
    font-size: 14px;
    font-weight: 400;

    .blue-cta {
      margin-top: 20px;
    }
  }

  &__bank-form {
    position: relative;

    .input-wrapper {
      padding-left: 0;
      padding-right: 0;
    }

    .blue-cta {
      margin: 0 10px 10px 0;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    cursor: pointer;

    .icon {
      margin: 0 5px;
    }
  }

  &__close {
    background: none;
    color: @white;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-left: 5px;
  }
}


.ordo-header, .ordo-footer {
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  padding: 0 30px;

  img {
    width: 100%;
    height: auto;
  }
}

.ordo-content, .ordo-date, .ordo-patient-infos {
  background: none;
  font-size: 14px;
  border: none;
  text-align: left;
  padding: 30px;
  font-weight: 400;
  line-height: 1.5;
  white-space: pre-wrap;
  word-break: normal;
}

.ordo-patient-infos {
  white-space: normal;
}

.ordo-content {
  padding-top: 0;
}


.ordo-date {
  text-align: right;
  padding-right: 30px;
  width: 100%;
}

.ordo-signature {
  text-align: right;
  padding: 0 30px;
  width: 100%;

  img {
    max-height: 160px;
  }

  &__tampon {
    padding: 5px 10px;
    border: 2px solid @black;
    text-align: left;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;

    &--name {
      font-size: 16px;
      font-weight: 700;
      display: block;
    }

    &--spe {
      font-size: 14px;
      display: block;
      margin-bottom: 5px;
    }

    &--line1, &--line2 {
      font-size: 12px;
      display: block;
    }
  }
}

.ordo-header {
  border: 0;
  background: none;
  padding: 0;
  margin: 40px 0;
  font-weight: 500;
}

.ordo-header-new {
  &__medecin-infos {
    text-align: left;

    &--name {
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
    }

    &--spe {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
    }

    &--details {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.3;
      background: none;
      border: 0;
      padding: 0;
      margin: 0;

      &:empty {
        display: none;
      }
    }

    &--address {
      font-size: 14px;
      line-height: 1.3;
      padding-top: 20px;
      font-weight: 500;

      &:empty {
        display: none;
      }
    }
  }

  &__medecin-code {
    display: flex;
    align-items: center;
    padding-top: 20px;

    &--label {
      font-size: 10px;
      font-weight: 500;
      line-height: 1;
      padding: 0 10px;
    }

    &--rpps, &--am {
      margin-right: 20px;
    }
  }
}

.inline-arrow {
  width: 10px;
  height: 10px;
  border-top: 3px solid @black;
  border-right: 3px solid @black;
  transform: rotate(45deg);
  margin-left: 10px;
  display: inline-block;
}

.hono-header {
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: flex-start;
  margin-bottom: 40px;

  &__patient-infos, &__praticien-infos {
    min-width: 50%;
    width: 50%;
    line-height: 1.8;
    padding: 10px 15px;
    border: 1px solid @black;
  }

  &__patient-infos {
    width: calc(~"50% - 20px");
    min-width: calc(~"50% - 20px");
  }
}

.hono-content {
  font-size: 14px;
  text-align: left;

  &__title {
    font-weight: 700;
    font-size: 22px;
    margin-bottom: 20px;
    text-align: center;
  }

  &__subtitle {
    font-weight: 700;
    font-size: 14px;
    margin-bottom: 30px;
    text-align: center;
  }
  &__text {
    line-height: 1.5;
    margin-bottom: 40px;
  }

  &__date {
    font-size: 14px;
    margin-bottom: 30px;
    text-align: right;
    padding-right: 30px;
    font-weight: 500;
  }

  &__amount {
    white-space: nowrap;
  }
}

.hono-footer {
  font-size: 12px;
  text-align: left;
  line-height: 1.5;
}
