.pagination {
  &__title {
    text-align: center;
    font-weight: 400;
    padding: 10px;

    strong {
      font-weight: 500;
      color: @blue;
    }
  }

  &__list {
    list-style: none;
    padding: 20px;
    margin: 0 auto;
    max-width: 1400px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    background: @white;

    &--item {
      width: 25%;
      padding: 5px;
      margin-bottom: 20px;

      @media @tablet {
        width: 50%;
      }

      @media @mobile {
        width: 100%;
      }

      address {
        color: @antracite !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 1.3 !important;
        margin-top: 10px;
      }

      a, button {
        color: @black;
        position: relative;
        display: inline-block;
        padding-right: 30px;
        background: none;

        &:after {
          content: '';
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translate(0, -50%) rotate(45deg);
          height: 8px;
          width: 8px;
          font-weight: 400;
          border-top: 2px solid @blue;
          border-right: 2px solid @blue;
        }
      }
    }
  }

  &__paginate {
    list-style: none;
    margin: 0;
    padding: 20px 0;
    font-size: 0;
    text-align: center;

    &--item {
      font-size: 16px;
      display: inline-block;
      vertical-align: middle;
      margin: 15px;
      font-weight: 400;
      color: @black;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: @white;
      padding: 5px 10px;

      a, button {
        color: @black;
        background: none;
      }

      &.selected {
        background: @blue;
        color: @white;
        font-weight: 500;
        pointer-events: none;

        a, button {
          color: @white;
        }
      }
    }
  }
}