.input-wrapper {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px 20px;
  position: relative;
  width: 100%;
  transition: opacity 150ms linear;

  &.search-patient {

    .inline-green-line {
      padding: 0;
    }
    .search-patient {

      &__result {
        display: flex;
        flex-wrap: wrap;

        .filter-rdv__no-result {
          text-align: center;
          width: 100%;
          font-weight: 500;
          padding: 0 0 10px 0;
          font-size: 12px;
        }
        &:empty {
          background: @white;
        }

        padding: 10px 10px 0;
        background: @lightgrey4;

        &--item {
          margin: 0 30px 10px 0;
          font-size: 12px;
          display: flex;
          align-items: center;
          font-weight: 500;

          .icon {
            margin-right: 5px;

            &.icon-addressbook {
              color: @blue;
            }
          }
        }

      }
      &__wrapper {
        display: flex;

        input {
          border-radius: 4px 0 0 4px;
        }
        .blue-cta {
          height: 50px;
          line-height: 50px;
          border-radius: 0 4px 4px 0;
          width: 50px;
          min-width: 50px;
          text-align: center;
          padding: 0;
          position: relative;
          z-index: 10;

          .icon {
            margin: 0;
            font-size: 20px;
          }
        }
      }
    }
  }

  &__withstickycta {
    display: flex;
    align-items: center;

    input {
      border-width: 1px 0 1px 1px !important;
      border-radius: 4px 0 0 4px !important;
    }

    .blue-cta {
      height: 50px !important;
      line-height: 50px !important;
      border-radius: 0 4px 4px 0 !important;
      padding: 0 20px !important;

      @media @mobile {
        padding: 0 15px !important;

        .icon {
          margin: 0;
          font-size: 20px;
        }
      }
    }
  }

  &__inner {
    display: flex;
    align-items: center;

    .input-wrapper & input[type="text"] {
      width: 50%;
      height: 42px;
      line-height: 42px;

      @media @mobile {
        width: 68%;
      }
    }

    .blue-cta {
      margin: 10px;
      display: flex;
      align-items: center;
    }
  }

  &.docked-right {
    padding: 0;
    text-align: right;

    .blue-cta {
      margin-left: 0;
      margin-right: 0;
    }

    label {
      text-transform: none;
      text-align: center;
      font-weight: 400;
    }
  }

  &.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.disabled-all {
    opacity: 0;
    pointer-events: none;
    cursor: not-allowed;
  }

  .has-tooltip {
    &:focus {
      + .input-tooltip {
        opacity: 1;
        visibility: visible;
        height: auto;
        padding: 5px;
        overflow: visible;
      }
    }
  }

  &.no-pad-bottom {
    padding-bottom: 0;
  }

  &.no-padding {
    padding: 0;
  }

  &.no-padding-x {
    padding-left: 0;
    padding-right: 0;

    &.withIcon i {
      left: 15px;
    }
  }

  &.no-padding-x-top {
    padding: 30px 0 0 0;
  }

  &.dashed {
    input, textarea {
      border-style: dashed !important;
    }
  }

  .list-wrapper {
    border: 1px solid rgba(35, 41, 57, 0.1);
    border-radius: 4px;
    padding: 10px;
    max-height: 160px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    input[type="radio"], input[type="checkbox"] {
      margin-right: 10px;
      vertical-align: middle;
    }

    span {
      vertical-align: middle;
      color: @greyText;
      font-size: 13px;
    }

    label {
      display: flex !important;

      span {
        white-space: normal;
      }
    }
  }

  .horaires-wrapper {
    font-size: 0;
    text-align: left;
    padding-bottom: 10px;

    .closed {
      display: inline;
      text-transform: none;
      font-weight: 500;

      input {
        margin-left: 5px;
      }

      @media @mobile {
        display: block;
      }
    }

    .selector, select.horaires {
      width: 58px !important;
      display: inline-block;
      margin: 0 10px 10px;
      padding: 0 8px;
      background-image: none;

      @media @mobile {
        width: calc(~"50% - 30px") !important;
      }
    }

    .selector > span {
      width: 60px !important;
      padding: 0 10px !important;
    }

    .selector select {
      width: 100% !important;
    }

    .selector:after {
      right: 10px !important;
      height: 5px;
      width: 5px;
      display: none !important;
    }

    input[type=date], input[type=email], input[type=number], input[type=text], input[type=password] {
      width: calc(~"50% - 65px");
      display: inline-block;
      vertical-align: middle;

      @media @mobile {
        width: calc(~"50% - 20px");
      }
    }

    .sep-horaire {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: 20px;
      width: 20px;

      @media @mobile {
        display: none;
      }
    }

    .sep-horaire-text {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      font-size: 14px;
      width: 10px;
      font-weight: 300;
      margin-bottom: 10px;
    }

    .horaire-title {
      width: 80px;
      display: inline-block;
      vertical-align: middle;
      text-transform: uppercase;
      font-size: 12px;
      margin-bottom: 10px;

      @media @mobile {
        width: 100%;
        padding: 20px 0;
      }
    }

  }

  @media @mobile {
    padding: 0 0 20px;
  }

  input[type="checkbox"], input[type="radio"] {
    vertical-align: middle;

    & + label {
      display: inline-block;
      vertical-align: middle;
      padding: 0 0 0 10px;
      width: calc(~"100% - 80px");
    }
  }

  .checkbox {
    margin-right: 20px;
    width: auto;
    padding-top: 10px;
    display: inline-block;

    input {
      margin-right: 10px;
    }

    span {
      vertical-align: middle;

      a {
        vertical-align: initial;
      }
    }

    &.with-flex {
      display: flex;
      align-items: center;
      text-transform: none;
      font-weight: 400;
      font-size: 14px;

      span {
        white-space: normal;
        line-height: 1.5;
        max-width: calc(~"100% - 25px");
      }
    }
  }

  &.cta-wrapper {
    padding-top: 50px;

    &.less-pad {
      padding-top: 20px;
    }

    @media @mobile {
      text-align: center;
    }
  }

  &.talign-right {
    text-align: right;

    @media @mobile {
      text-align: center;
    }
  }

  &.withIcon {
    input[type="text"], input[type="tel"], input[type="email"], input[type="date"], input[type="datetime-local"] {
      padding-left: 40px;
    }

    i {
      font-size: 14px;
      position: absolute;
      left: 25px;
      bottom: 38px;

      @media @mobile {
        left: 15px;
      }
    }
  }

  &.half-col {
    width: 50%;

    @media @mobile {
      width: 100%;
    }
  }

  &.larger-col {
    width: 60%;

  }

  &.minor-col {
    width: 40%;

  }


  &.tiers-col {
    width: 33.3333%;

    @media @mobile {
      width: 100%;
    }
  }

  &.quarter-col {
    width: 25%;

    @media @mobile {
      width: calc(~"50% - 5px");

      &:nth-child(2n+1) {
        width: calc(~"50% - 5px");
        margin-right: 10px;
      }
    }
  }

  &.two-tiers-col {
    width: 66.6666%;

    @media @mobile {
      width: 100%;
    }
  }

  &.error {
    color: red !important;
    border-color: red !important;

    * {
      color: red !important;
      border-color: red !important;
    }
  }

  label {
    width: 100%;
    display: block;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 10px;
    line-height: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-left: 3px;

    &.for-borderless {
      line-height: 30px;
      margin: 0 7px 0 0;
      padding-bottom: 2px;
      font-size: 16px;
      vertical-align: middle;
      width: auto;
      display: inline-block;
      text-transform: none;
    }

    small {
      font-size: 80%;
      text-transform: none;

      &.label-desc {
        font-size: 14px;
        line-height: 1.5;
        font-weight: 300;
        display: block;
        padding-top: 10px;
      }
    }

    span {
      vertical-align: middle;
      padding: 0 20px 0 10px;

      @media @mobile {
        padding-right: 0;
      }
    }

    &.inline-block {
      display: inline-block;
      width: auto;
    }

    &.has-wrap {
      white-space: normal;
      text-transform: none;
      line-height: 1.3;
    }

  }

  input[type="date"] {
    white-space: nowrap;
  }

  input[type="text"], input[type="tel"], input[type="email"], input[type="date"], input[type="number"], input[type="password"], input[type="datetime-local"] {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    width: 100%;
    display: block;
    color: @greyText;
    border: 1px solid rgba(35, 41, 57, 0.1);
    border-radius: 4px;
    font-size: 13px;
    -webkit-appearance: none;
    -moz-appearance: none;

    &:focus {
      border: 1px solid rgba(35, 41, 57, 1);
    }

    &.small {
      height: 40px;
    }

    @media @mobile {
      font-size: 16px;
    }

    &[disabled] {
      background: @lightgrey;
      cursor: not-allowed;
    }

    &.big {
      text-transform: uppercase;
      font-size: 30px;
      font-weight: 600;
      letter-spacing: 0.2em;
    }

    &.tiers {
      width: calc(~"33.33% - 20px");
      display: inline-block;
      margin-right: 20px;
    }

    &.twotiers {
      width: 66.66%;
      display: inline-block;
    }
  }

  textarea {
    padding: 20px;
    width: 100%;
    display: block;
    color: @greyText;
    border: 1px solid rgba(35, 41, 57, 0.1);
    border-radius: 4px;
    font-size: 13px;
    line-height: 1.5;

    &.dashed {
      border: 1px dashed rgba(35, 41, 57, 0.2);
      resize: none;

      &:focus {
        border: 1px dashed rgba(35, 41, 57, 1);
      }
    }

    &.blue-border {
      border-color: @blue;
    }

    &.smaller {
      min-height: auto;
      height: 70px;
      resize: none;
    }

    &.withMinHeight {
      min-height: 160px;
    }

    &:focus {
      border: 1px solid rgba(35, 41, 57, 1);
    }

    @media @mobile {
      font-size: 16px;
    }
  }

  select {
    height: 42px;
    width: 100%;
    display: block;
    font-size: 13px;

    -webkit-appearance: none;
    -moz-appearance: none;

    background: @white url("@{img-path}chevron-down.png") calc(~"100% - 5px") 50% no-repeat;
    background-size: auto 20px;
    vertical-align: middle;
    margin: 0;
    position: relative;
    border: 1px solid rgba(35, 41, 57, 0.1);
    border-radius: 4px;
    padding: 0 30px 0 20px;

    &.borderless {
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      padding: 0 30px 0 0;
      font-weight: 500;
      font-size: 16px;
      border: 0;
      border-bottom: 2px solid @green;
      border-radius: 0;
      background-position: 101% 50%;
      display: inline-block;
      vertical-align: middle;
      height: 30px;
      transition: none;

      &.focus {
        padding: 0 25px 0 5px;
        border: 2px solid @blue;
        background-position: 100% 50%;

      }
    }

    ::-ms-expand {
      display: none;
    }

    &.add-margin {
      margin-top: 30px;
    }

    &[disabled] {
      background: @white;
      cursor: not-allowed;
    }

    @media @mobile {
      font-size: 16px;
    }
  }

  &.multiple-choices {
    input[type="text"], input[type="tel"], input[type="email"], input[type="number"], input[type="datetime-local"] {
      margin-bottom: 10px;
    }

    button {
      margin-top: 20px;
    }
  }
}

.separator {
  background: rgba(0, 0, 0, 0.1);
  height: 1px;
  width: calc(~"100% - 20px");
  clear: bottom;
  margin: 10px 10px 40px;
}

.input-tooltip {
  position: absolute;
  background: @yellowpj;
  transform: translate(0, calc(~"-100% - 10px"));
  left: 10px;
  width: calc(~"100% - 20px");
  line-height: 1.3;
  padding: 0 5px;
  font-size: 14px;
  font-weight: 400;
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.1);
  top: 0;
  color: @black;
  border-radius: 4px;
  transition: opacity 300ms linear;
  opacity: 0;
  visibility: hidden;
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 0;

  &__checkemail {
    font-size: 18px;
    font-weight: 700;
    font-family: Monospace !important;
    width: 100%;
    word-break: break-all;
    text-align: center;
  }

  &.with-wrap {
    flex-wrap: wrap;
    justify-content: center;
  }

  &.visible {
    opacity: 1;
    visibility: visible;
    height: auto;
    padding: 5px;
    overflow: visible;
  }

  @media @mobile {
    left: 0;
    width: 100%;
  }

  .icon-faq {
    font-size: 20px;
    margin-left: 10px;
    color: @black;
    position: static !important;
  }

  span {
    display: block;
    padding-left: 10px;
    text-align: left;
    color: @black;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -8px;
    left: 20px;
    border-top: 8px solid @yellowpj;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
  }
}

.section {
  &__smallform {
    margin: 30px auto;
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;

    &.no-center {
      margin: 30px 0;
    }

    @media @mobile {
      margin: 30px auto;
    }

    .input-wrapper {
      padding-bottom: 10px;

      .blue-cta {
        width: 100%;
      }

      @media @mobile {
        padding-bottom: 5px;

        &.quarter-col:nth-child(2n+1) {
          margin-right: 0;
          margin-left: 10px;
        }

        &.secunumber {
          width: calc(~"50% - 5px");
        }

        &.cta-mobile {
          width: calc(~"50% - 5px");
          margin-left: 10px;

          .blue-cta {
            width: 100%;
          }
        }
      }
    }

    label {
      font-weight: 500;
      text-transform: none;
      font-size: 14px;
      margin: 0;
      padding-bottom: 10px;

      &.alone {
        padding: 20px 0 30px;
      }

      &.radio-wrapper {
        padding-bottom: 15px;

        &:last-child {
          margin-bottom: 10px;
        }

        input:checked + span {
          font-weight: 700;
        }
      }
    }

    input[type="text"] {
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      border: 1px solid rgba(35, 41, 57, 0.1);
    }
  }
}

.input-type-date {
  font-family: Monospace !important;
}

.note-wrapper {
  display: flex;
  justify-content: center;
  background: @white;
  border: 1px solid rgba(35, 41, 57, 0.1);
  border-radius: 4px;

  &.error {
    border-color: @red;
  }

  label {
    width: auto;
    text-overflow: unset;
    margin: 10px;
    cursor: pointer;

    &.star_checked, &.star_hover {
      .note-wrapper__full {
        display: block;
      }

      .note-wrapper__empty {
        display: none;
      }
    }
  }

  &__empty {

  }

  &__full {
    display: none;
  }

  input[type='radio'] {
    opacity: 0;
    width: 0;
    height: 0;
    position: absolute;
  }
}