.grid {
  font-size: 16px;
  width: 100%;

  .grid-item {
    width: 400px;
    padding: 15px;
    max-width: 100%;
    position: relative;

    &--width2 {
      width: 800px;
    }

    &__fullwidth {
      width: 100%;
    }

    &__inner {
      padding: 20px 20px 30px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      overflow: hidden;
      border-radius: 4px;
      background: @white;
    }
  }
}