.section{
  &.legales{
    padding-top: 20px;

    .section{
      &__title{
        font-size: 24px;
        text-align: center;
        font-weight: 400;
        padding: 20px;

        @media @mobile{
          font-size: 16px;
          line-height: 1.3;

        }
      }

      &__content{
        max-width: 1200px;
        border-radius: 20px;
        background: @white;
        margin: 20px auto;
        padding: 40px;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.5;

        @media @mobile{
          border-radius: 10px;
          width: calc(~"100% - 20px");
          margin-left: 10px;
          padding: 30px 20px;

        }

        h2{
          font-size: 20px;
          font-weight: 500;
          margin: 30px 0 20px;
        }
        a{
          color: @blue;
          font-weight: 500;
        }
      }
    }
  }
}