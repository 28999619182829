.print_cta {
  padding: 20px;
  text-align: right;
}

.rdv-comment__delete {
  position: absolute;
  top: 5px;
  right: -10px;
  background: @red;
  color: @white;
  padding: 5px;
  border-radius: 4px;
  line-height: 1;

  .comments-content-wrapper.section__rdv & {
    right: 5px;
  }
}

.patient_datas {
  text-align: left;

  .popin-med__cta-wrapper {
    margin-top: 20px !important;
  }

  .cerfa-content-wrapper, .cerfa-work-content-wrapper, .sephira-content-wrapper {
    .rdv-cerfa {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 30px;

      .blue-cta {
        padding: 0 10px;
        margin: 5px;

        .icon {
          margin: 0;
        }
      }
    }
  }

  .comments-content-wrapper {
    .rdv-comment {
      vertical-align: top;
      background: @white;
      border: 4px;
      margin-bottom: 5px;
      padding: 5px 10px;
      position: relative;

      .rdv-wrapper__date {
        font-size: 10px;
        font-weight: 500;
        padding-bottom: 5px;
      }

      .rdv-wrapper__content {
        font-size: 12px;
        line-height: 1.3;
        font-weight: 400;
        border: 0;
        margin: 0;
        padding: 0;
        background: none;
        white-space: pre-wrap;
      }
    }
  }

  .ordo-content-wrapper {
    padding-top: 10px;

    .rdv-ordo {
      margin: 0 10px 10px 0;
      padding: 5px 10px;
      border-radius: 4px;
      background: @blue;
      cursor: pointer;
      color: @white;
      font-size: 12px;
      font-weight: 500;
      display: inline-block;
      vertical-align: top;
    }

    .rdv-ordo-doc {
      font-size: 12px;
      margin: 0 10px 10px 0;
      background: @antracite;
      color: @white;
      cursor: pointer;
      border-radius: 4px;
      padding: 5px 4px 5px 5px;
      text-align: right;
      display: inline-block;
      vertical-align: top;

      .rdv-wrapper__date {
        display: inline-block;
        vertical-align: middle;

        .icon {
          color: @white;
        }
      }

      .rdv-ordo-doc {
        &__cta {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
        }

        &__link, &__delete {
          margin: 0 0 0 10px;
          display: inline-block;
          vertical-align: middle;
          text-align: center;
          border-radius: 4px;
          width: 20px;
          height: 20px;
          line-height: 20px;
          padding: 0;

          .icon {
            margin: 0;
            vertical-align: middle;
            font-size: 10px;
          }
        }
      }
    }
  }

  .input-wrapper {
    textarea {
      padding: 10px;
      min-height: 140px;
    }
  }

  &__infos {
    font-size: 12px;
    color: @blue;
    font-weight: 500;
    line-height: 1.3;
    padding: 15px 20px 10px;
  }

  table {
    border-radius: 8px;
    overflow: hidden;
    margin: 10px;
    width: calc(~"100% - 20px");
    font-weight: 400;
    font-size: 13px;

    .date {
      font-size: 10px;
    }

    .folder_name {
      font-weight: 500;
      text-transform: uppercase;
      border-top: 1px solid @lightgrey2;
      border-bottom: 2px solid @lightgrey2;
    }

    .type {
      font-weight: 500;
    }

    tr {
      &:first-child {
        td {

          padding-top: 20px;
        }
      }

      &:last-child {
        td, th {

          padding-bottom: 20px;
        }
      }

      &:nth-child(even) {
        background: @lightgrey;
      }

      &:nth-child(odd) {
        background: @lightgrey4;
      }
    }

    td, th {
      padding: 10px 20px;
    }

    th {
      background: @blue;
      color: @white;
      text-transform: uppercase;
      font-weight: 500;
    }

    .input-wrapper {
      padding: 0;
    }
  }
}

.ordo_wrapper {
  table {
    overflow: hidden;
    margin: 10px;
    width: 100%;
    font-weight: 400;
    font-size: 13px;

    tr {
      &:first-child {
        td {

          padding-top: 20px;
        }
      }

      &:last-child {
        td, th {

          padding-bottom: 20px;
        }
      }
    }

    td, th {
      padding: 10px 20px;
    }

    th {
      color: @black;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}